#main.blogMain {
  padding-top: 100px; }
  @media (max-width: 1399px) {
    #main.blogMain {
      padding-top: 70px; } }
  @media (max-width: 767px) {
    #main.blogMain {
      padding-top: 50px; } }
  #main.blogMain .defaultContainer {
    margin: 0 0 90px 0; }

.tx-fd-blog .container-fluid.list {
  display: flex;
  flex-wrap: wrap; }

.tx-fd-blog .bloglist-header {
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 100px; }
  @media (max-width: 1399px) {
    .tx-fd-blog .bloglist-header {
      height: 600px;
      margin-bottom: 50px; } }
  @media (max-width: 991px) {
    .tx-fd-blog .bloglist-header {
      height: 440px;
      margin-bottom: 50px; } }
  @media (max-width: 767px) {
    .tx-fd-blog .bloglist-header {
      height: 370px;
      margin-bottom: 50px; } }
  @media (max-width: 479px) {
    .tx-fd-blog .bloglist-header {
      height: 280px; } }
  .tx-fd-blog .bloglist-header.header-style-0 .category {
    color: #fff; }
  .tx-fd-blog .bloglist-header.header-position-0 {
    background-position: center top; }
  .tx-fd-blog .bloglist-header.header-style-1 .category {
    color: #000; }
  .tx-fd-blog .bloglist-header.header-position-1 {
    background-position: center bottom; }
  .tx-fd-blog .bloglist-header .container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center; }

.tx-fd-blog .category {
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 5px; }
  @media (max-width: 767px) {
    .tx-fd-blog .category {
      font-size: 14px; } }
  @media (max-width: 479px) {
    .tx-fd-blog .category {
      font-size: 12px; } }

.tx-fd-blog .detail-link {
  display: inline-block;
  background: #fff;
  padding: 8px 40px 5px 40px;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
  transition: all .3s ease; }
  .tx-fd-blog .detail-link:hover {
    background: #666;
    color: #fff; }
  @media (max-width: 767px) {
    .tx-fd-blog .detail-link {
      font-size: 12px;
      padding: 5px 14px 2px; } }
  .tx-fd-blog .detail-link.dtl-lnk2 {
    border: 1px solid #000; }
    .tx-fd-blog .detail-link.dtl-lnk2:hover {
      border: 1px solid #666; }
    @media (max-width: 639px) {
      .tx-fd-blog .detail-link.dtl-lnk2 {
        margin-bottom: 30px; } }

.tx-fd-blog .bloglist-teaser .dark-color span em,
.tx-fd-blog .bloglist-header .dark-color span em {
  padding-left: 7px;
  padding-right: 7px;
  background: #000;
  color: #fff; }

.tx-fd-blog h1 {
  max-width: 40%;
  margin-bottom: 30px !important; }
  @media (max-width: 1399px) {
    .tx-fd-blog h1 {
      max-width: 60%; } }
  @media (max-width: 767px) {
    .tx-fd-blog h1 {
      font-size: 30px;
      margin-bottom: 10px !important; } }
  @media (max-width: 639px) {
    .tx-fd-blog h1 {
      max-width: 80%;
      font-size: 28px; } }
  @media (max-width: 479px) {
    .tx-fd-blog h1 {
      max-width: 100%;
      font-size: 26px; } }

.tx-fd-blog h1 em,
.tx-fd-blog h2 em {
  font-style: normal;
  background: #000;
  display: inline-block;
  vertical-align: top;
  padding: 15px 15px 0 15px;
  transition: background .3s ease-in-out; }
  @media (max-width: 479px) {
    .tx-fd-blog h1 em,
    .tx-fd-blog h2 em {
      padding-top: 10px; } }

.tx-fd-blog h2 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 36px; }
  @media (max-width: 767px) {
    .tx-fd-blog h2 {
      font-size: 26px; } }
  @media (max-width: 479px) {
    .tx-fd-blog h2 {
      font-size: 20px; } }

.tx-fd-blog h2 em {
  padding: 7px 7px 0 7px; }

.tx-fd-blog .bloglist-teaser {
  display: flex;
  margin-bottom: 100px; }
  @media (max-width: 639px) {
    .tx-fd-blog .bloglist-teaser {
      margin-bottom: 50px; } }
  @media (max-width: 639px) {
    .tx-fd-blog .bloglist-teaser.blogteaser-1 {
      flex-direction: column; } }
  .tx-fd-blog .bloglist-teaser.blogteaser-1 .list-text {
    flex: 33%;
    padding-right: 20px; }
    @media (max-width: 1024px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-1 .list-text {
        flex: 50%; } }
    @media (max-width: 639px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-1 .list-text {
        flex: 100%;
        padding-right: 0; } }
  .tx-fd-blog .bloglist-teaser.blogteaser-1 .list-image {
    flex: 67%; }
    .tx-fd-blog .bloglist-teaser.blogteaser-1 .list-image img {
      width: 100%; }
    @media (max-width: 1024px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-1 .list-image {
        flex: 50%; } }
    @media (max-width: 639px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-1 .list-image {
        flex: 100%; } }
  .tx-fd-blog .bloglist-teaser.blogteaser-2 {
    flex-direction: row-reverse; }
    @media (max-width: 639px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-2 {
        flex-direction: column; } }
    .tx-fd-blog .bloglist-teaser.blogteaser-2 .list-text {
      flex: 33%;
      padding-left: 20px; }
      @media (max-width: 1024px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-2 .list-text {
          flex: 50%; } }
      @media (max-width: 639px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-2 .list-text {
          flex: 100%;
          padding-left: 0; } }
    .tx-fd-blog .bloglist-teaser.blogteaser-2 .list-image {
      flex: 67%; }
      .tx-fd-blog .bloglist-teaser.blogteaser-2 .list-image img {
        width: 100%; }
      @media (max-width: 1024px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-2 .list-image {
          flex: 50%; } }
      @media (max-width: 639px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-2 .list-image {
          flex: 100%; } }
  @media (max-width: 639px) {
    .tx-fd-blog .bloglist-teaser.blogteaser-3 {
      flex-direction: column; } }
  .tx-fd-blog .bloglist-teaser.blogteaser-3 .list-text {
    flex: 33%;
    padding-right: 20px; }
    @media (max-width: 1023px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-3 .list-text {
        flex: 50%; } }
    @media (max-width: 639px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-3 .list-text {
        padding-right: 0; } }
  .tx-fd-blog .bloglist-teaser.blogteaser-3 .list-image {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex: 67%; }
    @media (max-width: 1023px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-3 .list-image {
        flex: 50%;
        flex-wrap: wrap; } }
    .tx-fd-blog .bloglist-teaser.blogteaser-3 .list-image img {
      width: 100%;
      padding-right: 20px; }
      @media (max-width: 1023px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-3 .list-image img {
          width: 100%;
          margin-bottom: 20px;
          padding-right: 0; } }
    @media (max-width: 639px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-3 .list-image picture {
        width: 100%; } }
  .tx-fd-blog .bloglist-teaser.blogteaser-4 {
    flex-direction: row-reverse; }
    @media (max-width: 639px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-4 {
        flex-direction: column; } }
    .tx-fd-blog .bloglist-teaser.blogteaser-4 .list-text {
      flex: 33%;
      padding-left: 20px; }
      @media (max-width: 1023px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-4 .list-text {
          flex: 50%; } }
      @media (max-width: 639px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-4 .list-text {
          padding-left: 0; } }
    .tx-fd-blog .bloglist-teaser.blogteaser-4 .list-image {
      align-items: flex-start;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      flex: 67%; }
      @media (max-width: 1023px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-4 .list-image {
          flex: 50%;
          flex-wrap: wrap; } }
      .tx-fd-blog .bloglist-teaser.blogteaser-4 .list-image img {
        width: 100%;
        padding-right: 20px; }
        @media (max-width: 1023px) {
          .tx-fd-blog .bloglist-teaser.blogteaser-4 .list-image img {
            padding-right: 0;
            margin-bottom: 20px;
            width: 100%; } }
      @media (max-width: 639px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-4 .list-image picture {
          width: 100%; } }
  .tx-fd-blog .bloglist-teaser.blogteaser-5 .list-text {
    flex: 100%; }
  .tx-fd-blog .bloglist-teaser.blogteaser-5 .list-image {
    flex: 0%;
    display: none; }
  .tx-fd-blog .bloglist-teaser.blogteaser-5 h2 {
    display: none; }
  .tx-fd-blog .bloglist-teaser.blogteaser-5 .quote {
    margin-bottom: 20px; }
    .tx-fd-blog .bloglist-teaser.blogteaser-5 .quote span,
    .tx-fd-blog .bloglist-teaser.blogteaser-5 .quote p {
      display: inline;
      font-size: 40px;
      font-style: italic;
      font-weight: 400;
      line-height: 1.1em; }
      @media (max-width: 1023px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-5 .quote span,
        .tx-fd-blog .bloglist-teaser.blogteaser-5 .quote p {
          font-size: 30px; } }
      @media (max-width: 639px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-5 .quote span,
        .tx-fd-blog .bloglist-teaser.blogteaser-5 .quote p {
          font-size: 22px; } }
  .tx-fd-blog .bloglist-teaser.blogteaser-6 {
    width: 33.33%;
    display: flex;
    flex-direction: column-reverse;
    float: left;
    justify-content: flex-end;
    position: relative;
    padding-right: 20px; }
    @media (max-width: 639px) {
      .tx-fd-blog .bloglist-teaser.blogteaser-6 {
        width: 100%;
        padding-right: 0; } }
    .tx-fd-blog .bloglist-teaser.blogteaser-6 .list-text {
      width: 100%; }
    .tx-fd-blog .bloglist-teaser.blogteaser-6 .list-image {
      width: 100%;
      margin-bottom: 20px; }
      @media (max-width: 639px) {
        .tx-fd-blog .bloglist-teaser.blogteaser-6 .list-image img {
          width: 100%; } }

.tx-fd-blog .article-socials {
  list-style: none;
  margin: 50px 0;
  padding: 0; }
  .tx-fd-blog .article-socials li {
    width: calc(20% - 20px);
    display: inline-block;
    background: #f2f2f2;
    padding: 3px 15px 3px 3px;
    margin-right: 20px;
    text-align: center;
    position: relative;
    line-height: 30px;
    cursor: pointer; }
    @media (max-width: 1023px) {
      .tx-fd-blog .article-socials li {
        width: calc(50% - 20px);
        margin-bottom: 10px; } }
    @media (max-width: 479px) {
      .tx-fd-blog .article-socials li {
        width: 100%; } }
    .tx-fd-blog .article-socials li i {
      position: absolute;
      left: 10px;
      top: 8px; }
    .tx-fd-blog .article-socials li a {
      text-decoration: none;
      border: 0;
      margin-top: 3px;
      display: inline-block; }
      .tx-fd-blog .article-socials li a:hover {
        color: #000; }
    .tx-fd-blog .article-socials li .icon-xing-logo {
      display: block;
      width: 25px;
      height: 27px;
      background-image: url("../images/xing-logo.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 85%; }

.tx-fd-blog .date {
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 10px; }

_:-ms-fullscreen,
:root .bloglist-teaser {
  width: 100%; }
  _:-ms-fullscreen.blogteaser-6,
  :root .bloglist-teaser.blogteaser-6 {
    width: 33.33%; }

/*# sourceMappingURL=maps/blog.css.map */
