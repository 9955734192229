/**
 * Animate a list of elements sequentially
 *
 * @author FOURDEGREES
 * @param {string} $target             - selector of animated item 
 * @param {number} $initialDelay = 0.3 - delay until animation begins
 * @param {number} $duration = 0.5     - duration for each animation
 * @param {number} $delay = 0.2        - delay between each animation
 * @param {number} $itemCount = 10     - number of animated items
 */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

.middleContainer,
.frame-middleContainer {
  max-width: 1050px;
}

@media (min-width: 1025px) {
  .smallContainer,
  .frame-smallContainer {
    max-width: 930px;
  }
}

.tinyContainer,
.frame-tinyContainer {
  max-width: 786px;
}

.tinyContainerReverse {
  max-width: 786px;
}
.tinyContainerReverse .contacts-holder {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.tinyContainerReverse .contact-box {
  order: 2;
  padding: 25px 0 0 20px;
}
@media (min-width: 480px) {
  .tinyContainerReverse .contact-box {
    padding-left: 40px;
  }
}
.tinyContainerReverse .image-holder {
  margin: 0;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

hr.spacer {
  clear: both;
  display: block;
  height: 1px;
  background: #e6e6e6;
  width: 100%;
  margin: 50px 0;
}

.middleContainer,
.smallContainer,
.tinyContainer,
.tinyContainerReverse,
.defaultContainer,
.frame-middleContainer,
.frame-smallContainer,
.frame-tinyContainer,
.frame-tinyContainerReverse,
.frame-defaultContainer {
  margin: 0 auto 30px;
}
.middleContainer .productLineNavigation a,
.smallContainer .productLineNavigation a,
.tinyContainer .productLineNavigation a,
.tinyContainerReverse .productLineNavigation a,
.defaultContainer .productLineNavigation a,
.frame-middleContainer .productLineNavigation a,
.frame-smallContainer .productLineNavigation a,
.frame-tinyContainer .productLineNavigation a,
.frame-tinyContainerReverse .productLineNavigation a,
.frame-defaultContainer .productLineNavigation a {
  border-bottom: 0;
}
.middleContainer .productLineNavigation a.active,
.smallContainer .productLineNavigation a.active,
.tinyContainer .productLineNavigation a.active,
.tinyContainerReverse .productLineNavigation a.active,
.defaultContainer .productLineNavigation a.active,
.frame-middleContainer .productLineNavigation a.active,
.frame-smallContainer .productLineNavigation a.active,
.frame-tinyContainer .productLineNavigation a.active,
.frame-tinyContainerReverse .productLineNavigation a.active,
.frame-defaultContainer .productLineNavigation a.active {
  border-bottom: solid 1px;
}
.middleContainer a,
.smallContainer a,
.tinyContainer a,
.tinyContainerReverse a,
.defaultContainer a,
.frame-middleContainer a,
.frame-smallContainer a,
.frame-tinyContainer a,
.frame-tinyContainerReverse a,
.frame-defaultContainer a {
  border-bottom: solid 1px;
}
.middleContainer a.btn,
.middleContainer a.btn:hover,
.smallContainer a.btn,
.smallContainer a.btn:hover,
.tinyContainer a.btn,
.tinyContainer a.btn:hover,
.tinyContainerReverse a.btn,
.tinyContainerReverse a.btn:hover,
.defaultContainer a.btn,
.defaultContainer a.btn:hover,
.frame-middleContainer a.btn,
.frame-middleContainer a.btn:hover,
.frame-smallContainer a.btn,
.frame-smallContainer a.btn:hover,
.frame-tinyContainer a.btn,
.frame-tinyContainer a.btn:hover,
.frame-tinyContainerReverse a.btn,
.frame-tinyContainerReverse a.btn:hover,
.frame-defaultContainer a.btn,
.frame-defaultContainer a.btn:hover {
  border-bottom: solid 1px #000;
}
.middleContainer a.result-product-box,
.smallContainer a.result-product-box,
.tinyContainer a.result-product-box,
.tinyContainerReverse a.result-product-box,
.defaultContainer a.result-product-box,
.frame-middleContainer a.result-product-box,
.frame-smallContainer a.result-product-box,
.frame-tinyContainer a.result-product-box,
.frame-tinyContainerReverse a.result-product-box,
.frame-defaultContainer a.result-product-box {
  border-bottom: 0;
}
@media (min-width: 768px) {
  .middleContainer,
  .smallContainer,
  .tinyContainer,
  .tinyContainerReverse,
  .defaultContainer,
  .frame-middleContainer,
  .frame-smallContainer,
  .frame-tinyContainer,
  .frame-tinyContainerReverse,
  .frame-defaultContainer {
    margin: 0 20px 60px;
  }
}
@media (min-width: 992px) {
  .middleContainer,
  .smallContainer,
  .tinyContainer,
  .tinyContainerReverse,
  .defaultContainer,
  .frame-middleContainer,
  .frame-smallContainer,
  .frame-tinyContainer,
  .frame-tinyContainerReverse,
  .frame-defaultContainer {
    margin: 0 50px 90px;
  }
}
/* @media (min-width: 1400px) {
    .middleContainer,
    .smallContainer,
    .tinyContainer,
    .tinyContainerReverse,
    .defaultContainer,
    .frame-middleContainer,
    .frame-smallContainer,
    .frame-tinyContainer,
    .frame-tinyContainerReverse,
    .frame-defaultContainer {
      margin: 0 auto 90px; } } */

@media (min-width: 992px) {
  .container-fluid .article-box + .article-box {
    margin: 0 0 75px;
  }
}

.container-fluid {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

a.noBorder {
  border-bottom: none !important;
}

.buttonWrapper {
  transition: opacity 0.3s ease-in-out;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  text-transform: none;
  font-size: 16px;
  cursor: pointer;
}
.buttonWrapper:hover span,
.buttonWrapper.active span {
  color: #fff;
  background: #000;
  border-color: #000;
}
.buttonWrapper span {
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
  display: block;
  width: 100%;
  color: #7f7f7f;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
@media (min-width: 768px) {
  .buttonWrapper {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .buttonWrapper {
    font-size: 22px;
  }
}
.buttonWrapper.active:hover {
  opacity: 0.5;
}

.clear,
.clearBoth {
  clear: both;
}

.loaderBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 35;
}

body > .loaderBackground {
  position: fixed;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.clear {
  clear: both;
}

.inlineBlock {
  display: inline-block;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.valignTop {
  vertical-align: top;
}

.noPadding {
  padding: 0 !important;
}

.textBlack {
  color: #000;
}

.valignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box5 {
  width: 5%;
}

.box10 {
  width: 10%;
}

.box15 {
  width: 15%;
}

.box20 {
  width: 20%;
}

.box25 {
  width: 25%;
}

.box30 {
  width: 30%;
}

.box35 {
  width: 35%;
}

.box40 {
  width: 40%;
}

.box45 {
  width: 45%;
}

.box50 {
  width: 50%;
}

.box55 {
  width: 55%;
}

.box60 {
  width: 60%;
}

.box65 {
  width: 65%;
}

.box70 {
  width: 70%;
}

.box75 {
  width: 75%;
}

.box80 {
  width: 80%;
}

.box85 {
  width: 85%;
}

.box90 {
  width: 90%;
}

.box95 {
  width: 95%;
}

.box100 {
  width: 100%;
}

/*** CSS-Loader http://tobiasahlin.com/spinkit/ ****/
.sk-fading-circle {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #000;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3 {
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4 {
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5 {
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6 {
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7 {
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8 {
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9 {
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10 {
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11 {
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12 {
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.showOnPrint {
  display: none !important;
}

.hideOnPrint {
  display: inline !important;
}

.showOnMobile {
  display: none !important;
}

.hideOnMobile {
  display: block !important;
}

#main .container-fluid .container-fluid {
  padding: 0;
}

.imageCollageWrapper .big h1 {
  max-width: 85%;
}
@media (min-width: 1024px) {
  .imageCollageWrapper .big h1 {
    max-width: 75%;
  }
}
@media (min-width: 1400px) {
  .imageCollageWrapper .big h1 {
    max-width: 700px;
  }
}
.imageCollageWrapper .big h1 span em {
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
}
@media (min-width: 992px) {
  .imageCollageWrapper .big h1 span em {
    padding-left: 7px;
    padding-right: 7px;
  }
}
@media (max-width: 767px) {
  .imageCollageWrapper .big h1 span em {
    padding: 9px 6px 6px;
  }
}
.imageCollageWrapper .big h1.dark-color span em {
  color: #fff;
}

.imageCollageWrapper .imageHolderWrapper {
  overflow: hidden;
}
.imageCollageWrapper .imageHolderWrapper .imageWrapper {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}
.imageCollageWrapper .imageHolderWrapper .imageWrapper img {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}
.imageCollageWrapper .imageHolderWrapper:hover:not(.teaserSliderShow) .imageWrapper {
  transform: scale(1.3);
}

.imageCollageWrapper h1,
.imageCollageWrapper h2,
.imageCollageWrapper strong {
  z-index: 5;
}

.imageCollageWrapper a {
  height: 100%;
  display: inline-block;
  width: 100%;
}

_::-webkit-full-page-media,
_:future,
:root .imageLargeCollageWrapper {
  margin-top: 0;
}

.caption-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  text-transform: uppercase;
}
.caption-list li {
  float: left;
  padding: 0 10px;
}
.caption-list li .image-caption {
  text-align: center;
  padding-top: 15px;
  font-size: 16px;
}
.caption-list li .image-caption strong {
  font-family: "geomanist";
  font-weight: 700;
}
.caption-list li .image-caption p {
  font-size: 14px;
}

.tx-dce-container p > * {
  margin: 0 7px;
}
.tx-dce-container p > *:first-child {
  margin-left: 0;
}
.tx-dce-container p > *:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .showOnMobile {
    display: block !important;
  }
  .hideOnMobile {
    display: none !important;
  }
  .caption-list {
    flex-direction: column;
  }
  .caption-list li {
    margin-bottom: 30px;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .col-ld-4 {
    width: 33.33333%;
    float: left;
  }
}

@media (max-width: 767px) {
  .col-lt-6 {
    width: 50%;
    float: left;
  }
  div.submitHolder .btn.large {
    font-size: 20px;
    width: 100%;
    padding: 10px 14px 5px 14px;
  }
  .mobileProfileMenu {
    position: fixed;
    width: 100%;
    left: 0;
    top: 50px;
    height: 0;
    background-color: #fff;
    text-align: center;
    overflow: hidden;
  }
  .mobileProfileMenu.show {
    overflow: visible;
  }
  .mobileProfileMenu .btnProfile,
  .mobileProfileMenu .btnLogout {
    width: auto;
    vertical-align: top;
    display: inline-block;
    height: 50px;
  }
  .mobileProfileMenu .btnProfile > a,
  .mobileProfileMenu .btnLogout > a {
    width: auto;
    position: relative;
    color: #000;
    text-transform: uppercase;
    font-weight: 400;
    padding: 13px 1px;
    height: 50px;
  }
  .mobileProfileMenu .shadow {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    opacity: 0;
    top: 50px;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media print {
  .showOnPrint {
    display: inline !important;
  }
  .hideOnPrint {
    display: none !important;
  }
}

.ce-gallery .caption-list img {
  border-radius: 100%;
}

/*image background 35 */
.article-box.article-box-background {
  justify-content: stretch;
  align-items: center;
}
.article-box.article-box-background .ce-gallery.image-background figure {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  width: 100%;
  height: 100%;
}
.article-box.article-box-background .ce-gallery.image-background figure img {
  display: none;
}

.accordeonBody .downloadWrapper {
  margin-top: 15px;
  font-size: 90%;
}
.accordeonBody .downloadWrapper .image {
  width: 100%;
  max-width: 160px;
  display: inline-block;
}
.accordeonBody .downloadWrapper .bold {
  font-weight: bold;
}
.accordeonBody .downloadWrapper .downloadEntry {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: calc(100% - 160px);
  vertical-align: top;
  padding-left: 20px;
}
.accordeonBody .downloadWrapper .downloadEntry > span {
  box-sizing: border-box;
  padding-left: 40px;
}
.accordeonBody .downloadWrapper .downloadEntry i.icon-download {
  position: absolute;
  top: 10px;
  left: 20px;
}

.newsletter-holder {
  position: relative;
  min-height: 55px;
}
.newsletter-holder .newsletterField {
  cursor: pointer;
}
.newsletter-holder .checkbox {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-color: #eee;
  vertical-align: top;
  margin-right: 20px;
}
.newsletter-holder .checkbox.checked::after {
  content: " ";
  position: absolute;
  width: 14px;
  height: 14px;
  top: 5px;
  left: 5px;
  background-color: #000;
}
.newsletter-holder .label {
  margin: 10px 0;
  display: inline-block;
  vertical-align: top;
}

.imageBoxTeaserWrapper:not(.ie-browser) {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 5px;
  margin: 0 5px;
}
.imageBoxTeaserWrapper:not(.ie-browser) .fr-1 {
  grid-column: span 1;
}
.imageBoxTeaserWrapper:not(.ie-browser) .fr-2 {
  grid-column: span 2;
}
.imageBoxTeaserWrapper:not(.ie-browser) .fr-3 {
  grid-column: span 3;
}
.imageBoxTeaserWrapper:not(.ie-browser) .vh-1 {
  grid-row: span 1;
}
.imageBoxTeaserWrapper:not(.ie-browser) .vh-2 {
  grid-row: span 2;
}
.imageBoxTeaserWrapper:not(.ie-browser) .vh-3 {
  grid-row: span 3;
}
@media (max-width: 767px) {
  .imageBoxTeaserWrapper:not(.ie-browser) {
    grid-template-columns: 1fr 1fr;
  }
  .imageBoxTeaserWrapper:not(.ie-browser) .fr-1 {
    grid-column: auto / span 2;
  }
  .imageBoxTeaserWrapper:not(.ie-browser) .fr-2 {
    grid-column: auto / span 2;
  }
  .imageBoxTeaserWrapper:not(.ie-browser) .fr-3 {
    grid-column: auto / span 2;
  }
  .imageBoxTeaserWrapper:not(.ie-browser) .vh-1 {
    grid-row: auto / span 2;
  }
  .imageBoxTeaserWrapper:not(.ie-browser) .vh-2 {
    grid-row: auto / span 2;
  }
  .imageBoxTeaserWrapper:not(.ie-browser) .vh-3 {
    grid-row: auto / span 2;
  }
  .imageBoxTeaserWrapper:not(.ie-browser) .box50OnMobile {
    grid-column: auto / span 1;
    grid-row: auto / span 1;
  }
  .imageBoxTeaserWrapper:not(.ie-browser) .box50OnMobile .fdSliderStepsWrapper {
    display: none;
  }
  .imageBoxTeaserWrapper:not(.ie-browser) .fr-2.vh-1 {
    grid-row: auto / span 1;
  }
}

.imageBoxTeaserWrapper.ie-browser {
  display: block;
  overflow: hidden;
  margin: 0;
}
.imageBoxTeaserWrapper.ie-browser:after {
  clear: both;
  content: " ";
}
.imageBoxTeaserWrapper.ie-browser .newRow {
  clear: both;
}
.imageBoxTeaserWrapper.ie-browser .imageBoxWrapper {
  float: left;
  margin: 0 5px 5px;
}
.imageBoxTeaserWrapper.ie-browser .fr-1 {
  width: calc(33% - 5px);
}
.imageBoxTeaserWrapper.ie-browser .fr-2 {
  width: calc(66% - 5px);
}
.imageBoxTeaserWrapper.ie-browser .fr-3 {
  width: 100%;
}
.imageBoxTeaserWrapper.ie-browser .imageHolderWrapper .element {
  overflow: hidden;
}
@media (max-width: 767px) {
  .imageBoxTeaserWrapper.ie-browser .fr-1,
  .imageBoxTeaserWrapper.ie-browser .fr-2 {
    width: 100%;
    float: none;
  }
  .imageBoxTeaserWrapper.ie-browser .fr-1.box50OnMobile {
    width: calc(50% - 10px);
    float: left;
  }
}
@media (max-width: 479px) {
  .imageBoxTeaserWrapper.ie-browser .fr-1.box50OnMobile {
    width: 100%;
    float: none;
  }
}

.imageBoxTeaserWrapper .element {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.imageBoxTeaserWrapper .teaserSliderShow .image {
  position: relative;
  z-index: 1;
}

.imageBoxTeaserWrapper .teaserSliderShow .element:not(.active) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.imageBoxTeaserWrapper .teaserSliderShow .fdSliderStepsWrapper,
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderControllsWrapper {
  position: absolute;
}

.imageBoxTeaserWrapper .teaserSliderShow .fdSliderDurationBar {
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  left: 0;
  border-top: 1px solid #fff;
  box-shadow: 0 -1px 0px #000;
}
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderDurationBar .bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  background-color: #fff;
  height: 100%;
}

.imageBoxTeaserWrapper .teaserSliderShow .fdSliderControllsWrapper {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderControllsWrapper .arrow {
  position: absolute;
  width: 40px;
  height: 120px;
  top: 50%;
  margin-top: -60px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  cursor: pointer;
  color: #fff;
  font-size: 140%;
  text-align: center;
  line-height: 120px;
}
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderControllsWrapper .arrow.leftArrow {
  left: 0;
}
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderControllsWrapper .arrow.leftArrow i {
  transform: rotate(-180deg);
  display: inline-block;
}
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderControllsWrapper .arrow.leftArrow i:before {
  content: "\e90d";
}
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderControllsWrapper .arrow.rightArrow {
  right: 0px;
}

.imageBoxTeaserWrapper .teaserSliderShow .fdSliderStepsWrapper {
  bottom: 50px;
  left: 0;
  width: 100%;
  height: 0px;
  text-align: center;
  z-index: 10;
}
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderStepsWrapper .step {
  width: 10px;
  display: inline-block;
  height: 10px;
  box-sizing: border-box;
  margin: 0 5px;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  border: 2px solid #a8abab;
  background-color: #fff;
  vertical-align: top;
}
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderStepsWrapper .step:after {
  background-color: transparent;
  cursor: pointer;
  height: auto;
  width: auto;
  content: " ";
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
}
.imageBoxTeaserWrapper .teaserSliderShow .fdSliderStepsWrapper .step.active {
  background-color: #a8abab;
  height: 10px;
  width: 10px;
}

@media (max-width: 991px) {
  .imageBoxTeaserWrapper .teaserSliderShow .fdSliderStepsWrapper {
    bottom: 50px;
  }
}

.imageBoxTeaserWrapper .imageBoxWrapper {
  position: relative;
}
.imageBoxTeaserWrapper .imageBoxWrapper img {
  width: 100%;
  height: auto;
  width: 100%;
}
.imageBoxTeaserWrapper .imageBoxWrapper.fr-2.vh-2 h1 {
  max-width: 700px;
}
.imageBoxTeaserWrapper .imageBoxWrapper .imageWrapper {
  height: 100%;
  width: 100%;
}
.imageBoxTeaserWrapper .imageBoxWrapper .imageHolderWrapper,
.imageBoxTeaserWrapper .imageBoxWrapper .image {
  height: 100%;
  width: 100%;
}

.imageBoxTeaserWrapper h1 em {
  font-style: normal;
  display: inline-block;
  vertical-align: top;
  padding: 15px 15px 0;
  transition: background 0.3s ease-in-out;
}

.imageBoxTeaserWrapper .sub-title,
.imageBoxTeaserWrapper .titleWrapper {
  position: absolute;
  font-size: 16px;
  top: 12px;
  left: 15px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 300;
}

.imageBoxTeaserWrapper .invader {
  position: absolute;
  top: 12px;
  left: 5px;
  background-color: #000;
  color: #fff;
  padding: 8px 10px 4px;
  width: calc(100% - 10px);
  z-index: 10;
  font-size: 20px;
}
.imageBoxTeaserWrapper .invader p {
  margin: 0;
}
.imageBoxTeaserWrapper .invader.reverse {
  background-color: #fff;
  color: #000;
}
@media (max-width: 767px) {
  .imageBoxTeaserWrapper .invader {
    font-size: 16px;
  }
}

.imageBoxTeaserWrapper .titleWrapper {
  width: calc(100% - 30px);
  top: 6px;
}
.imageBoxTeaserWrapper .titleWrapper .sub-title,
.imageBoxTeaserWrapper .titleWrapper .invader {
  position: relative;
}
.imageBoxTeaserWrapper .titleWrapper .sub-title {
  margin-left: -15px;
  margin-top: -10px;
}
.imageBoxTeaserWrapper .titleWrapper .invader {
  margin: 0 -10px;
  width: calc(100% + 5px);
}

/* ie11 hack teaserboxen */
_:-ms-fullscreen,
:root .jobs .imageBoxTeaserWrapper {
  display: flex;
  overflow: hidden;
  margin: 0;
  flex-wrap: wrap;
}
_:-ms-fullscreen:after,
:root .jobs .imageBoxTeaserWrapper:after {
  clear: both;
  content: " ";
}
_:-ms-fullscreen .newRow,
:root .jobs .imageBoxTeaserWrapper .newRow {
  clear: both;
}
_:-ms-fullscreen .imageBoxWrapper,
:root .jobs .imageBoxTeaserWrapper .imageBoxWrapper {
  float: left;
  margin: 0;
}
_:-ms-fullscreen .vh-1,
:root .jobs .imageBoxTeaserWrapper .vh-1 {
  float: left;
  padding: 3px;
}
_:-ms-fullscreen .fr-1,
:root .jobs .imageBoxTeaserWrapper .fr-1 {
  width: 33.33%;
}
_:-ms-fullscreen .fr-2,
:root .jobs .imageBoxTeaserWrapper .fr-2 {
  width: 66.67%;
}
_:-ms-fullscreen .fr-3,
:root .jobs .imageBoxTeaserWrapper .fr-3 {
  width: 100%;
}
_:-ms-fullscreen .imageHolderWrapper .element,
:root .jobs .imageBoxTeaserWrapper .imageHolderWrapper .element {
  overflow: hidden;
}
@media (max-width: 767px) {
  _:-ms-fullscreen .fr-1,
  _:-ms-fullscreen .fr-2,
  :root .jobs .imageBoxTeaserWrapper .fr-1,
  :root .jobs .imageBoxTeaserWrapper .fr-2 {
    width: 100%;
    float: none;
  }
  _:-ms-fullscreen .fr-1.box50OnMobile,
  :root .jobs .imageBoxTeaserWrapper .fr-1.box50OnMobile {
    width: calc(50% - 10px);
    float: left;
  }
}
@media (max-width: 479px) {
  _:-ms-fullscreen .fr-1.box50OnMobile,
  :root .jobs .imageBoxTeaserWrapper .fr-1.box50OnMobile {
    width: 100%;
    float: none;
  }
}

/* ie11 hack teaserboxen end */
.owl-carousel[data-thumbs="false"] {
  margin-bottom: 30px;
}
.owl-carousel[data-thumbs="false"] .owl-dots {
  position: relative;
  padding: 0;
  width: 100%;
  height: 20px;
  display: block;
  margin-top: 30px !important;
  text-align: center;
  z-index: 10;
}
.owl-carousel[data-thumbs="false"] .owl-dots .owl-dot {
  width: 10px;
  display: inline-block;
  height: 10px;
  box-sizing: border-box;
  margin: 0 5px;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  border: 2px solid #a8abab;
  background-color: #fff;
  vertical-align: top;
}
.owl-carousel[data-thumbs="false"] .owl-dots .owl-dot.active {
  background-color: #a8abab;
  height: 10px;
  width: 10px;
}
.owl-carousel[data-thumbs="false"] .owl-dots .owl-dot:after {
  background-color: transparent;
  cursor: pointer;
  height: auto;
  width: auto;
  content: " ";
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-prev,
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next {
  display: block;
  color: #fff !important;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  border-radius: 100%;
  padding: 0;
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-prev:before,
.owl-carousel[data-thumbs="false"] .owl-nav .owl-prev:after,
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next:before,
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next:after {
  content: " ";
  width: 20px;
  height: 5px;
  background-color: #fff;
  position: absolute;
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-prev:before,
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next:before {
  top: 17px;
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-prev:after,
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next:after {
  top: 28px;
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-prev:before,
.owl-carousel[data-thumbs="false"] .owl-nav .owl-prev:after {
  left: 13px;
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-prev:before {
  transform: rotate(-45deg);
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-prev:after {
  transform: rotate(45deg);
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next {
  right: 3px;
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next:before,
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next:after {
  left: 17px;
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next:before {
  transform: rotate(45deg);
}
.owl-carousel[data-thumbs="false"] .owl-nav .owl-next:after {
  transform: rotate(-45deg);
}

.maxContainer,
.largeContainer {
  width: 100vw;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.largeContainer {
  max-width: 1920px;
}

/*# sourceMappingURL=maps/content.css.map */
