/*****************************************
*             DefaultStyle               *
******************************************/
.defaultFormStyle .rowWrapper {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: 0 0;
  margin-bottom: 10px;
  min-width: 400px;
}
.defaultFormStyle .rowWrapper.col12 {
  width: 100%;
}

.defaultFormStyle label {
  width: 100%;
  display: inline-block;
}

.defaultFormStyle .fullSize {
  width: 100%;
  clear: both;
  margin-top: 30px;
}

.defaultFormStyle input[type="text"],
.defaultFormStyle input[type="password"],
.defaultFormStyle input[type="mail"],
.defaultFormStyle textarea,
.defaultFormStyle select {
  width: 100%;
  max-width: 400px;
}

.defaultFormStyle input[type="text"],
.defaultFormStyle input[type="password"],
.defaultFormStyle input[type="mail"] {
  padding: 7px 12px 2px;
}

.defaultFormStyle textarea {
  min-height: 150px;
}

.defaultFormStyle select {
  padding: 12px 10px 5px;
}

.defaultFormStyle .g-recaptcha > div {
  display: inline-block;
}

.defaultFormStyle button.btn {
  width: 304px;
}
.defaultFormStyle button.btn.small {
  width: 204px;
}

.defaultFormStyle .important,
.defaultFormStyle .important * {
  color: #e1001a !important;
}

.defaultFormStyle .submitHolder.alignLeft {
  text-align: left;
}

.defaultFormStyle .smallLabel p {
  font-size: 75%;
  margin-top: 5px;
  margin-bottom: 0;
}

.defaultFormStyle .checkboxWrapper .checkbox {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
}
.defaultFormStyle .checkboxWrapper .checkbox input[type="checkbox"] {
  position: relative;
  margin: 9px;
}

.defaultFormStyle .checkboxWrapper label {
  cursor: pointer;
  width: auto;
  display: inline-block;
  vertical-align: top;
  margin: 13px 0;
}

/*****************************************
*              CustomStyle               *
******************************************/
div.defaultFormStyle {
  position: relative;
}
div.defaultFormStyle .feedbackMessage {
  min-height: 350px;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  align-items: center;
}
div.defaultFormStyle .errorField {
  margin-top: 7px;
  color: #e1001a;
  font-size: 80%;
}
div.defaultFormStyle .captchaWrapper {
  text-align: right;
}
div.defaultFormStyle input {
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
  display: inline-block;
  padding: 0 5px;
  cursor: pointer;
  background: transparent;
  text-align: left;
  color: #000;
  font-weight: 400;
  border-bottom: 1px solid #e6e6e6;
  transition: border-color 0.3s ease-in-out 0s;
}
div.defaultFormStyle input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
div.defaultFormStyle input:focus {
  outline: none;
  border-color: #000;
}
div.defaultFormStyle .rowWrapper {
  position: relative;
  padding-top: 24px;
}
div.defaultFormStyle .rowWrapper.selectbox label,
div.defaultFormStyle .rowWrapper.textarea label {
  position: relative;
  left: 0;
  top: 0px;
}
@media (max-width: 991px) {
  div.defaultFormStyle .rowWrapper {
    margin: 0 auto 10px;
    display: block;
    width: 90%;
    min-width: 100%;
  }
  div.defaultFormStyle .rowWrapper input[type="text"],
  div.defaultFormStyle .rowWrapper input[type="mail"],
  div.defaultFormStyle .rowWrapper textarea,
  div.defaultFormStyle .rowWrapper select,
  div.defaultFormStyle .rowWrapper .jcf-select.jcf-select-primary-select {
    max-width: 100%;
  }
}
div.defaultFormStyle .rowWrapper.important label {
  color: #e1001a;
}
div.defaultFormStyle .rowWrapper.important input {
  border-bottom-color: #e1001a;
}
div.defaultFormStyle label:not(.noTransform) {
  transition: left 0.2s linear, top 0.2s linear;
  color: #666;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  position: absolute;
  top: 35px;
  left: 10px;
}
div.defaultFormStyle label:not(.noTransform).focus {
  top: 12px;
  left: 0px;
  color: #000;
}
div.defaultFormStyle .rowWrapper.selectbox,
div.defaultFormStyle .rowWrapper.textarea {
  padding-top: 5px;
}
div.defaultFormStyle .jcf-select.jcf-select-primary-select {
  max-width: 400px;
}
div.defaultFormStyle .rowWrapper p {
  margin: 0px;
  display: inline-block;
}
div.defaultFormStyle .rowWrapper p.tx-srfeuserregister-pi1-error {
  color: #e1001a;
  font-size: 14px;
  display: block;
}
div.defaultFormStyle .submitHolder {
  text-align: center;
}
@media (min-width: 992px) {
  div.defaultFormStyle .submitHolder {
    text-align: right;
  }
}
@media (max-width: 991px) {
  div.defaultFormStyle .submitHolder .btn {
    font-size: 26px;
    padding: 10px 14px 2px 14px;
  }
}
@media (max-width: 767px) {
  div.defaultFormStyle .submitHolder .btn {
    font-size: 20px;
    width: 100%;
    padding: 10px 14px 5px 14px;
  }
}
div.defaultFormStyle .labelRequiredFields {
  color: #666;
  display: block;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0 15px;
  text-align: right;
}
@media (min-width: 768px) {
  div.defaultFormStyle .labelRequiredFields {
    margin: 15px 0 30px;
  }
}
@media (max-width: 767px) {
  div.defaultFormStyle .rowWrapper.spacer {
    display: none;
  }
}

div.middleContainer .defaultFormStyle .rowWrapper.selectbox:nth-child(2n) label,
div.middleContainer .defaultFormStyle .rowWrapper.textarea:nth-child(2n) label {
  padding-left: 0px;
}

div.middleContainer .defaultFormStyle .rowWrapper:nth-child(2n) {
  padding-left: 123px;
}
@media (max-width: 991px) {
  div.middleContainer .defaultFormStyle .rowWrapper:nth-child(2n) {
    padding-left: 0px;
  }
}
div.middleContainer .defaultFormStyle .rowWrapper:nth-child(2n) label {
  padding-left: 123px;
}
@media (max-width: 991px) {
  div.middleContainer .defaultFormStyle .rowWrapper:nth-child(2n) label {
    padding-left: 0px;
  }
}

div.middleContainer .defaultFormStyle .rowWrapper.col12:nth-child(2n) {
  padding-left: 0px;
}
div.middleContainer .defaultFormStyle .rowWrapper.col12:nth-child(2n) label {
  padding-left: 0px;
}

.tx-felogin-pi1 h3 {
  line-height: 1.2;
  margin: 0;
  font-size: 22px;
}
@media (min-width: 768px) {
  .tx-felogin-pi1 h3 {
    font-size: 25px;
    margin: 0 0 5px;
  }
}
@media (min-width: 992px) {
  .tx-felogin-pi1 h3 {
    font-size: 30px;
  }
}

#popup .defaultFormStyle .rowWrapper {
  width: 100%;
  min-width: auto;
}

#popup .colTeaser {
  padding: 0;
}

#popup .colTeaser > div {
  padding: 0 40px;
}
#popup .colTeaser > div:first-child {
  padding-left: 0;
}
#popup .colTeaser > div:last-child {
  padding-right: 0;
}

@media (max-width: 767px) {
  .colTeaser > div {
    border: none;
  }
  .colTeaser > div:last-child {
    margin: 40px 0;
  }
  #popup .colTeaser > div {
    padding: 0 0;
  }
}

[id^="prflabor-"] h2 {
  margin-bottom: 1em;
}

[id^="prflabor-"] .form-group {
  position: relative;
}
[id^="prflabor-"] .form-group:nth-child(7) .label-active {
  top: -2px;
  left: 5px;
}

[id^="prflabor-"] .form-control {
  position: relative;
  margin-top: 18px;
  height: 33px !important;
  font-size: 16px;
}

[id^="prflabor-"] .control-label {
  position: absolute;
  top: 25px;
  left: 20px;
  color: #666;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.3s ease;
  font-size: 16px;
}

[id^="prflabor-"] .label-active {
  top: 0;
  left: 5px;
}

[id^="prflabor-"] .jcf-select.jcf-select-primary-select .jcf-select-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  padding: 8px 50px 2px 15px;
  margin-top: 18px;
}

[id^="prflabor-"] .note {
  font-size: 16px;
  padding-left: 20px;
  padding-top: 30px;
}

[id^="prflabor-"] .textarea {
  height: 200px !important;
  border: 1px solid #e6e6e6;
}
[id^="prflabor-"] .textarea.focus {
  border-color: #000;
}

[id^="prflabor-"] .help-block {
  display: none;
}

[id^="prflabor-"] .has-error .form-control {
  box-shadow: none;
  border-color: #b54d4d;
  background-color: rgba(255, 50, 50, 0.5);
}

[id^="kontakt-"] h2 {
  margin-bottom: 1em;
}

[id^="kontakt-"] .form-group {
  position: relative;
}

[id^="kontakt-"] .form-row:nth-last-child(4) {
  margin-top: 20px;
}
[id^="kontakt-"] .form-row:nth-last-child(4) .label-active {
  top: -4px;
  left: 5px;
}

[id^="kontakt-"] .form-control {
  position: relative;
  margin-top: 18px;
  height: 33px !important;
  font-size: 16px;
}

[id^="kontakt-"] .control-label {
  position: absolute;
  top: 25px;
  left: 20px;
  color: #666;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.3s ease;
  font-size: 16px;
}

[id^="kontakt-"] .label-active,
[id^="kontakt-"] .stay-active {
  top: 0;
  left: 5px;
}

[id^="kontakt-"] .jcf-select.jcf-select-primary-select .jcf-select-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  padding: 8px 50px 2px 15px;
  margin-top: 18px;
}

[id^="kontakt-"] .note {
  font-size: 16px;
  padding-left: 20px;
  padding-top: 30px;
}

[id^="kontakt-"] .textarea {
  height: 200px !important;
  border: 1px solid #e6e6e6;
}
[id^="kontakt-"] .textarea.focus {
  border-color: #000;
}

[id^="kontakt-"] .help-block {
  display: none;
}

[id^="kontakt-"] .clearfix p {
  font-size: 16px;
  padding-left: 2px;
  margin-bottom: 0;
}

[id^="kontakt-"] .btn-group {
  width: 100%;
}
[id^="kontakt-"] .btn-group .btn {
  float: right;
}

[id^="kontakt-"] .custom-checkbox .custom-control-label {
  padding: 0 10px;
  font-weight: 300;
}

[id^="kontakt-"] .custom-checkbox .is-invalid + .custom-control-label {
  background-color: rgba(255, 50, 50, 0.7);
}

[id^="kontakt-"] .has-error .form-control {
  box-shadow: none;
  border-color: red;
}

body > .jcf-select-drop.jcf-select-primary-select .jcf-list .jcf-option {
  padding: 8px 25px 4px;
}

.defaultFormStyle .stepView {
  display: none;
}

.defaultFormStyle .stepView.stepActive {
  display: block;
}

.defaultFormStyle .registerRow label:not(.noTransform) {
  top: 30px;
}

.defaultFormStyle .registerRow .has-error label:not(.noTransform) {
  top: 5px;
  left: 0px;
}

.defaultFormStyle .registerRow .has-error .form-control {
  box-shadow: none;
  border-color: red;
}

.defaultFormStyle .registerRow .has-error .alert {
  font-size: 13px;
  color: red;
}

/*# sourceMappingURL=maps/formStyle.css.map */
