@charset "UTF-8";
@media print {
  /* Styles for print version */
  * {
    background: none !important;
    color: #000 !important;
  }
  img {
    filter: contrast(110%) brightness(110%) sepia(30%) grayscale(100%);
    border: 1px solid #000;
  }
  img:before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    background: rgba(0, 0, 0, 0);
  }
  h1 {
    font-size: 20px;
  }
  .nav-holder,
  .btn-with-ico,
  .footer,
  .btn,
  .filter-frame,
  .sub-nav-list,
  .media-communications-holder,
  .article-box .image-holder,
  .we-hire,
  .category-list .delete i,
  .bersicht-sidebar,
  .newsletter-form,
  .jcf-select.jcf-select-primary-select.jcf-select-primary-light-select,
  .category-list,
  .search-category-form,
  .jcf-select.jcf-select-tabset-select {
    display: none !important;
  }
  .header {
    padding: 0;
    position: static !important;
  }
  .logo img {
    display: block;
  }
  .logo img:first-child {
    display: none;
  }
  .twocolumns {
    text-align: center;
  }
  .twocolumns .text-box {
    width: 100%;
  }
  a:after {
    content: " (" attr(href) ") ";
    display: none;
  }
  #main {
    padding: 20px 0 0;
  }
  .twocolumns .image-holder {
    display: none;
  }
  .column-box {
    padding: 30px 0 0;
  }
  .box-frame img {
    filter: contrast(110%) brightness(110%) sepia(30%) grayscale(100%);
    border: 1px solid #000;
  }
  .box-frame img:before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    background: rgba(0, 0, 0, 0);
  }
  .box-frame h1,
  .box-frame h2,
  .box-frame .sub-title {
    background: #fff !important;
    padding: 6px 5px 2px;
    z-index: 9999;
  }
  .column-box .box-frame {
    display: block;
    width: auto;
  }
  .column-box .box-frame .column.column-lg {
    display: block;
    display: inline-block;
    vertical-align: top;
    width: 66%;
  }
  .column-box .box-frame .column {
    display: block;
    display: inline-block;
    vertical-align: top;
    width: 33%;
  }
  .column-box .box-frame .column .box-holder {
    display: block;
    width: 100%;
  }
  .box-frame h1 {
    font-size: 20px;
    line-height: 1;
  }
  .box-frame h2 {
    font-size: 17px;
    line-height: 1;
  }
  .box-frame h2 em {
    font-size: 12px;
    line-height: 1;
  }
  .box-frame .sub-title {
    font-size: 12px;
    line-height: 1;
  }
  .sitemap-section .column-holder {
    overflow: hidden;
  }
  .sitemap-section .column {
    width: 33.333%;
    float: left;
  }
  .sitemap-section .js-slide-hidden {
    position: static !important;
    left: 0 !important;
    top: 0 !important;
    display: block !important;
    height: auto !important;
  }
  .sitemap-section .sitemap-opener {
    font-size: 18px !important;
  }
  .sitemap-section .drop-sitemap {
    font-size: 14px !important;
  }
  .sitemap-section .drop-sitemap-slide li {
    font-size: 12px !important;
  }
  .sitemap-section .drop-sitemap-slide li a {
    font-size: 12px !important;
  }
  .box-frame.full-width-mobile {
    display: block !important;
    overflow: hidden;
  }
  .box-frame.full-width-mobile .box-holder {
    position: static !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 33% !important;
    float: left;
  }
  .title-section .title-text {
    position: static !important;
    top: 0 !important;
    left: 0 !important;
    padding: 50px 0 0;
  }
  .title-section .image-holder img {
    display: none;
  }
  .article-box {
    display: block !important;
  }
  .article-box .text-box {
    width: 100%;
    padding: 0;
  }
  .article-box.reverse .text-box {
    padding: 0;
  }
  .medium-container {
    padding: 0;
  }
  .title-section h1 span {
    font-size: 30px;
  }
  .sitemap-section h2 {
    font-size: 15px;
  }
  .medium-container .contact-box {
    float: left;
  }
  .medium-container .contacts-holder .image-holder {
    float: left;
  }
  .geschichte-frame li {
    display: block !important;
    margin: 0 0 15px;
  }
  .geschichte-frame .inner-box {
    padding: 0 !important;
    width: 100%;
    text-align: left !important;
  }
  .geschichte-frame * {
    opacity: 1 !important;
  }
  .geschichte-frame img {
    display: none;
  }
  .tab .result .image-holder {
    display: none;
  }
  .contacts-content {
    display: none;
  }
  .maps-form {
    display: none;
  }
  .contacts-sidebar {
    width: 100%;
  }
  .places-list .slide {
    position: static !important;
    top: 0 !important;
    left: 0 !important;
  }
  .category-list {
    display: block;
    padding: 10px 0 0;
  }
  .category-list li {
    width: 32%;
    display: inline-block;
    vertical-align: top;
  }
  .category-list li.active .btn-category {
    border: 4px solid #000;
  }
  .category-list .btn-category {
    border: 1px solid #000;
  }
  .category-list span {
    font-size: 14px;
  }
  .result-holder {
    display: block;
  }
  .result-product-box {
    width: 30%;
    display: inline-block;
    vertical-align: top;
  }
  .bersicht-content-frame {
    overflow: visible !important;
  }
  .bersicht-content-frame .bersicht-content {
    overflow: visible !important;
  }
  .bersicht-content-frame .col-sm-4 {
    width: 0;
    display: none;
  }
  .bersicht-content-frame .col-sm-8 {
    width: 100%;
  }
  .search-category-form {
    margin: 0 0 27px;
  }
  .search-category-form .tabset-holder {
    justify-content: flex-end;
  }
  .place-of-search {
    margin: 0 0 38px;
    display: block !important;
  }
  .place-of-search .jcf-select.jcf-select-primary-select {
    display: none;
    width: 30%;
    margin: 0 0 10px;
  }
  .place-of-search .btn-box {
    display: block;
    margin: 0 0 10px;
    overflow: hidden;
  }
  .place-of-search .btn-box .btn {
    font-weight: 400;
    color: #7f7f7f;
    background: #e6e6e6;
    border-color: #e6e6e6;
    text-transform: none;
    margin: 0 0 0 6px;
    width: 200px !important;
    font-size: 22px;
    display: block !important;
    float: left;
  }
  .place-of-search .btn-box .btn:first-child {
    margin: 0;
  }
  .place-of-search .btn-box .btn.active {
    color: #fff;
    background: #000;
    border-color: #000;
    border-width: 2px;
  }
  .place-of-search .primary-light-select {
    display: none;
  }
  .result-holder {
    margin: 0 -5px 48px;
  }
  .result-product-box {
    width: 30%;
    padding: 0 5px;
    margin: 0 0 20px;
    display: inline-block;
    vertical-align: top;
  }
  .result-product-box .image-frame {
    position: relative;
    overflow: hidden;
  }
  .result-product-box .image-frame img {
    vertical-align: top;
    width: 100%;
  }
  .result-product-box h3 {
    margin: 0;
    font-weight: normal;
    font-size: 22px;
    color: #242424;
  }
  .result-product-box p {
    margin: 0;
    font-size: 16px;
    line-height: 1.25;
    color: #666;
    font-weight: 300;
    display: block;
  }
  .result-product-box .sub-category {
    text-transform: uppercase;
    color: #fff;
    background: #000;
    position: absolute;
    left: 0;
    line-height: 1;
    font-weight: normal;
    font-size: 22px;
    top: 15px;
    padding: 6px 13px 0 14px;
  }
  .result-product-box .more {
    font-weight: normal;
    position: absolute;
    bottom: 5px;
    right: 0;
    text-transform: uppercase;
    color: #fff;
    line-height: 1;
    font-weight: 400;
    font-size: 10px;
    padding: 9px 15px 5px;
    z-index: 1;
    overflow: hidden;
    max-width: 70%;
  }
  .result-product-box .more:after {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: #000;
    z-index: -1;
  }
  .result-product-box .more em {
    display: inline-block;
    font-style: normal;
    transform: translate(0, 0) !important;
    color: #000 !important;
  }
  .result-product-box .more i {
    display: inline;
    color: #fff;
    font-size: 14px;
    margin: 0 0 0 10px;
  }
  .result-product-box .family {
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    border-radius: 50%;
    background: #000;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 10px;
    bottom: 15px;
    left: 15px;
  }
  .result-product-box .text-box {
    padding: 12px 0 0 16px;
  }
  .sub-nav {
    text-align: center;
  }
  .sub-nav li {
    display: inline-block;
    vertical-align: top;
  }
  .sub-nav a {
    color: #000 !important;
  }
  .article-list .owl-carousel {
    display: none !important;
  }
  .info-product-frame .owl-carousel {
    display: none !important;
  }
  .info-product-frame p {
    font-size: 14px !important;
  }
  .white-frame {
    display: none;
  }
  .head-column {
    padding: 0;
  }
  .head-article {
    margin: 0 !important;
  }
  .head-article .head-column {
    display: inline-block !important;
    width: 16% !important;
  }
  .head-article .head-column strong {
    font-size: 14px !important;
  }
  .article-list .content-slide {
    width: 100%;
    padding: 0 !important;
  }
  .article-list .head-article {
    margin: 0;
  }
  .article-list .head-article .head-column {
    display: inline-block;
    width: 16%;
  }
  .article-list .head-article .head-column strong {
    font-size: 14px !important;
  }
  .article-list .article-opener {
    display: none;
  }
  .article-list li {
    border-bottom: 0 !important;
  }
  .article-list .column-holder .column {
    display: inline-block !important;
    vertical-align: top !important;
    width: 25% !important;
    margin: 0 20px 20px !important;
  }
  .article-list .column-holder .column:first-child {
    width: 60% !important;
  }
  .article-list .column-holder .sub-column {
    width: 40% !important;
    display: inline-block;
    vertical-align: top;
  }
  .product-description-accordion {
    overflow: hidden;
  }
  .product-description-accordion dl {
    padding: 0 !important;
  }
  .product-description-accordion dl dt,
  .product-description-accordion dl dd {
    font-size: 14px !important;
  }
  .product-description-accordion .product-description {
    width: 45%;
    display: inline-block;
    vertical-align: top;
    border-top: 0 !important;
    margin: 0 15px 20px;
  }
  .product-description-accordion .product-description ul {
    padding: 0;
  }
  .product-description-accordion .product-description ul li {
    font-size: 14px !important;
  }
  .product-description-accordion .js-slide-hidden {
    position: static !important;
    top: 0 !important;
    left: 0 !important;
  }
  .product-description-accordion .row-opener .opener-link {
    display: none !important;
  }
  .product-description-accordion .row-opener .fake-opener {
    display: block !important;
  }
  .article-slide.js-slide-hidden {
    position: static !important;
    top: 0 !important;
    left: 0 !important;
  }
  .familie-product .other-product-list li {
    width: 25% !important;
    display: inline-block;
    vertical-align: top;
  }
  .newsletter-form .col-md-5 {
    width: 45%;
    float: right;
  }
  .newsletter-form .col-md-5:first-child {
    float: left;
  }
  .newsletter-form .jcf-select.jcf-select-primary-select {
    border-bottom: 1px solid #ccc;
  }
  .newsletter-form .custom-check .fake-input {
    border: 1px solid #ccc;
  }
  .newsletter-form .custom-check [type="checkbox"]:checked + .fake-input {
    border: 8px solid #000;
    width: 8px;
    height: 8px;
  }
  .result-holder {
    display: block !important;
  }
  .result-product-box {
    display: inline-block !important;
    vertical-align: top !important;
    width: 30% !important;
  }
  .places-list {
    max-height: none !important;
  }
}

/* Generated by Font Squirrel (https://www.fontsquirrel.com) on January 20, 2017 */
@font-face {
  font-family: "geomanist";
  font-weight: 300;
  src: url("../fonts/geomanist-light-webfont.woff2") format("woff2"), url("../fonts/geomanist-light-webfont.woff") format("woff");
}

@font-face {
  font-family: "geomanist";
  font-weight: 400;
  src: url("../fonts/geomanist-book-webfont.woff2") format("woff2"), url("../fonts/geomanist-book-webfont.woff") format("woff");
}

@font-face {
  font-family: "geomanist";
  font-weight: 700;
  src: url("../fonts/geomanist-bold-webfont.woff2") format("woff2"), url("../fonts/geomanist-bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoonc3eb.svg?ufm80i#icomoon") format("svg"), url("../fonts/icomoonc3eb.woff2?ufm80i") format("woff2"), url("../fonts/icomoonc3eb.woff?ufm80i") format("woff");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: "\e900";
}

.icon-add:before {
  content: "\e901";
}

.icon-ok:before {
  content: "\e902";
}

.icon-dbl-arrow:before {
  content: "\e903";
}

.icon-family:before {
  content: "\e904";
}

.icon-linkedin:before {
  content: "\e905";
}

.icon-instagram:before {
  content: "\e906";
}

.icon-youtube-play:before,
.icon-youtube:before {
  content: "\e907";
}

.icon-facebook:before {
  content: "\e908";
}

.icon-pinterest:before {
  content: "\e909";
}

.icon-cross:before {
  content: "\e90a";
}

.icon-vimeo:before {
  content: "\e90b";
}

.icon-lumus:before {
  content: "\e90c";
}

.icon-arrow-right:before {
  content: "\e90d";
}

.icon-filter:before {
  content: "\e90e";
}

.icon-download:before {
  content: "\e90f";
}

.icon-message:before {
  content: "\e910";
}

.icon-twitter:before {
  content: "\e911";
}

.icon-envelope:before {
  content: "\e912";
}

.icon-cancel:before {
  content: "\e913";
}

.icon-print:before {
  content: "\e914";
}

.icon-uniE912:before {
  content: "\e915";
}

.icon-uniE913:before {
  content: "\e916";
}

.icon-uniE914:before {
  content: "\e917";
}

.icon-search:before {
  content: "\e918";
}

.icon-pdf:before {
  content: "\e919";
}

.icon-external-url:before {
  content: "\e91a";
}

.icon-copy:before {
  content: "\e91b";
}

.icon-login:before {
  content: "\e91c";
}

.icon-profil:before {
  content: "\e91d";
}

.icon-logout:before {
  content: "\e91e";
}

.icon-newArrow:before {
  content: "\e920";
}

.icon-location:before {
  content: "\e921";
}

.icon-phone:before {
  content: "\e91f";
}

/* custom range input styles */
.jcf-range {
  display: inline-block;
  min-width: 200px;
  margin: 0 10px;
  width: 130px;
}

.jcf-range .jcf-range-track {
  margin: 0 20px 0 0;
  position: relative;
  display: block;
}

.jcf-range .jcf-range-wrapper {
  background: #e5e5e5;
  border-radius: 5px;
  display: block;
  margin: 5px 0;
  height: 10px;
}

.jcf-range.jcf-vertical {
  width: auto;
}

.jcf-range.jcf-vertical .jcf-range-wrapper {
  margin: 0;
  width: 10px;
  height: auto;
  padding: 20px 0 0;
}

.jcf-range.jcf-vertical .jcf-range-track {
  height: 180px;
  width: 10px;
}

.jcf-range.jcf-vertical .jcf-range-handle {
  left: -5px;
  top: auto;
}

.jcf-range .jcf-range-handle {
  position: absolute;
  background: #aaa;
  border-radius: 19px;
  width: 19px;
  height: 19px;
  margin: -4px 0 0;
  z-index: 1;
  top: 0;
  left: 0;
}

.jcf-range .jcf-range-mark {
  position: absolute;
  overflow: hidden;
  background: #000;
  width: 1px;
  height: 3px;
  top: -7px;
  margin: 0 0 0 9px;
}

.jcf-range.jcf-vertical .jcf-range-mark {
  margin: 0 0 9px;
  left: 14px;
  top: auto;
  width: 3px;
  height: 1px;
}

.jcf-range.jcf-focus .jcf-range-handle {
  border: 1px solid #f00;
  margin: -5px 0 0 -1px;
}

.jcf-range.jcf-disabled {
  background: none !important;
  opacity: 0.3;
}

/* custom select styles */
.jcf-select {
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 0;
  background: none;
  margin: 4px 76px 0 0;
  min-width: none;
  height: 16px;
  color: #fff;
}
@media (min-width: 768px) {
  .jcf-select {
    display: none;
  }
}
.jcf-select.jcf-select-tabset-select,
.jcf-select.jcf-select-primary-select {
  display: block;
  background: #f2f2f2;
  height: 26px;
  margin: 0 0 5px;
}
.jcf-select.jcf-select-tabset-select .jcf-select-text,
.jcf-select.jcf-select-primary-select .jcf-select-text {
  color: #000;
  font-size: 14px;
  padding: 7px 25px 1px 2px;
  position: relative;
}
.jcf-select.jcf-select-tabset-select .jcf-select-text:after,
.jcf-select.jcf-select-primary-select .jcf-select-text:after {
  font-family: "icomoon" !important;
  content: "\e90d";
  clear: both;
  display: block;
  position: absolute;
  top: 50%;
  right: 5px;
  margin: 2px 0 0;
  transform: translate(0, -50%) rotate(90deg);
  transition: all 0.2s linear;
}
.jcf-select.jcf-select-tabset-select.jcf-drop-active .jcf-select-text:after,
.jcf-select.jcf-select-primary-select.jcf-drop-active .jcf-select-text:after {
  transform: translate(0, -50%) rotate(-90deg);
}
.jcf-select.jcf-select-primary-select {
  height: 33px;
  cursor: pointer;
  position: relative;
  max-width: 344px;
}
.jcf-select.jcf-select-primary-select .jcf-select-text {
  font-size: 18px;
  line-height: 23px;
  padding: 8px 50px 2px 22px;
  color: #242424;
  font-weight: normal;
  cursor: pointer;
}
.jcf-select.jcf-select-primary-select .jcf-select-text:after {
  right: 20px;
}
.jcf-select.jcf-select-primary-select.jcf-select-primary-light-select {
  background: none;
}
@media (max-width: 767px) {
  .jcf-select.jcf-select-primary-select.jcf-select-primary-light-select {
    display: none;
  }
}
.jcf-select.jcf-select-primary-select.jcf-select-primary-light-select .jcf-select-text {
  color: #666;
  padding: 8px 30px 2px 22px;
}
.jcf-select.jcf-select-primary-select.jcf-select-primary-light-select .jcf-select-text:after {
  font-size: 12px;
  right: 12px;
  margin: 1px 0 0;
}

.jcf-select select {
  z-index: 1;
  left: 0;
  top: 0;
}

.jcf-select .jcf-select-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 0 2px;
}

.jcf-select .jcf-select-opener {
  display: none;
}

body > .jcf-select-drop {
  position: absolute;
  margin: -1px 0 0;
  z-index: 9999;
}
body > .jcf-select-drop.jcf-select-tabset-select {
  width: auto !important;
  right: 5px;
}
body > .jcf-select-drop.jcf-select-primary-select {
  min-width: 200px;
}
body > .jcf-select-drop.jcf-select-primary-select .jcf-list .jcf-option {
  padding: 5px 25px;
}

body > .jcf-select-drop.jcf-drop-flipped {
  margin: 1px 0 0;
}

.jcf-select .jcf-select-drop {
  position: absolute;
  margin-top: 0px;
  z-index: 9999;
  top: 100%;
  left: -1px;
  right: -1px;
}

.jcf-select .jcf-drop-flipped {
  bottom: 100%;
  top: auto;
}

.jcf-select-drop .jcf-select-drop-content {
  border: 0;
}

/* multiple select styles */
.jcf-list-box {
  overflow: hidden;
  display: inline-block;
  border: 0;
  min-width: 200px;
  margin: 0 15px;
}

/* select options styles */
.jcf-list {
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: #000;
  line-height: 24px;
  font-size: 16px;
  width: 100%;
}

.jcf-list .jcf-list-content {
  vertical-align: top;
  display: inline-block;
  overflow: auto;
  width: 100%;
}

.jcf-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.jcf-list ul li {
  overflow: hidden;
  display: block;
}

.jcf-list .jcf-overflow {
  overflow: auto;
}

.jcf-list .jcf-option {
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  display: block;
  padding: 5px 2px;
  color: #fff;
  height: 1%;
}

.jcf-list .jcf-disabled {
  background: #fff !important;
  color: #aaa !important;
}

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
  background: #e6e6e6;
  color: #000;
}

.jcf-list .jcf-optgroup-caption {
  white-space: nowrap;
  font-weight: bold;
  display: block;
  padding: 5px 9px;
  cursor: default;
  color: #000;
}

.jcf-list .jcf-optgroup .jcf-option {
  padding-left: 30px;
}

/* common custom form elements styles */
.jcf-disabled {
  background: #ddd !important;
}

.jcf-focus,
.jcf-focus * {
  border-color: #f00 !important;
}

/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.html) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.html) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

/**
 * Animate a list of elements sequentially
 *
 * @author FOURDEGREES
 * @param {string} $target             - selector of animated item 
 * @param {number} $initialDelay = 0.3 - delay until animation begins
 * @param {number} $duration = 0.5     - duration for each animation
 * @param {number} $delay = 0.2        - delay between each animation
 * @param {number} $itemCount = 10     - number of animated items
 */
.nav-wrapper .mainnavigation .holderNavLevel .navbar-nav,
.nav-wrapper .holderNavLevel1--horizontal .navLevel1,
.language-menu__list,
.footer .slide,
.footer .sitemap,
.footer .social-list,
.footer .menu-box__list,
.footer .language-box__list,
.footer .meta-box__list,
.sub-nav-list,
.social-plagin,
.detailView .project-list,
.other-product-list,
.media-communications-list,
.partner-list,
.sitemap-section .sitemap-slide > ul,
.sitemap-section .drop-sitemap-slide,
.tabset-holder .tabset,
.sub-nav,
.product-description-accordion ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clearfix:after,
.tab .result:after,
.product-description-accordion dl:after {
  content: "";
  display: block;
  clear: both;
}

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

/* page logo styles */
.logo {
  height: 48px;
  padding-top: 9px;
  width: 100%;
  max-width: 160px;
  margin-top: -2px;
  margin-left: 10px;
}
@media (min-width: 768px) {
  .logo {
    padding-top: 0px;
  }
}
@media (min-width: 1400px) {
  .logo {
    max-width: 202px;
  }
}
.logo a {
  background-position: right center;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-indent: -33333em;
  background-image: url(../images/logo_white.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  overflow: hidden;
}

/* header styles */
.header {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  min-height: 50px;
}
@media (min-width: 768px) {
  .header {
    min-height: 70px;
  }
}
@media (min-width: 1400px) {
  .header {
    min-height: 100px;
  }
}
@media (max-width: 767px) {
  .header .container-fluid {
    padding: 0 19px;
  }
}

.heder-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: transparent;
  padding: 0 5px;
}
@media (min-width: 768px) {
  .heder-top {
    padding: 10px 0 7px;
  }
}
@media (min-width: 1400px) {
  .heder-top {
    padding: 28px 0 14px;
  }
}

.nav-holder {
  padding: 12px 0 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .nav-holder {
    padding: 8px 2px 0 0;
  }
}
@media (min-width: 1400px) {
  .nav-holder {
    padding: 8px 2px 0 0;
  }
}

.search-form {
  margin: -9px 0 0 0;
  padding: 0;
}
@media (min-width: 1400px) {
  .search-form {
    margin: -12px 0 0 0;
  }
}
@media (min-width: 768px) {
  .search-form:hover .form-control {
    width: 40px;
  }
}
.search-form .fake {
  position: absolute;
  top: 5px;
  left: 0;
  width: 40px;
  height: 30px;
  z-index: 1;
  display: block;
}
.search-form .submit:before {
  opacity: 1;
  position: absolute;
  left: 10px;
  top: 13px;
}
@media (min-width: 1400px) {
  .search-form .submit:before {
    margin-top: 3px;
  }
}
.search-form:hover .submit:before {
  color: #7f7f7f;
}
@media (min-width: 768px) {
  .search-form.child-input-focused .form-control {
    width: 100%;
    padding: 6px 4px 2px !important;
    text-indent: inherit;
  }
  .search-form.child-input-focused .submit:before {
    opacity: 1;
  }
  .search-form.child-input-focused .submit:after {
    opacity: 0;
  }
}
.search-form .sub-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}
.search-form .form-group {
  margin: 0px;
  width: 90%;
  display: inline-block;
}
@media (min-width: 768px) {
  .search-form .form-group {
    margin: -1px auto auto;
    min-width: 40px;
  }
}
@media (min-width: 1400px) {
  .search-form .form-group {
    margin: 5px auto auto;
    min-width: 440px;
  }
}
.search-form .form-control {
  color: #fff;
  border-bottom: 1px solid #fff;
}
.search-form .form-control:focus {
  box-shadow: none;
  border-color: #fff;
}
@media (max-width: 767px) {
  .search-form .form-control {
    font-size: 14px;
    height: 18px;
    line-height: 2.2em;
  }
}
@media (min-width: 768px) {
  .search-form .form-control {
    width: 0;
    padding: 0 !important;
    transition: width 0.3s ease-in-out, padding 0.3s ease-in-out, text-indent 0.3s ease-in-out;
    float: left;
    text-indent: -9999px;
  }
}
.search-form .submit {
  color: #fff;
  outline: none;
  min-width: 30px;
  margin: 3px 0 10px 10px;
  font-size: 20px;
  width: 30px;
  height: 22px;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .sub-search-form .form-control {
    font-size: 14px;
    height: 18px;
    line-height: 2.2em;
  }
}

body.nav-active {
  position: relative;
}
body.nav-active .header {
  background: #fff;
}
body.nav-active .logo a {
  background-image: url(../images/logo.svg);
}
body.nav-active .navi__button--close {
  opacity: 1;
  top: -70px;
}
body.nav-active .header .btnLogin::before,
body.nav-active .header .btnLogout::before,
body.nav-active .header .btnProfile::before {
  color: #7f7f7f;
}
body.nav-active:after {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: #000;
  opacity: 0.4;
}
body.nav-active .nav .holderNavLevel1--horizontal .nav-link {
  color: #7f7f7f;
}
body.nav-active .nav .nav-opener span {
  opacity: 0;
  background: #000;
}
body.nav-active .nav .nav-opener:hover {
  opacity: 0.9;
}
body.nav-active .nav .nav-opener:hover:before {
  opacity: 1;
}
body.nav-active .nav .nav-opener:hover:after {
  width: auto;
}
body.nav-active .nav .nav-opener:after,
body.nav-active .nav .nav-opener:before {
  transform: rotate(45deg);
  top: 8px;
  left: 0;
  right: 0;
  background: #000;
}
body.nav-active .nav .nav-opener:after {
  transform: rotate(-45deg);
}
body.nav-active .nav .nav-drop {
  z-index: -1;
  transform: translate(0, 0);
  min-height: calc(100vh - 50px);
  overflow: auto;
  height: auto;
  background: #fff;
}
@media (min-width: 768px) {
  body.nav-active .nav .nav-drop {
    min-height: calc(100vh - 70px);
  }
}
@media (min-width: 1400px) {
  body.nav-active .nav .nav-drop {
    min-height: calc(100vh - 100px);
  }
}
body.nav-active .nav-hover .nav .nav-drop {
  transform: translate(0, 0);
}

.nav .nav-opener {
  width: 32px;
  height: 24px;
  position: relative;
  z-index: 1000;
  display: inline-block;
  vertical-align: top;
  display: inline-block;
  padding: 0;
  margin-top: 3px;
  margin-right: 30px;
  font-weight: normal;
  font-size: 18px;
  line-height: 1;
  color: #000;
  text-transform: uppercase;
}
.nav .nav-opener em {
  position: absolute;
  bottom: 0px;
  left: 100%;
  font-style: normal;
  padding: 0 0 0 9px;
}
.nav .nav-opener:before,
.nav .nav-opener:after,
.nav .nav-opener span {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  margin-top: 0;
  transition: all 0.2s linear;
}
.nav .nav-opener:before,
.nav .nav-opener:after {
  content: "";
  top: 10px;
}
.nav .nav-opener:after {
  top: 20px;
}
@media (min-width: 1400px) {
  .nav .nav-opener:hover span {
    top: 10px;
    left: -4px;
    right: auto;
    bottom: auto;
    width: 24px;
    transform: rotate(45deg);
  }
  .nav .nav-opener:hover:after {
    top: 10px;
    left: 11px;
    right: auto;
    bottom: auto;
    width: 24px;
    transform: rotate(-45deg);
  }
  .nav .nav-opener:hover:before {
    opacity: 0;
  }
}

.nav .close-btn span {
  opacity: 0;
}

.nav .close-btn:after,
.nav .close-btn:before {
  transform: rotate(45deg);
  top: 15px;
  left: 0;
  right: 0;
}

.nav .close-btn:after {
  transform: rotate(-45deg);
}

.nav .nav-drop {
  overflow: hidden;
  position: absolute;
  top: 100%;
  transform: translate(0, -100%);
  left: 0;
  right: 0;
  z-index: -1;
  transition: opacity 0.25s linear;
  margin: 0;
  background: #000;
  transition: bottom 0.3s ease-in-out, top 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.fairButton-holder {
  margin-top: -4px;
  margin-right: 20px;
}
@media (min-width: 768px) {
  .fairButton-holder {
    margin-top: -5px;
  }
}

.nav-wrapper {
  flex-grow: 1;
  display: flex;
}
.nav-wrapper .nav-opener {
  display: none;
}
.nav-wrapper .navi__button--close {
  position: absolute;
  top: 100px;
  left: 0;
  line-height: 0;
  font-size: 0;
  color: transparent;
  width: 30px;
  height: 30px;
  opacity: 0;
  transition: all 0.5s;
}
.nav-wrapper .navi__button--close:hover::before,
.nav-wrapper .navi__button--close:hover::after {
  background: #7f7f7f;
}
.nav-wrapper .navi__button--close::before,
.nav-wrapper .navi__button--close::after {
  content: "";
  background: #000;
  position: absolute;
  height: 4px;
  top: 8px;
  left: 0;
  right: 0;
  transition: all 0.3s;
}
.nav-wrapper .navi__button--close::before {
  transform: rotate(45deg);
}
.nav-wrapper .navi__button--close::after {
  transform: rotate(-45deg);
}
.nav-wrapper .navi__button--back {
  display: none;
}
.nav-wrapper .mainnavigation {
  padding-top: 100px;
}
.nav-wrapper .mainnavigation .mainNavContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 100%;
  position: relative;
}
.nav-wrapper .mainnavigation .holderNavLevel {
  border: none;
  position: relative;
  top: 0;
  width: 33.333333%;
  background-color: #fff;
}
.nav-wrapper .mainnavigation .holderNavLevel .navbar-nav {
  padding-left: 3vw;
  padding-right: 3vw;
}
.nav-wrapper .mainnavigation .holderNavLevel:last-child .navbar-nav {
  padding-right: 0;
}
.nav-wrapper .mainnavigation .holderNavLevel.holderNavLevel1 {
  display: none;
}
.nav-wrapper .mainnavigation .holderNavLevel.holderNavLevel2 .navbar-nav {
  padding-left: 0;
}
.nav-wrapper .mainnavigation .navbar-nav {
  position: absolute;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 7vh;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden auto;
  border-right: 1px solid #e6e6e6;
  background-color: #fff;
}
.nav-wrapper .mainnavigation .navbar-nav.sub-navi {
  display: none;
}
.nav-wrapper .mainnavigation .navbar-nav.sub-navi.hoverActive {
  display: block;
}
.nav-wrapper .mainnavigation .nav-item {
  opacity: 0;
  margin-bottom: 1.5em;
  position: relative;
}
.nav-wrapper .mainnavigation .nav-item .nav-link {
  font-size: 30px;
  color: #7f7f7f;
  line-height: 1;
  position: relative;
  display: block;
  padding-right: 1em;
}
@media (min-width: 768px) {
  .nav-wrapper .mainnavigation .nav-item .nav-link {
    font-size: 40px;
  }
}
.nav-wrapper .mainnavigation .nav-item .nav-link:hover {
  color: #000;
}
.nav-wrapper .mainnavigation .nav-item .nav-link.naviSubLink::after {
  position: absolute;
  right: 0;
  content: "»";
}
.nav-wrapper .mainnavigation .nav-item.active .nav-link {
  color: #000;
  font-weight: 700;
}
.nav-wrapper .holderNavLevel1--horizontal {
  display: flex;
}
.nav-wrapper .holderNavLevel1--horizontal .navLevel1 {
  display: flex;
  flex-direction: row;
}
.nav-wrapper .holderNavLevel1--horizontal .navLevel1 .nav-item {
  margin-right: 30px;
}
.nav-wrapper .holderNavLevel1--horizontal .navLevel1 .nav-item.active .nav-link::after {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: #fff;
}
.nav-wrapper .holderNavLevel1--horizontal .navLevel1 .nav-item.important {
  border: 1px solid #fff;
  padding: 4px 10px;
  transition: all 0.3s;
  margin-top: -4px;
}
.nav-wrapper .holderNavLevel1--horizontal .navLevel1 .nav-item.important:hover {
  border-color: #7f7f7f;
}
.nav-wrapper .holderNavLevel1--horizontal .navLevel1 .nav-link {
  position: relative;
  color: #fff;
  letter-spacing: 0.02em;
}
.nav-wrapper .holderNavLevel1--horizontal .navLevel1 .nav-link:hover {
  color: #7f7f7f;
}
.nav-wrapper .holderNavLevel1--horizontal .nav-holder {
  white-space: nowrap;
}

body.mobile .fairButton-holder {
  display: none;
}

body.mobile .navi__button--close {
  display: none;
}

body.mobile .navi__button--back {
  display: block;
  position: absolute;
  top: -110px;
  left: 0;
  font-size: 70px;
  font-weight: 400;
}
body.mobile .navi__button--back:hover {
  color: #7f7f7f;
}

body.mobile .holderNavLevel1--horizontal {
  display: none;
}

body.mobile .nav-opener {
  display: block;
}

body.mobile .mainnavigation .holderNavLevel {
  width: 100%;
  position: absolute;
  left: -100vw;
  transition: all 0.7s;
}
body.mobile .mainnavigation .holderNavLevel.active {
  left: 0;
}
body.mobile .mainnavigation .holderNavLevel.holderNavLevel1 {
  display: flex;
  z-index: 10;
}
body.mobile .mainnavigation .holderNavLevel.holderNavLevel2 {
  z-index: 9;
}
body.mobile .mainnavigation .holderNavLevel.holderNavLevel3 {
  z-index: 8;
}
body.mobile .mainnavigation .holderNavLevel .navbar-nav {
  padding: 0;
  border: none;
  padding-left: 10px;
}

body.mobile .mainnavigation .nav-item {
  opacity: 1;
}
body.mobile .mainnavigation .nav-item.important {
  margin-top: 3em;
}
body.mobile .mainnavigation .nav-item.important span {
  border: 1px solid #7f7f7f;
  padding: 4px 10px 8px;
  transition: all 0.3s;
  margin-left: -10px;
}
body.mobile .mainnavigation .nav-item.important span:hover {
  border-color: #7f7f7f;
}

.searchForm__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.6s;
}

.searchForm {
  top: -4em;
  position: relative;
}
.searchForm__content {
  display: flex;
  position: relative;
}
.searchForm__input {
  outline: none;
  border: none;
  border-bottom: 2px solid #7f7f7f;
  width: 70vw;
  max-width: 500px;
  padding: 0.6em 1.5em 0.6em 1em;
  background: transparent;
  font-weight: 400;
}
.searchForm .icon-search.searchForm__submit {
  background: transparent none;
  border: 0px none;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
  transition: all 0.4s;
  transform: scale(1);
  transform-origin: center center;
  color: #000;
  margin-left: 0.3em;
}
@media (min-width: 480px) {
  .searchForm .icon-search.searchForm__submit {
    font-size: 42px;
  }
}
.searchForm .icon-search.searchForm__submit:hover {
  transform: scale(1.3);
}

.searchFormActive .searchForm__wrapper {
  opacity: 1;
  pointer-events: all;
}

.header .icon-search {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  transition: all 0.3s;
  position: relative;
  margin-top: 2px;
  margin-left: 10px;
}
.header .icon-search:hover {
  color: #7f7f7f;
}

.nav-active .header .icon-search {
  color: #7f7f7f;
}

.language-menu {
  position: relative;
  display: flex;
  margin-right: 40px;
}
.language-menu__list {
  position: relative;
  width: 44px;
  height: 30px;
}
.language-menu__listItem {
  position: relative;
  background-color: #000;
  height: 0;
  width: 100%;
  overflow: hidden;
  padding: 0 10px;
  transition: height 0.3s, opacity 0.3s;
  opacity: 0;
}
.language-menu__listItem a {
  color: #fff;
}
.language-menu__listItem.active {
  height: auto;
  opacity: 1;
}
.language-menu__listItem.active a {
  font-weight: 400;
}
.language-menu__toggle {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
}
.language-menu__toggle::after {
  position: absolute;
  font-family: "icomoon" !important;
  content: "\e90d";
  transform: rotate(90deg);
  color: #fff;
  transition: all 0.5s;
}
.language-menu__toggle:hover::after {
  color: #7f7f7f;
}
.language-menu.open .language-menu__listItem {
  height: 30px;
  opacity: 1;
}
.language-menu.open .language-menu__toggle::after {
  transform: rotateZ(90deg) rotateY(180deg);
  transform-origin: center center;
}

.nav-active .language-menu__listItem {
  background-color: #fff;
}
.nav-active .language-menu__listItem a {
  color: #000;
}

.nav-active .language-menu__toggle::after {
  color: #000;
}

.naviTeasers {
  display: none;
  pointer-events: none;
}

.naviTeaser {
  position: relative;
  width: 45%;
  pointer-events: all;
}
.naviTeaser__image {
  margin-bottom: 30px;
}

.nav-active:not(.mobile) .naviTeasers.active {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-left: 3vw;
  padding-right: 3vw;
  width: 200%;
  top: 0;
  left: 32vw;
}
.nav-active:not(.mobile) .naviTeasers.active .naviTeaser {
  top: 0;
  display: block;
}

.hideTeaser .naviTeaser:nth-child(2) {
  display: none !important;
}

.holderNavLevel .hoverActive > .nav-item {
  opacity: 0;
  left: -200px;
  -webkit-animation: 0.5s slideIn 0.2s forwards ease-out;
  animation: 0.5s slideIn 0.2s forwards ease-out;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(2) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(4) {
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(6) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(7) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(8) {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(9) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(10) {
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(11) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(12) {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(13) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(14) {
  -webkit-animation-delay: 0.85s;
  animation-delay: 0.85s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(15) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(16) {
  -webkit-animation-delay: 0.95s;
  animation-delay: 0.95s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(17) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(18) {
  -webkit-animation-delay: 1.05s;
  animation-delay: 1.05s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(19) {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(20) {
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(21) {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(22) {
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(23) {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(24) {
  -webkit-animation-delay: 1.35s;
  animation-delay: 1.35s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(25) {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(26) {
  -webkit-animation-delay: 1.45s;
  animation-delay: 1.45s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(27) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(28) {
  -webkit-animation-delay: 1.55s;
  animation-delay: 1.55s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(29) {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.holderNavLevel .hoverActive > .nav-item:nth-of-type(30) {
  -webkit-animation-delay: 1.65s;
  animation-delay: 1.65s;
}

@-webkit-keyframes slideIn {
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes slideIn {
  100% {
    opacity: 1;
    left: 0;
  }
}

/* footer styles */
.footer {
  font-size: 18px;
}
.footer .sub-row {
  margin: 0 -25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .footer .sub-row.row-bottom {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .footer .logo-box a.btn {
    max-width: none;
    width: 200px;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 7px 7px 7px;
  }
  .footer .logo-box a.btn i {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    margin: -2px 20px 0 0;
  }
}
.footer .logo-footer {
  width: 150px;
  margin-bottom: 40px;
  margin-left: 6px;
}
.footer .logo-footer a {
  display: block;
}
.footer .logo-footer img {
  display: block;
  vertical-align: top;
  width: 100%;
}
@media (max-width: 768px) {
  .footer .contactMenu {
    display: none;
  }
}
.footer .logo-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}
@media (min-width: 768px) {
  .footer .logo-box {
    margin: 0;
    display: block;
    padding: 0 0 0 5px;
    width: 31%;
  }
}
@media (min-width: 992px) {
  .footer .logo-box {
    width: 24%;
  }
}
.footer .right-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .footer .right-box {
    width: 69%;
  }
}
@media (min-width: 992px) {
  .footer .right-box {
    width: 76%;
    padding: 0 0 0 7px;
  }
}
.footer .sitemap {
  width: 50%;
}
@media (min-width: 768px) {
  .footer .sitemap {
    width: auto;
    width: 65%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: -5px 0 0 0;
  }
}
@media (min-width: 992px) {
  .footer .sitemap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 73%;
  }
}
.footer .sitemap .column {
  margin: 0 0 2px;
}
@media (min-width: 768px) {
  .footer .sitemap .column {
    margin: 0 0 8px;
    width: 50%;
    padding: 0 0 0 20px;
  }
}
@media (min-width: 992px) {
  .footer .sitemap .column {
    width: 25%;
  }
  .footer .sitemap .column:first-child {
    padding: 0;
  }
}
.footer .sitemap .opener {
  text-transform: uppercase;
  color: #808080;
  font-weight: normal;
  font-size: 12px;
}
@media (min-width: 768px) {
  .footer .sitemap .opener {
    font-size: 16px;
  }
}
.footer .sitemap .opener:hover {
  color: #000;
}
@media (min-width: 992px) {
  .footer .slide li {
    margin: 0 0 -2px;
  }
}
.footer .sitemap a {
  text-transform: uppercase;
  color: #808080;
  font-weight: normal;
  font-size: 12px;
  display: inline-block;
  width: 100%;
  padding: 7px 0 5px;
  line-height: 100%;
}
@media (min-width: 768px) {
  .footer .sitemap a {
    font-size: 16px;
  }
}
.footer .sitemap a:hover {
  color: #000;
}
@media (min-width: 992px) {
  .footer .sitemap {
    position: static !important;
    left: 0 !important;
    top: 0 !important;
    height: auto !important;
  }
}
.footer .social-box {
  width: 100% !important;
}
@media (min-width: 992px) {
  .footer .social-box {
    width: 25% !important;
  }
}
.footer .social-list {
  display: flex;
  flex-flow: row wrap;
}
.footer .social-list-wrapper {
  padding-bottom: 0 !important;
}
.footer .social-list li {
  margin: 0 40px 35px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 40px;
}
.footer .social-list li:last-child {
  margin-right: 0;
}
@media (min-width: 480px) {
  .footer .social-list li {
    text-align: left;
  }
}
.footer .social-list a {
  color: #808080;
  font-size: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (min-width: 768px) {
  .footer .social-list a {
    text-align: left;
    font-size: 33px;
    justify-content: flex-start;
  }
}
.footer .social-list a:hover {
  color: #000;
}
.footer .col4 {
  width: 100%;
  padding: 0 25px;
  position: relative;
}
@media (min-width: 768px) {
  .footer .col4 {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .footer .col4 {
    width: 25%;
  }
}
.footer .meta-box {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.footer .meta-box__list {
  flex-grow: 1;
}
.footer .menu-box {
  flex-grow: 1;
}
.footer .menu-box__list {
  -moz-column-count: 1;
  column-count: 1;
}
@media (min-width: 992px) {
  .footer .menu-box__list {
    -moz-column-count: 2;
    column-count: 2;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}
.footer .menu-box__listItem {
  font-weight: 400;
  color: #000;
  margin-bottom: 0.2em;
}
@media (min-width: 992px) {
  .footer .menu-box__listItem {
    margin-bottom: 1em;
  }
}
.footer .language-box__list {
  display: flex;
}
.footer .language-box__listItem {
  margin-right: 20px;
}
.footer .language-box__listItem.active a {
  font-weight: 400;
}
.footer .meta-box {
  padding: 0 25px;
}
.footer .meta-box__list {
  display: flex;
  flex-wrap: wrap;
}
.footer .meta-box__listItem {
  margin-right: 20px;
}
.footer .footer-box p:last-child {
  margin-bottom: 0;
}
.footer .footer-box > * {
  position: relative;
  padding: 30px 5px;
}
.footer .footer-box > *::before {
  content: "";
  display: block;
  background-color: #cfcfcf;
  height: 1px;
  width: 100%;
  max-width: 230px;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 768px) {
  .footer .footer-box > *::before {
    display: none;
  }
}

.shortcutSidebar {
  position: fixed;
  right: -40px;
  top: 130px;
  z-index: 100;
  display: none;
  transition: all 0.3s;
}
@media (min-width: 1400px) {
  .shortcutSidebar {
    display: block;
  }
}
.shortcutSidebar.active {
  right: 0;
}
.shortcutSidebar__buttons {
  margin: 0;
  padding: 0;
  list-style: none;
}
.shortcutSidebar__button {
  width: 40px;
  height: 40px;
  margin-bottom: 1px;
  background-color: #000;
  font-size: 24px;
  color: #fff;
  position: relative;
}
.shortcutSidebar__link {
  display: block;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7f7f7f;
}
.shortcutSidebar__link:hover {
  color: #fff;
}
.shortcutSidebar__detail {
  position: absolute;
  top: 0px;
  right: -100px;
  float: right;
  z-index: -1;
  height: 40px;
  margin-bottom: 1px;
  background-color: #000;
  font-size: 24px;
  width: 0px;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px 15px;
  transition: all 0.2s ease-out;
}
.shortcutSidebar__detail.detailPhone {
  padding-top: 0px;
  padding-bottom: 0px;
  height: auto;
}
.shortcutSidebar__detail.detailPhone span {
  font-size: 15px;
  line-height: 20px;
}
.shortcutSidebar__detail a {
  color: #fff;
}
.shortcutSidebar__detail .social-list {
  display: inline;
  white-space: nowrap;
  padding: 0px;
}
.shortcutSidebar__detail .social-list li {
  display: inline-block;
  padding: 0 9px;
}
.shortcutSidebar__detail.opened {
  right: 40px;
  width: 275px;
}
@media (min-width: 992px) {
  .shortcutSidebar {
    right: -80px;
    top: 300px;
  }
  .shortcutSidebar__button {
    width: 80px;
    height: 80px;
    font-size: 48px;
  }
  .shortcutSidebar__detail {
    right: 0;
    height: 80px;
    font-size: 32px;
    padding: 18px 20px;
  }
  .shortcutSidebar__detail.detailPhone {
    font-size: 27px;
    height: 80px;
  }
  .shortcutSidebar__detail.detailPhone span {
    font-size: 16px;
    line-height: 20px;
  }
  .shortcutSidebar__detail.opened {
    right: 80px;
    width: 300px;
  }
}

body.nav-active .shortcutSidebar.active {
  right: -40px;
}
body.nav-active .shortcutSidebar.active .shortcutSidebar__detail {
  width: 0px !important;
  right: -200px;
}
@media (min-width: 992px) {
  body.nav-active .shortcutSidebar.active {
    right: -80px;
  }
}

/* main container styles */
body {
  font-weight: 300;
  color: #000;
}

#wrapper {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.xdebug-var-dump,
.extbase-debugger {
  z-index: 1000;
  position: relative;
  font-size: 12px;
}

a {
  transition: color 0.3s ease-in-out;
  color: #000;
  font-weight: 300;
  display: inline;
  vertical-align: top;
  text-decoration: none;
  outline: none;
}
a:hover,
a:focus {
  text-decoration: none;
}
a:hover {
  color: #7f7f7f;
}

a.linkUnderlined {
  border-bottom: solid 1px;
}

.uppercase {
  text-transform: uppercase;
}

a.noUnderline {
  border-bottom: 0 !important;
}

.btn {
  font-weight: normal;
  font-size: 18px;
  text-transform: uppercase;
  padding: 5px 30px;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  white-space: normal;
}
.btn:hover {
  color: #fff;
  background: #000;
  border-color: #000;
}
.btn.btn-primary:hover {
  background: #fff;
  color: #000;
  border-color: #000;
}
.btn.btn-primary.disabled {
  opacity: 0.5;
}
.btn.btn-primary.disabled:hover {
  color: #fff;
  background: #000;
}
.btn.btn-transparent {
  background-color: transparent;
  color: #fff;
  border-color: #fff !important;
}
.btn.btn-transparent:hover {
  background: #fff;
  color: #000;
  border-color: #000 !important;
}
.btn.active {
  box-shadow: none;
}
.btn.btn-with-ico {
  max-width: 105px;
  font-size: 9px;
  color: #fff;
  background: #000;
  padding: 5px 7px 2px 7px;
}
@media (min-width: 768px) {
  .btn.btn-with-ico {
    padding: 11px 20px 4px 20px;
  }
}
.btn.btn-with-ico:hover {
  color: #000;
  background: #fff;
}
.btn.btn-with-ico.active {
  color: #000;
  background: #fff;
}
.btn.btn-with-ico.active:hover {
  color: #fff;
  background: #000;
}
.btn.btn-with-ico span {
  display: inline-block;
  vertical-align: middle;
}
.btn.btn-with-ico i {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  margin: 0 5px 0 0;
}
@media (min-width: 768px) {
  .btn.btn-with-ico i {
    font-size: 22px;
    margin: -5px 10px 0 0;
  }
}
@media (min-width: 992px) {
  .btn.btn-with-ico i {
    font-size: 25px;
  }
}
@media (min-width: 768px) {
  .btn.btn-with-ico {
    font-size: 15px;
    max-width: 178px;
  }
}
@media (min-width: 992px) {
  .btn.btn-with-ico {
    font-size: 18px;
    max-width: 215px;
  }
}

.btn-download-row {
  display: inline-block;
  vertical-align: top;
}

.btn-download {
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  line-height: 1;
  color: #000;
  text-transform: none;
  display: inline-block;
  vertical-align: top;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: auto;
  margin-bottom: 15px;
  border-bottom: 0 !important;
}
@media (min-width: 768px) {
  .btn-download {
    font-size: 18px;
  }
}
.btn-download:hover {
  color: #000;
}
.btn-download:hover em {
  color: #000;
}
.btn-download:hover i {
  color: #000;
}
.btn-download b {
  font-weight: normal;
  display: block;
  position: relative;
}
.btn-download em {
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
  color: #666;
  margin: 1px 0 -4px 0;
  transition: color 0.3s ease-in-out;
}
.btn-download i {
  font-size: 18px;
  margin: 0 10px 0 0;
  color: #000;
  transition: color 0.3s ease-in-out;
}

.submit {
  padding: 0;
  background: none;
  outline: none;
  border: 0;
  display: inline-block;
  vertical-align: top;
  color: #676767;
  font-size: 16px;
  width: 15px;
  height: 15px;
  margin: 4px 0 4px 4px;
}
@media (min-width: 768px) {
  .submit {
    font-size: 20px;
    width: 30px;
    height: 22px;
    margin: 10px 0 10px 10px;
  }
}
@media (min-width: 1400px) {
  .submit {
    font-size: 24px;
    width: 24px;
    height: 24px;
    margin: 10px 0 10px 10px;
  }
}
.submit:before {
  transition: content 0.3s ease-in-out;
}
.submit:hover {
  background: none;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2 {
  line-height: 1.2;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0.3em;
}
@media (min-width: 768px) {
  h2 {
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  h2 {
    font-size: 60px;
  }
}

h2.h1Style,
h1 {
  font-weight: 700;
  line-height: 1;
  font-size: 36px;
  margin-bottom: 0.3em;
}
@media (min-width: 768px) {
  h2.h1Style,
  h1 {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  h2.h1Style,
  h1 {
    font-size: 48px;
  }
}
@media (min-width: 1400px) {
  h2.h1Style,
  h1 {
    font-size: 60px;
  }
}

h3,
.subheader,
.h3 {
  line-height: 1.2;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0.3em;
}
@media (min-width: 768px) {
  h3,
  .subheader,
  .h3 {
    font-size: 32px;
  }
}
@media (min-width: 992px) {
  h3,
  .subheader,
  .h3 {
    font-size: 40px;
  }
}
@media (min-width: 1400px) {
  h3,
  .subheader,
  .h3 {
    font-size: 48px;
  }
}

h1 + p,
h2 + p,
h3 + p,
.subheader + p {
  margin-top: 20px;
}
@media (min-width: 768px) {
  h1 + p,
  h2 + p,
  h3 + p,
  .subheader + p {
    margin-top: 40px;
  }
}

p {
  font-size: 20px;
  line-height: 1.4;
  margin: 0 0 20px;
}
@media (min-width: 768px) {
  p {
    line-height: 1.5;
  }
}
@media (min-width: 992px) {
  p {
    line-height: 1.4;
    margin: 0 0 60px;
  }
}

.paddingSpacer {
  padding: 30px 0 0 0;
  width: 100%;
  clear: both;
}
@media (min-width: 768px) {
  .paddingSpacer {
    padding: 69px 0 0 0;
  }
}

.form-control {
  font-weight: 300;
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  font-size: 18px;
  line-height: 1.444;
  height: 30px !important;
  padding: 6px 4px 2px !important;
  color: #666;
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
  border-color: #000;
}

@media (min-width: 768px) {
  .row {
    margin-left: -20px;
    margin-right: -20px;
  }
}

/* main informative part styles */
#main {
  padding-top: 50px;
}
@media (min-width: 768px) {
  #main {
    padding-top: 70px;
  }
}
@media (min-width: 1400px) {
  #main {
    padding-top: 100px;
  }
}

/* box-frame section styles */
.box-frame {
  margin: 0 -5px;
}
@media (min-width: 768px) {
  .box-frame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 -5px;
  }
}
.box-frame .column {
  margin: 0 -5px;
}
@media (max-width: 767px) {
  .box-frame .column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
}
@media (min-width: 768px) {
  .box-frame .column {
    width: 33.333%;
    padding: 0 5px;
    margin: 0;
  }
}
.box-frame .column .box-holder {
  width: 50%;
}
@media (min-width: 768px) {
  .box-frame .column .box-holder {
    width: 100%;
  }
}
.box-frame .column.column-lg .box-holder {
  width: 100%;
}
@media (min-width: 768px) {
  .box-frame .column.column-lg {
    width: 66.666%;
  }
}
.box-frame .box-holder {
  width: 100%;
  position: relative;
  padding: 0 5px 5px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .box-frame .box-holder.full-width {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .box-frame .box-holder {
    width: 100%;
    padding: 0 0 10px;
  }
}
.box-frame .box-holder:hover .image-holder img {
  transform: scale3d(1.1, 1.1, 1.1);
}
.box-frame .box-holder a {
  display: block;
  position: relative;
}
.box-frame .image-holder {
  overflow: hidden;
  position: relative;
}
.box-frame .image-holder::after {
  content: "";
  position: absolute;
  background: linear-gradient(transparent 0%, transparent 50%, #000 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box-frame .image-holder img {
  vertical-align: top;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  z-index: -1;
  position: relative;
  display: block;
}
.box-frame .image-holder.fadeTeaser:before {
  content: "";
  display: block;
  margin-top: 86%;
}
.box-frame .image-holder.fadeTeaser img {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  left: 0;
}
.box-frame .sub-title {
  position: absolute;
  top: 8px;
  left: 15px;
  text-transform: uppercase;
  font-size: 10px;
  color: #fff;
  font-weight: 300;
}
.box-frame .sub-title.reverse {
  color: #000;
}
@media (min-width: 768px) {
  .box-frame .sub-title {
    font-size: 16px;
    top: 12px;
  }
}
.box-frame h2 {
  font-size: 16px;
  line-height: 0.8666;
  text-shadow: 0px 0px 30px #000;
  font-weight: normal;
  position: absolute;
  bottom: 23px;
  left: 15px;
  right: 15px;
  margin: 0;
  line-height: 0.8;
}
@media (min-width: 480px) {
  .box-frame h2 {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .box-frame h2 {
    font-size: 25px;
    bottom: 15px;
  }
}
@media (min-width: 992px) {
  .box-frame h2 {
    font-size: 30px;
  }
}
.box-frame h2.dark-color span {
  color: #fff;
}
.box-frame h2.dark-color i {
  color: #fff;
}
.box-frame h2.dark-color i:after {
  color: #fff;
}
.box-frame h2.dark-color em {
  color: #fff;
}
.box-frame h2.dark-color em:after {
  color: #fff;
}
.box-frame h2.medium-size {
  text-align: left;
}
@media (min-width: 768px) {
  .box-frame h2.medium-size {
    text-align: left;
  }
  .box-frame h2.medium-size span {
    padding: 7px 0 0 10px;
  }
}
@media (min-width: 992px) {
  .box-frame h2.medium-size {
    font-size: 28px;
  }
}
.box-frame h2 span {
  display: inline-block;
  vertical-align: top;
  color: #000;
  display: inline;
  background: #fff;
  padding: 4px 0 0 0px;
  padding: 0px;
  transition: background 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .box-frame h2 span {
    padding: 10px 0 0 10px;
  }
}
.box-frame h2 i {
  font-style: normal;
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 5px 2px 0px 1px;
}
@media (min-width: 768px) {
  .box-frame h2 i {
    padding: 10px 3px 0px 3px;
  }
}
.box-frame h2 em {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  text-transform: none;
  display: inline-block;
  padding: 5px 2px 3px 2px;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .box-frame h2 em {
    font-size: 18px;
    padding: 10px 3px 5px 3px;
  }
}
@media (min-width: 992px) {
  .box-frame h2 em {
    font-size: 24px;
  }
}
.box-frame h1 {
  position: absolute;
  top: 33px;
  left: 4px;
  color: #fff;
  padding: 0 10px;
  margin: 0;
  font-size: 25px;
  line-height: 0.7083;
  text-transform: uppercase;
  font-weight: normal;
}
@media (min-width: 480px) {
  .box-frame h1 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .box-frame h1 {
    top: 61px;
    left: 4px;
    font-size: 40px;
    line-height: 0.75;
  }
}
@media (min-width: 992px) {
  .box-frame h1 {
    font-size: 60px;
    line-height: 0.75;
    left: 4px;
    top: 80px;
  }
}
.box-frame h1 span {
  color: #fff;
  display: inline;
}
.box-frame h1 em {
  font-style: normal;
  display: inline-block;
  vertical-align: top;
  padding: 8px 6px 6px;
  transition: background 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .box-frame h1 em {
    padding: 15px 15px 0;
  }
}
.box-frame .label {
  max-width: 100px;
  position: absolute;
  bottom: 20px;
  right: 15px;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}
.box-frame .label:hover {
  opacity: 0.8;
}
@media (min-width: 768px) {
  .box-frame .label {
    max-width: 130px;
  }
}
@media (min-width: 992px) {
  .box-frame .label {
    max-width: 266px;
    right: 23px;
    bottom: 15px;
  }
}
.box-frame .label img {
  vertical-align: top;
  width: 100%;
}
.box-frame.full-width-mobile {
  margin: 0 -10px;
}
@media (min-width: 640px) {
  .box-frame.full-width-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 0;
  }
  .box-frame.full-width-mobile .box-holder {
    width: 50%;
    padding: 0 5px 10px;
  }
}
@media (min-width: 992px) {
  .box-frame.full-width-mobile .box-holder {
    width: 33.333%;
    padding: 0 5px 10px;
  }
}
@media (max-width: 767px) {
  .box-frame.full-width-mobile .column {
    display: block;
  }
  .box-frame.full-width-mobile .column .box-holder {
    width: 100%;
  }
  .box-frame.full-width-mobile h2 {
    bottom: 10px;
    left: 9px;
    font-size: 20px;
  }
}

.column-box {
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .column-box {
    margin: 0 -5px;
  }
}

/* twocolumns section styles */
.twocolumns {
  margin: 0 5px;
}
@media (min-width: 768px) {
  .twocolumns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .twocolumns .image-holder {
    width: 45%;
    position: relative;
  }
}
@media (min-width: 992px) {
  .twocolumns .image-holder {
    width: 47%;
  }
}
.twocolumns .image-holder img {
  vertical-align: top;
  width: 100%;
}
.twocolumns .text-box {
  padding: 9px 0 0 0;
}
@media (min-width: 768px) {
  .twocolumns .text-box {
    width: 55%;
    padding: 37px 0 0 0;
  }
}
@media (min-width: 992px) {
  .twocolumns .text-box {
    width: 53%;
    padding: 56px 0 0 0;
  }
}
.twocolumns span {
  font-weight: 300;
  display: block;
  font-size: 10px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .twocolumns span {
    padding: 0 10px;
    position: absolute;
    top: 5px;
    left: 0;
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .twocolumns span {
    font-size: 16px;
    top: 13px;
    left: 7px;
  }
}
.twocolumns h2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.1111;
  margin: 0 0 5px;
}
@media (min-width: 768px) {
  .twocolumns h2 {
    font-size: 24px;
    line-height: 1;
  }
}
@media (min-width: 992px) {
  .twocolumns h2 {
    font-size: 32px;
    line-height: 1.09375;
    margin: 0 0 12px;
  }
}
@media (min-width: 992px) {
  .twocolumns p {
    margin: 0 0 33px;
  }
}
.twocolumns .row-btn {
  text-align: center;
}
@media (min-width: 768px) {
  .twocolumns .row-btn {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .twocolumns .row-btn .btn {
    margin: 0 5px;
  }
}

/* title section styles */
.title-section {
  margin: -10px -20px 24px;
  position: relative;
}
@media (min-width: 768px) {
  .title-section {
    margin: -20px 0px 37px;
  }
}
@media (min-width: 992px) {
  .title-section {
    margin: -30px 0px 50px;
  }
}
.title-section .image-holder img {
  vertical-align: top;
  width: auto;
  height: 400px;
  max-width: none;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .title-section .image-holder img {
    width: 100%;
    height: auto;
  }
}
.title-section .title-text {
  position: absolute;
  bottom: 10px;
  left: 13px;
  right: 10px;
}
@media (min-width: 768px) {
  .title-section .title-text {
    bottom: 15px;
    left: 16px;
  }
}
.title-section h2 {
  font-weight: 400;
  color: #fff;
  margin: 0;
  font-size: 15px;
}
@media (min-width: 768px) {
  .title-section h2 {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .title-section h2 {
    font-size: 30px;
  }
}
.title-section h1 {
  font-weight: 700;
  color: #fff;
  margin: 0;
}
.title-section h1 span {
  padding: 6px 2px 0 6px;
  margin: 0;
}
@media (min-width: 768px) {
  .title-section h1 span {
    padding: 10px 7px 0 6px;
    margin: -6px 0 0 0;
  }
}
.title-section span {
  display: inline-block;
  vertical-align: top;
  padding: 2px 7px 0 6px;
  text-shadow: 0px 0px 50px #000;
}
@media (min-width: 768px) {
  .title-section span {
    padding: 0 7px 0 6px;
  }
}

/* sub nav styles */
.sub-nav-list {
  font-size: 0;
  line-height: 0;
  padding: 0 5px 10px;
}
@media (min-width: 768px) {
  .sub-nav-list {
    padding: 20px 28px 30px;
  }
}
@media (min-width: 992px) {
  .sub-nav-list {
    padding: 20px 50px 41px;
  }
}
.sub-nav-list li {
  display: none;
  font-size: 12px;
  line-height: 1;
  padding: 0 20px 16px 0;
}
@media (min-width: 992px) {
  .sub-nav-list li {
    font-size: 18px;
    padding: 0 35px 20px 0;
    display: inline-block;
  }
}
@media (min-width: 1400px) {
  .sub-nav-list li {
    padding: 0 50px 20px 0;
  }
}
.sub-nav-list li:last-child {
  padding: 0 0 20px 0;
}
.sub-nav-list a {
  border-bottom: 0;
  font-size: 20px;
  color: #666;
  position: relative;
}
.sub-nav-list a:hover {
  color: #000;
}
.sub-nav-list a.active {
  color: #000;
}
.sub-nav-list a.active:after {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: #000;
}

.title-section + .sub-nav-list {
  padding-top: 0;
}

/* example lightbox styles */
.popup-holder.popup-active {
  overflow-y: scroll;
  max-height: 100vh;
  width: 100%;
}
.popup-holder.popup-active .lightbox-frame {
  opacity: 1;
  visibility: visible;
}
.popup-holder.popup-active .lightbox-frame .merkliste-section {
  padding: 0;
}
@media (max-width: 767px) {
  .popup-holder.popup-active {
    position: fixed;
  }
}

.social-plagin {
  position: absolute;
  left: 10px;
  top: 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .social-plagin {
    left: 20px;
  }
}
@media (min-width: 992px) {
  .social-plagin {
    left: 20px;
  }
}
@media (min-width: 1240px) {
  .social-plagin {
    left: 0;
  }
}
@media (min-width: 1400px) {
  .social-plagin {
    left: -40px;
  }
}
@media (min-width: 1550px) {
  .social-plagin {
    top: 151px;
    left: -150px;
  }
}
.social-plagin li {
  display: block;
  float: left;
}
@media (min-width: 1550px) {
  .social-plagin li {
    float: none;
    display: block;
  }
}
.social-plagin li:last-child a:after {
  display: none;
}
.social-plagin a {
  display: block;
  color: #7f7f7f;
  background: #e6e6e6;
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  font-size: 20px;
}
@media (min-width: 768px) {
  .social-plagin a {
    width: 50px;
    height: 51px;
    font-size: 25px;
  }
}
@media (min-width: 1400px) {
  .social-plagin a {
    width: 70px;
    height: 71px;
    font-size: 32px;
  }
}
.social-plagin a:hover {
  background: #000;
  color: #fff;
}
.social-plagin a:hover:after {
  background: #fff;
}
.social-plagin a:after {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  width: 1px;
  height: 20px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  background: #ccc;
  transition: background 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .social-plagin a:after {
    height: 30px;
  }
}
@media (min-width: 992px) {
  .social-plagin a:after {
    height: 40px;
  }
}
@media (min-width: 1550px) {
  .social-plagin a:after {
    width: 40px;
    height: 2px;
    bottom: -1px;
    top: auto;
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.detailView {
  padding: 0 5px 0;
}
@media (min-width: 768px) {
  .detailView {
    padding: 0 0px 0;
  }
}
.detailView .sub-title {
  display: none;
}
@media (min-width: 992px) {
  .detailView .twocolumns-lightbox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    margin: 0 0 70px;
  }
}
@media (min-width: 1024px) {
  .detailView .twocolumns-lightbox {
    margin: 0 0 101px;
  }
}
.detailView .twocolumns-lightbox .text-box {
  margin: 0 0 35px;
}
.detailView .twocolumns-lightbox .text-box.fullWidth {
  width: 100%;
}
@media (min-width: 992px) {
  .detailView .twocolumns-lightbox .text-box {
    width: calc(100% - 260px);
    padding: 0 60px 0 0;
    margin: 0;
  }
}
@media (min-width: 1024px) {
  .detailView .twocolumns-lightbox .text-box {
    width: calc(100% - 356px);
    padding: 0 100px 0 0;
  }
}
.detailView .twocolumns-lightbox .text-box p {
  margin: 0 0 17px;
}
@media (min-width: 768px) {
  .detailView .twocolumns-lightbox .text-box p {
    margin: 0 0 28px;
    font-size: 20px;
    line-height: 1.3;
  }
}
.detailView .twocolumns-lightbox .text-box p:last-of-type {
  margin: 0 0 20px 0;
}
@media (min-width: 768px) {
  .detailView .twocolumns-lightbox .text-box p:last-of-type {
    margin: 0 0 37px 0;
  }
}
.detailView .twocolumns-lightbox .text-box .title {
  display: block;
  font-size: 18px;
  color: #242424;
  margin: 0 0 15px;
  font-weight: 400;
  padding: 5px 0 0;
}
@media (min-width: 768px) {
  .detailView .twocolumns-lightbox .text-box .title {
    font-size: 25px;
    margin: 0 0 20px;
    padding: 10px 0 0;
  }
}
@media (min-width: 992px) {
  .detailView .twocolumns-lightbox .text-box .title {
    font-size: 30px;
    margin: 0 0 28px;
    padding: 15px 0 0;
  }
}
.detailView .twocolumns-lightbox .sidebar {
  margin: 0 0 25px;
}
@media (min-width: 992px) {
  .detailView .twocolumns-lightbox .sidebar {
    width: 260px;
    margin: 0;
  }
}
@media (min-width: 1024px) {
  .detailView .twocolumns-lightbox .sidebar {
    width: 356px;
  }
}
.detailView dl {
  margin: 0 0 0 4px;
}
@media (max-width: 767px) {
  .detailView dl {
    margin-left: 0;
  }
}
.detailView dl dt {
  margin: 0 0 1px;
  font-weight: normal;
  font-size: 16px;
  color: #000;
}
.detailView dl dd {
  margin: 0 0 13px 11px;
  font-weight: 300;
  font-size: 18px;
  color: #000;
}
@media (max-width: 767px) {
  .detailView dl dd {
    margin-left: 0;
  }
}
.detailView .title-address {
  display: block;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
  color: #000;
  margin: 0 0 5px;
}
@media (min-width: 768px) {
  .detailView .title-address {
    font-size: 20px;
  }
}
.detailView .project-list li {
  display: block;
  margin: 40px 0 0;
}
.detailView .project-list li:first-child {
  margin: 0;
}
.detailView .project-list .image-holder {
  overflow: hidden;
  margin: 0 0 10px;
  position: relative;
}
.detailView .project-list .image-holder img {
  vertical-align: top;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
  width: 100%;
}
.detailView .project-list .image-holder .title {
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 18px;
  color: #fff;
  background-color: #242424;
  padding: 11px 30px 4px 29px;
  margin: 0;
}
.detailView .project-list p {
  margin: 0;
  font-size: 14px;
  line-height: 1.33;
}
@media (min-width: 768px) {
  .detailView .project-list p {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .detailView .project-list p {
    font-size: 18px;
  }
}
.detailView .project-list strong {
  display: block;
  font-size: 18px;
}
@media (min-width: 768px) {
  .detailView .project-list strong {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .detailView .project-list strong {
    font-size: 24px;
  }
}
.detailView .project-list span {
  display: block;
  font-weight: 300;
  font-size: 14px;
  margin: 0 0 10px;
}
@media (min-width: 768px) {
  .detailView .project-list span {
    font-size: 16px;
    margin: 0 0 20px;
  }
}
@media (min-width: 992px) {
  .detailView .project-list span {
    font-size: 18px;
  }
}
.detailView .project-list a {
  color: #000;
}
.detailView .project-list a:hover .image-holder img {
  transform: scale(1.5);
}
@media (min-width: 992px) {
  .detailView .other-product {
    margin: 0 -50px;
  }
}
.detailView .other-product .sub-title-product {
  display: block;
  text-transform: uppercase;
  font-weight: 400;
  color: #7f7f7f;
  border-bottom: 1px solid #e6e6e6;
  margin: 0 0 15px;
  font-size: 14px;
  line-height: 1;
}
@media (min-width: 768px) {
  .detailView .other-product .sub-title-product {
    font-size: 16px;
    margin: 0 0 22px;
    padding: 0 0 0 18px;
  }
}

.lightbox-frame > .container-fluid {
  position: relative;
  z-index: 100;
}

@media (max-width: 767px) {
  .linkBuBack a {
    display: inline-block;
    width: 100%;
    font-size: 16px;
  }
}

.lightbox-frame {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 8080;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  padding: 15px 0 0 0;
}
@media (min-width: 768px) {
  .lightbox-frame {
    padding: 20px 20px 0;
  }
}
@media (min-width: 1400px) {
  .lightbox-frame {
    padding: 30px 0 0;
  }
}
@media screen and (max-height: 800px) {
  .lightbox-frame {
    padding-top: 0px;
  }
}
.lightbox-frame .defaultContainer {
  margin: 0px;
}
.lightbox-frame .linkBuBack {
  display: none;
}
@media (max-width: 767px) {
  .lightbox-frame .linkBuBack a {
    display: inline-block;
    width: 100%;
    font-size: 16px;
  }
}
.lightbox-frame .detailView {
  padding: 0px;
}
.lightbox-frame.lightbox-frame-sm .lightbox-holder {
  max-width: 930px;
  overflow-x: hidden;
  overflow-y: scroll;
  bottom: auto;
  min-height: 200px;
}
@media (min-width: 992px) {
  .lightbox-frame.lightbox-frame-sm .lightbox-holder {
    padding: 25px 25px;
  }
}
.lightbox-frame.lightbox-frame-sm .container-fluid {
  max-width: 930px;
}
.lightbox-frame.lightbox-frame-sm .popup-close {
  z-index: 1000;
}
@media (min-width: 1400px) {
  .lightbox-frame.lightbox-frame-sm .popup-close {
    margin: 0 20px 1px 0;
  }
}
@media (min-width: 992px) {
  .lightbox-frame.lightbox-frame-sm .form-group {
    margin-bottom: 50px;
  }
}
.lightbox-frame .lightbox-holder {
  background: #fff;
  padding: 28px 5px 0;
  max-width: 1400px;
  margin: 0 auto;
  overflow: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 50px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .lightbox-frame .lightbox-holder {
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    right: auto;
    bottom: auto;
  }
}
@media (max-width: 767px) {
  .lightbox-frame .lightbox-holder {
    height: calc(100vh - 78px);
  }
}
@media (min-width: 768px) {
  .lightbox-frame .lightbox-holder {
    padding: 62px 40px 0;
    left: 20px;
    right: 20px;
    top: 70px;
  }
}
@media (min-width: 992px) {
  .lightbox-frame .lightbox-holder {
    padding: 68px 70px 0;
    left: 0px;
    right: 0px;
    top: 70px;
  }
}
@media screen and (max-height: 799px) {
  .lightbox-frame .lightbox-holder {
    top: 0px;
    height: 100vh;
    max-height: 100vh !important;
  }
}
@media screen and (min-height: 800px) and (max-height: 900px) {
  .lightbox-frame .lightbox-holder {
    max-height: 100vh !important;
    height: calc(100vh - 78px);
  }
}
@media (min-width: 1400px) {
  .lightbox-frame .lightbox-holder {
    top: 100px;
  }
}
.lightbox-frame .lightbox-holder .container-fluid {
  margin-right: 0;
  margin-left: 0;
}
.lightbox-frame .popup-close {
  margin: 0 5px 0 0;
  float: right;
  width: 35px;
  height: 35px;
  cursor: pointer;
  z-index: 8040;
  background: #7f7f7f;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .lightbox-frame .popup-close {
    width: 50px;
    height: 50px;
    margin: 0 15px 0 0;
  }
}
@media (min-width: 992px) {
  .lightbox-frame .popup-close {
    margin: 0 20px 1px 0;
  }
}
@media (min-width: 1240px) {
  .lightbox-frame .popup-close {
    margin: 0 0 1px 0;
  }
}
@media (min-width: 1400px) {
  .lightbox-frame .popup-close {
    width: 70px;
    height: 70px;
    margin: 0 -50px 1px 0;
  }
}
.lightbox-frame .popup-close:after {
  font-family: "icomoon" !important;
  content: "\e913";
  clear: both;
  display: block;
  font-size: 18px;
  color: #fff;
}
@media (min-width: 768px) {
  .lightbox-frame .popup-close:after {
    font-size: 28px;
  }
}
.lightbox-frame .container-fluid {
  max-width: 1240px;
}
.lightbox-frame .sub-title {
  text-transform: uppercase;
  font-size: 10px;
  color: #666;
  font-weight: 400;
  position: absolute;
  top: 7px;
  left: 25px;
  display: block;
}
@media (min-width: 768px) {
  .lightbox-frame .sub-title {
    font-size: 16px;
    top: 12px;
    left: 60px;
  }
}
@media (min-width: 992px) {
  .lightbox-frame .sub-title {
    left: 90px;
  }
}
.lightbox-frame h1 {
  margin: 0 0 25px;
}
@media (max-width: 991px) {
  .lightbox-frame h1 {
    font-size: 49px;
    margin: 0 0 15px;
  }
}
@media (max-width: 767px) {
  .lightbox-frame h1 {
    font-size: 26px;
    margin: 0 0 10px;
  }
}
.lightbox-frame h2 {
  margin: 0 0 34px;
}
@media (max-width: 767px) {
  .lightbox-frame h2 {
    font-size: 20px;
    margin: 0 0 2px;
  }
}
@media (max-width: 991px) {
  .lightbox-frame h2 {
    margin: 0 0 19px;
  }
}
@media (min-width: 992px) {
  .lightbox-frame h2 {
    font-size: 30px;
    color: #242424;
  }
}
.lightbox-frame .planning-holder {
  background: #000;
  text-align: center;
  color: #fff;
  padding: 20px 15px 50px;
  margin: 0 -10px;
}
@media (min-width: 768px) {
  .lightbox-frame .planning-holder {
    margin: 0 -55px;
    padding: 30px 0 60px;
  }
}
@media (min-width: 992px) {
  .lightbox-frame .planning-holder {
    margin: 0 -85px;
    padding: 30px 0;
  }
}
.lightbox-frame .planning-holder i {
  font-size: 23px;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 22px;
}
@media (min-width: 768px) {
  .lightbox-frame .planning-holder i {
    font-size: 33px;
  }
}
.lightbox-frame .planning-holder strong {
  text-transform: uppercase;
  display: block;
  font-weight: normal;
  font-size: 20px;
}
@media (min-width: 768px) {
  .lightbox-frame .planning-holder strong {
    font-size: 30px;
    line-height: 1;
  }
}
.lightbox-frame .planning-holder span {
  display: block;
  font-size: 10px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .lightbox-frame .planning-holder span {
    font-size: 18px;
  }
}

.other-product-list {
  margin: 0 0 25px;
}
@media (min-width: 768px) {
  .other-product-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 70px;
  }
}
@media (min-width: 992px) {
  .other-product-list {
    margin: 0 0 107px;
  }
}
.other-product-list li {
  margin: 0 0 10px;
  display: block;
  width: 100%;
}
@media (min-width: 768px) {
  .other-product-list li {
    width: 50%;
    margin: 0 0 0 10px;
  }
  .other-product-list li:first-child {
    margin: 0;
  }
}
@media (min-width: 992px) {
  .other-product-list li {
    width: 33.333%;
  }
}
.other-product-list li:hover .image-holder img {
  transform: scale(1.4);
}
.other-product-list .image-holder {
  overflow: hidden;
  margin: 0 0 5px;
}
@media (min-width: 768px) {
  .other-product-list .image-holder {
    margin: 0 0 15px;
  }
}
.other-product-list .image-holder img {
  vertical-align: top;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}
.other-product-list .text-box {
  padding: 0 0 0 14px;
}
.other-product-list span {
  display: block;
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  color: #666;
  margin: 0 0 5px;
}
@media (min-width: 768px) {
  .other-product-list span {
    font-size: 16px;
  }
}
.other-product-list strong {
  display: block;
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
  color: #242424;
}
@media (min-width: 768px) {
  .other-product-list strong {
    font-size: 22px;
  }
}

.ce-gallery .video .video-embed {
  margin: 20px auto;
}

/* owl carousel styles */
.item-video {
  width: 100%;
  height: 100%;
}
.item-video:before {
  padding-top: 41.666%;
  content: "";
  display: block;
}
.item-video .owl-video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.item-video iframe {
  width: 100%;
  height: 100%;
}
.item-video img {
  display: none !important;
}

.owl-carousel .item img {
  display: block;
  width: 100%;
  height: auto;
}

.owl-carousel {
  overflow: hidden;
  margin: 0 0 16px;
  max-height: 860px;
}
@media (min-width: 768px) {
  .owl-carousel {
    margin: 0 0 56px;
  }
}
.owl-carousel .slide__video-play,
.owl-carousel .owl-video-nav-icon,
.owl-carousel .owl-video-play-icon {
  color: #fff;
  font-size: 80px;
  line-height: 1;
}
@media (min-width: 640px) {
  .owl-carousel .slide__video-play:after,
  .owl-carousel .owl-video-nav-icon:after,
  .owl-carousel .owl-video-play-icon:after {
    font-family: "icomoon" !important;
    content: "\e900";
    position: absolute;
    top: 0;
    left: 0;
  }
}
.owl-carousel .slide__video-play,
.owl-carousel .owl-video-nav-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
}
@media (max-width: 991px) {
  .owl-carousel .owl-video-nav-icon {
    height: 40px;
    width: 40px;
    margin-left: -20px;
    margin-top: -20px;
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .owl-carousel .owl-video-nav-icon {
    height: 20px;
    width: 20px;
    margin-left: -10px;
    margin-top: -10px;
    font-size: 20px;
  }
}
.owl-carousel .owl-nav {
  margin: 0;
}
@media (min-width: 768px) {
  .owl-carousel .owl-nav {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
  }
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  font-size: 22px;
  font-weight: 900;
  display: block;
  position: absolute;
  top: 45%;
  line-height: 0.75em;
  width: 14px;
  height: 24px;
  border-radius: 0;
  background: none !important;
  cursor: pointer;
  text-indent: -9999px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  color: #000 !important;
  margin-top: -7px;
}
@media (min-width: 640px) {
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next {
    display: none;
  }
}
@media (min-width: 992px) {
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next {
    display: none;
  }
}
.owl-carousel .owl-nav .owl-prev:focus,
.owl-carousel .owl-nav .owl-prev:hover,
.owl-carousel .owl-nav .owl-next:focus,
.owl-carousel .owl-nav .owl-next:hover {
  transform: scale(1.2);
}
.owl-carousel .owl-nav .owl-prev:after,
.owl-carousel .owl-nav .owl-next:after {
  font-family: "icomoon" !important;
  content: "\e90d";
  clear: both;
  display: block;
  text-indent: 0 !important;
  position: absolute;
  top: 3px;
  left: -3px;
}
.owl-carousel .owl-nav .owl-prev {
  left: 3px;
}
.owl-carousel .owl-nav .owl-prev:after {
  transform: rotate(-180deg);
}
.owl-carousel .owl-nav .owl-next {
  right: 3px;
}
@media (min-width: 992px) {
  .owl-carousel .owl-nav .owl-next {
    right: 0;
  }
}
@media (min-width: 1400px) {
  .owl-carousel .owl-nav .owl-next {
    right: 100px;
  }
}
.owl-carousel .owl-dots {
  text-align: center;
  padding: 10px 15px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
@media (min-width: 640px) {
  .owl-carousel .owl-dots {
    padding: 10px 25px 8px;
  }
}
@media (min-width: 992px) {
  .owl-carousel .owl-dots {
    padding: 10px 30px 8px;
  }
}
@media (min-width: 1400px) {
  .owl-carousel .owl-dots {
    padding: 10px 0 8px;
  }
}
.owl-carousel .owl-dot {
  display: block;
  width: 6px;
  height: 6px;
  margin: 0 2px;
  outline: none;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #e6e6e6;
  background-repeat: no-repeat;
}
@media (max-width: 639px) {
  .owl-carousel .owl-dot {
    background-position: 3000px 3000px;
  }
}
@media (max-width: 639px) {
  .owl-carousel .owl-dot.active {
    background-color: #000;
  }
}
@media (min-width: 640px) {
  .owl-carousel .owl-dot {
    border: 1px solid #e6e6e6;
    width: 80px;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .owl-carousel .owl-dot {
    width: 100px;
    height: 70px;
    margin: 0 5px;
  }
}
@media (min-width: 992px) {
  .owl-carousel .owl-dot {
    width: 145px;
    height: 100px;
  }
}
@media (min-width: 1400px) {
  .owl-carousel .owl-dot {
    width: 225px;
    height: 150px;
  }
}
.owl-carousel .owl-dot span {
  display: none !important;
}
.owl-carousel .owl-dot:after {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 5px;
  right: 100%;
  height: 1px;
  background: #7f7f7f;
  transition: right 0.3s ease-in-out, height 0.3s ease-in-out, bottom 0.3s ease-in-out;
}
.owl-carousel .owl-dot:focus,
.owl-carousel .owl-dot:hover {
  outline: none;
}
.owl-carousel .owl-dot:focus:after,
.owl-carousel .owl-dot:hover:after {
  right: 5px;
}
.owl-carousel .owl-dot span {
  display: none;
}
.owl-carousel .owl-dot.active:after {
  right: 5px;
  height: 3px;
  bottom: -8px;
}

@media (min-width: 768px) {
  .contacts-holder {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.contacts-holder .image-holder {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  overflow: hidden;
  margin: 15px auto 0;
}
@media (min-width: 768px) {
  .contacts-holder .image-holder {
    margin: 30px 0 0;
  }
}
.contacts-holder .image-holder img {
  width: 100%;
  vertical-align: top;
}

/* media communications holder styles */
.media-communications-holder {
  padding: 20px 0 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .media-communications-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .media-communications-holder .column {
    width: 50%;
    padding: 0 20px 0 0;
  }
}
@media (min-width: 992px) {
  .media-communications-holder .column {
    padding: 0 50px 0 0;
  }
}
.media-communications-holder .title {
  display: block;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 15px;
}
@media (min-width: 768px) {
  .media-communications-holder .title {
    font-size: 30px;
    margin: 0 0 30px;
  }
}

.media-communications-list li {
  display: block;
  margin: 0 0 20px;
}
@media (min-width: 768px) {
  .media-communications-list li {
    margin: 0 0 25px;
  }
}
@media (min-width: 992px) {
  .media-communications-list li {
    margin: 0 0 40px;
  }
}

.media-communications-list.two-cols li {
  display: inline-block;
  width: 50%;
}

/* partner list styles */
.partner-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 21px 20px 25px;
}
@media (min-width: 768px) {
  .partner-list {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .partner-list {
    padding: 59px 27px 0 27px;
  }
}
.partner-list li {
  width: 50%;
  text-align: center;
  margin: 0 0 17px;
  padding: 0 5px;
}
@media (min-width: 768px) {
  .partner-list li {
    padding: 0 10px;
    margin: 0 0 52px;
  }
}
@media (min-width: 992px) {
  .partner-list li {
    width: 33.333333%;
  }
}
@media (max-width: 767px) {
  .partner-list li.large-height img {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .partner-list li.large-height img {
    width: auto;
  }
}
.partner-list li:hover img {
  transform: scale(1.1);
}
.partner-list img {
  vertical-align: top;
  border: 1px solid #ddd;
  transition: transform 0.5s ease-in-out;
  max-width: 100%;
}
@media (max-width: 767px) {
  .partner-list img {
    width: 100%;
  }
}
.partner-list a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px none !important;
}

/* article box styles */
.article-box {
  margin: 0 0 40px;
}
@media (min-width: 768px) {
  .article-box.ce-intext {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 50px;
  }
}
@media (min-width: 992px) {
  .article-box.ce-intext {
    margin: 0 0 105px;
  }
}
@media (min-width: 768px) {
  .article-box.reverse.ce-intext {
    flex-direction: row-reverse;
  }
  .article-box.reverse.ce-intext .text-box {
    padding: 19px 0 0 60px;
  }
}
.article-box.ce-intext .text-box {
  padding: 0 0 10px;
}
@media (min-width: 768px) {
  .article-box.ce-intext .text-box {
    width: 59%;
    padding: 19px 60px 0 0;
  }
}
.article-box.ce-intext .position-110.text-box {
  padding: 0 0 10px;
}
@media (min-width: 768px) {
  .article-box.ce-intext .position-110.text-box {
    width: 50%;
    padding: 19px 60px 0 0;
  }
}
@media (min-width: 992px) {
  .article-box.ce-intext .position-110.text-box {
    width: 40%;
  }
}
@media (min-width: 768px) {
  .article-box.reverse.ce-intext .position-110.text-box {
    padding: 19px 0 0 60px;
  }
}
.article-box.ce-intext .image-holder {
  padding: 0 0 10px;
}
@media (min-width: 768px) {
  .article-box.ce-intext .image-holder {
    width: 41%;
  }
}
.article-box.ce-intext .image-holder img {
  vertical-align: top;
  width: 100%;
}
.article-box.ce-intext .position-110 + .image-holder {
  padding: 0 0 10px;
}
@media (min-width: 768px) {
  .article-box.ce-intext .position-110 + .image-holder {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .article-box.ce-intext .position-110 + .image-holder {
    width: 60%;
  }
}
.article-box.ce-intext .position-110 + .image-holder img {
  vertical-align: top;
  width: 100%;
}
.article-box h1 {
  margin: 0 0 4px;
}
@media (min-width: 768px) {
  .article-box h1 {
    margin: 0 0 20px;
  }
}
@media (min-width: 992px) {
  .article-box p {
    margin: 0 0 28px;
  }
}

/* we hire styles */
.we-hire h1 {
  margin: 0 0 5px;
}
@media (min-width: 768px) {
  .we-hire h1 {
    margin: 0 0 22px;
  }
}

.we-hire img {
  vertical-align: top;
  width: 100%;
}

.load-more-holder {
  text-align: center;
  min-height: 216px;
}

/* sitemap-section styles */
.sitemap-section {
  padding: 20px 0 0;
}
@media (min-width: 768px) {
  .sitemap-section {
    padding: 40px 0 0 0;
  }
}
@media (min-width: 992px) {
  .sitemap-section {
    padding: 69px 0 0 0;
  }
}
.sitemap-section h1 {
  margin: 0 0 15px;
}
@media (min-width: 768px) {
  .sitemap-section h1 {
    margin: 0 0 20px;
  }
}
@media (min-width: 992px) {
  .sitemap-section h1 {
    margin: 0 0 81px;
  }
}
.sitemap-section h2 {
  font-weight: 700;
  margin: 0 0 20px;
  font-size: 18px;
}
@media (min-width: 768px) {
  .sitemap-section h2 {
    margin: 0 0 43px;
    font-size: 30px;
  }
}
.sitemap-section .column-holder {
  overflow: hidden;
}
@media (min-width: 768px) {
  .sitemap-section .column-holder {
    padding: 0 0 0 16px;
  }
}
@media (min-width: 768px) {
  .sitemap-section .column {
    width: 33.333%;
    float: left;
    padding: 0 20px 0 0;
  }
}
.sitemap-section .sitemap-open-close {
  margin: 0 0 10px;
}
@media (min-width: 768px) {
  .sitemap-section .sitemap-open-close {
    margin: 0 0 20px;
  }
}
@media (min-width: 992px) {
  .sitemap-section .sitemap-open-close {
    margin: 0 0 42px;
  }
}
.sitemap-section .sitemap-opener {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
@media (min-width: 768px) {
  .sitemap-section .sitemap-opener {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .sitemap-section .sitemap-opener {
    font-size: 28px;
  }
}
.sitemap-section .sitemap-opener:hover {
  color: #666;
}
.sitemap-section .sitemap-slide > {
  padding: 8px 0 0 20px;
}
.sitemap-section .sitemap-slide > ul li {
  display: block;
}
.sitemap-section .drop-sitemap {
  font-size: 16px;
  font-weight: 400;
  padding: 9px 0 6px 20px;
}
@media (min-width: 768px) {
  .sitemap-section .drop-sitemap {
    font-size: 20px;
  }
}
.sitemap-section .drop-sitemap a {
  color: #000;
}
.sitemap-section .drop-sitemap a:hover {
  color: #666;
}
.sitemap-section .drop-sitemap-slide {
  padding: 6px 0 0 11px;
}
.sitemap-section .drop-sitemap-slide li {
  display: block;
  font-weight: 400;
  font-size: 14px;
  margin: 2px 0 0;
}
@media (min-width: 768px) {
  .sitemap-section .drop-sitemap-slide li {
    font-size: 16px;
  }
}
.sitemap-section .drop-sitemap-slide li:first-child {
  margin: 0;
}
.sitemap-section .drop-sitemap-slide a {
  font-size: 14px;
  color: #666;
}
@media (min-width: 768px) {
  .sitemap-section .drop-sitemap-slide a {
    font-size: 16px;
  }
}
.sitemap-section .drop-sitemap-slide a:hover {
  color: #000;
}
@media (min-width: 992px) {
  .sitemap-section .js-slide-hidden {
    position: static !important;
    left: 0 !important;
    top: 0 !important;
    display: block !important;
  }
}

.slider-range-holder {
  position: relative;
  padding: 0 12px 3px;
  margin: 0 25px 0 15px;
}
.slider-range-holder .jcf-range {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.slider-range-holder .jcf-range .jcf-range-display-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.slider-range-holder .jcf-range .jcf-range-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #000;
}
.slider-range-holder .jcf-range .jcf-range-wrapper {
  background: #e6e6e6;
  margin: 0;
  height: 3px;
  border-radius: 0;
}
.slider-range-holder .jcf-range .jcf-range-track {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.slider-range-holder .jcf-range .jcf-range-handle {
  cursor: pointer;
  border: 0;
  background: #000;
  outline: none;
  width: 12px;
  height: 12px;
  margin: -4px 0 0;
  transition: transform 0.3s ease-in-out;
}
.slider-range-holder .jcf-range .jcf-range-handle.jcf-index-2 {
  margin: -4px 0 0 0;
}
.slider-range-holder .jcf-range.jcf-focus .jcf-active-handle {
  transform: scale(1.4);
}
.slider-range-holder .jcf-range .amount1,
.slider-range-holder .jcf-range .amount2 {
  transform: scale(0.72);
  margin: -6px 0 0 0;
}
.slider-range-holder .amount1,
.slider-range-holder .amount2 {
  white-space: nowrap;
  position: absolute;
  top: 20px;
  left: -6px;
  color: #242424;
  font-size: 14px;
  line-height: 1;
  border: 0;
  padding: 0;
  margin: 0;
  transition: none;
}
.slider-range-holder .amount1 em,
.slider-range-holder .amount2 em {
  font-style: normal;
}
.slider-range-holder .amount2 {
  left: auto;
  right: -15px;
}

/* animation company section */
@-webkit-keyframes scale {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes height {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes height {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .print-btn {
    display: none;
  }
}

/* merkliste section styles*/
.merkliste-section {
  padding: 30px 0 0 0;
}
@media (min-width: 768px) {
  .merkliste-section {
    padding: 69px 0 0 0;
  }
}
@media (max-width: 767px) {
  .merkliste-section .btn-download b,
  .merkliste-section .btn-download em {
    display: none;
  }
}
.merkliste-section h1 {
  margin: 0 0 20px;
}
@media (min-width: 768px) {
  .merkliste-section h1 {
    margin: 0 0 31px;
  }
}
@media (min-width: 992px) {
  .merkliste-section h1 {
    margin: 0 0 81px;
  }
}
.merkliste-section h1 span {
  display: inline-block;
  vertical-align: top;
}
.merkliste-section .bookmark {
  display: inline-block;
  vertical-align: top;
  color: #666;
  font-size: 20px;
  margin: 0 0 0 18px;
}
@media (min-width: 768px) {
  .merkliste-section .bookmark {
    font-size: 35px;
  }
}
@media (min-width: 992px) {
  .merkliste-section .bookmark {
    font-size: 40px;
  }
}
.merkliste-section .bookmark:hover {
  color: #000;
}
.merkliste-section .title-table {
  display: block;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #7f7f7f;
  margin: 0 0 15px;
}
@media (min-width: 768px) {
  .merkliste-section .title-table {
    text-align: right;
    margin: 0 0 34px;
  }
}
.merkliste-section .title-table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 13px;
}
@media (max-width: 767px) {
  .merkliste-section .title-table-row.hide-phone {
    display: none;
  }
}
.merkliste-section .title-table-row .cell {
  width: 30%;
  font-weight: normal;
  font-size: 16px;
}
.merkliste-section .title-table-row .cell:last-child {
  width: 10%;
}
.merkliste-section .title-table-row .cell:first-child {
  padding: 0 0 0 120px;
}
.merkliste-section .table {
  font-size: 18px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .merkliste-section .table {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .merkliste-section .table {
    font-size: 18px;
  }
}
.merkliste-section .table .sub-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 10px;
  border-bottom: 1px solid #e6e6e6;
  margin: 0 0 10px;
}
.merkliste-section .table .sub-row:last-child {
  margin: 0;
}
.merkliste-section .table .cell {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
}
@media (min-width: 768px) {
  .merkliste-section .table .cell {
    width: 31%;
  }
}
@media (min-width: 992px) {
  .merkliste-section .table .cell {
    width: 30%;
  }
}
@media (max-width: 767px) {
  .merkliste-section .table .cell:first-child {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .merkliste-section .table .cell.hide-phone {
    display: none;
  }
}
@media (min-width: 768px) {
  .merkliste-section .table .cell:last-child {
    justify-content: flex-end;
    padding: 0 19px 0 0;
    width: 7%;
  }
}
@media (min-width: 992px) {
  .merkliste-section .table .cell:last-child {
    width: 20%;
  }
}
.merkliste-section .image-holder {
  float: left;
  margin: 0 10px 0 8px;
  width: 14%;
  overflow: hidden;
}
@media (min-width: 768px) {
  .merkliste-section .image-holder {
    width: 80px;
    margin: 0 6px 0 8px;
  }
}
@media (min-width: 992px) {
  .merkliste-section .image-holder {
    margin: 0 31px 0 8px;
  }
}
.merkliste-section .image-holder img {
  vertical-align: top;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}
.merkliste-section .image-holder img:hover {
  transform: scale(1.2);
}
.merkliste-section .text-box {
  overflow: hidden;
  width: 86%;
}
@media (min-width: 768px) {
  .merkliste-section .text-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
  }
}
.merkliste-section .text-box span {
  display: block;
  font-weight: 300;
  font-size: 13px;
  display: none;
}
@media (min-width: 768px) {
  .merkliste-section .text-box span {
    font-size: 18px;
  }
}
.merkliste-section .text-box span.artikelnummer {
  display: block;
}
@media (min-width: 768px) {
  .merkliste-section .text-box span.artikelnummer {
    display: none;
  }
}
.merkliste-section .text-box strong {
  display: block;
  font-size: 13px;
  font-weight: normal;
}
@media (min-width: 768px) {
  .merkliste-section .text-box strong {
    font-size: 18px;
  }
}
.merkliste-section .text-box strong a {
  color: #000;
}
.merkliste-section .text-box strong a:hover {
  color: #7f7f7f;
}
.merkliste-section .delete {
  width: 24px;
  height: 24px;
  background: #666;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease-in-out;
}
.merkliste-section .delete:hover {
  background: #000;
}
.merkliste-section .button-row {
  text-align: center;
}
@media (min-width: 768px) {
  .merkliste-section .button-row {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .merkliste-section .button-row .btn {
    margin: 0 4px;
  }
}
@media (max-width: 767px) {
  .merkliste-section .button-row .btn.btn-with-ico {
    font-size: 12px;
    max-width: 125px;
  }
}
.merkliste-section .clear {
  margin: 0 0 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .merkliste-section .clear {
    text-align: right;
  }
}
.merkliste-section .clear-all {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  color: #7f7f7f;
  font-weight: 400;
}
.merkliste-section .clear-all:hover {
  color: #000;
}

.lightbox-frame .merkliste-section .text-box strong {
  font-size: 14px;
}
@media (min-width: 992px) {
  .lightbox-frame .merkliste-section .text-box strong {
    font-size: 18px;
  }
}

.lightbox-frame .merkliste-section .title-table-row .cell {
  width: 30%;
}
@media (min-width: 992px) {
  .lightbox-frame .merkliste-section .title-table-row .cell {
    width: 32%;
  }
}
.lightbox-frame .merkliste-section .title-table-row .cell:last-child {
  width: 10%;
}
@media (min-width: 992px) {
  .lightbox-frame .merkliste-section .title-table-row .cell:last-child {
    width: 5%;
  }
}

.lightbox-frame .merkliste-section .amount {
  max-width: 115px;
}
@media (max-width: 767px) {
  .lightbox-frame .merkliste-section .amount {
    display: block;
    max-width: 35px;
    padding: 7px 5px 1px 5px;
    font-size: 14px;
  }
}

.lightbox-frame .merkliste-section .table .cell {
  width: 30%;
}
@media (min-width: 992px) {
  .lightbox-frame .merkliste-section .table .cell {
    width: 32%;
  }
}
.lightbox-frame .merkliste-section .table .cell:first-child {
  width: 85%;
}
@media (min-width: 768px) {
  .lightbox-frame .merkliste-section .table .cell:first-child {
    width: 30%;
  }
}
.lightbox-frame .merkliste-section .table .cell:last-child {
  width: 10%;
}
@media (min-width: 992px) {
  .lightbox-frame .merkliste-section .table .cell:last-child {
    width: 5%;
  }
}
@media (max-width: 767px) {
  .lightbox-frame .merkliste-section .table .cell:last-child {
    width: 11%;
  }
}

.suche-frame {
  padding: 20px 0 0;
}
@media (min-width: 768px) {
  .suche-frame {
    padding: 69px 0 0;
  }
}
.suche-frame h1 {
  margin: 0 0 6px;
}
@media (min-width: 768px) {
  .suche-frame h1 {
    max-width: 60%;
    margin: 0;
  }
}
.suche-frame .title-frame-row {
  margin: 0 0 30px;
}
@media (min-width: 768px) {
  .suche-frame .title-frame-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 83px;
  }
}
.suche-frame .sub-search-form .form-group {
  margin: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .suche-frame .sub-search-form .form-group {
    width: auto;
  }
}
.suche-frame .sub-search-form .form-control {
  width: 100%;
}
@media (min-width: 768px) {
  .suche-frame .sub-search-form .form-control {
    min-width: 460px;
    width: auto;
  }
}
.suche-frame .sub-search-form .sub-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 768px) {
  .suche-frame .sub-search-form .sub-row {
    justify-content: flex-start;
  }
}

.tabset-holder {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .tabset-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    min-height: 16px;
    padding-bottom: 0px;
  }
}
.tabset-holder .title-tabset {
  display: block;
  font-size: 14px;
  margin: 0 0 7px;
}
@media (min-width: 768px) {
  .tabset-holder .title-tabset {
    display: none;
  }
}
@media (max-width: 767px) {
  .tabset-holder .tabset {
    display: none;
  }
}
@media (min-width: 768px) {
  .tabset-holder .tabset {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 -1px;
    padding: 0 0 0 49px;
  }
}
.tabset-holder .tabset li {
  display: block;
}
@media (min-width: 768px) {
  .tabset-holder .tabset li {
    margin: 0 30px 0 0;
  }
}
@media (min-width: 992px) {
  .tabset-holder .tabset li {
    margin: 0 77px 0 0;
  }
}
.tabset-holder .tabset li:last-child {
  margin: 0;
}
.tabset-holder .tabset a {
  display: block;
  text-transform: uppercase;
  color: #808080;
  font-size: 18px;
  line-height: 1;
  border-bottom: 1px solid #e6e6e6;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.tabset-holder .tabset a:hover,
.tabset-holder .tabset a.active {
  color: #000;
  border-color: #000;
}
.tabset-holder .counter {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  color: #7f7f7f;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  padding: 20px 0 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width: 768px) {
  .tabset-holder .counter {
    text-align: left;
    margin: 0 12px 0 0;
    font-size: 16px;
    padding: 0;
  }
}
.tabset-holder .counter em {
  font-style: normal;
}
@media (min-width: 768px) {
  .tabset-holder .tabset-select {
    display: none;
  }
}

.tab {
  display: block;
  padding: 20px 0 0 0;
  text-align: center;
}
@media (min-width: 768px) {
  .tab {
    padding: 57px 0 0 0;
    text-align: left;
  }
}
.tab .btn {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .tab .btn {
    margin-top: 40px;
  }
}
.tab h3 {
  margin: 0 0 15px;
  font-size: 20px;
  font-weight: 400;
}
@media (min-width: 768px) {
  .tab h3 {
    font-size: 30px;
    margin: 0 0 28px;
  }
}
.tab .result-box {
  margin: 30px 0 0;
  text-align: left;
}
@media (min-width: 768px) {
  .tab .result-box {
    margin: 70px 0 0;
  }
}
.tab .result-box:first-of-type {
  margin: 0;
}
.tab .result {
  margin: 15px 0 0;
}
@media (min-width: 768px) {
  .tab .result {
    margin: 10px 0 0;
  }
}
.tab .result:first-of-type {
  margin: 0;
}
.tab .result .image-holder {
  overflow: hidden;
  float: left;
  width: 93px;
  height: 93px;
  margin: 0 9px 0 0;
}
@media (min-width: 768px) {
  .tab .result .image-holder {
    width: 108px;
    height: 108px;
  }
}
.tab .result .image-holder:hover img {
  transform: scale(1.2);
}
.tab .result .image-holder a {
  display: block;
}
.tab .result .image-holder img {
  vertical-align: top;
  width: 100%;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}
.tab .result .text-box {
  overflow: hidden;
  padding: 8px 0 0 0;
  max-width: 811px;
}
.tab .result h4 {
  margin: 0 0 5px;
  font-weight: normal;
  font-size: 16px;
}
@media (min-width: 768px) {
  .tab .result h4 {
    font-size: 22px;
  }
}
.tab .result p,
.tab .result a {
  margin: 0 0 8px;
  color: #666;
  font-size: 12px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .tab .result p,
  .tab .result a {
    font-size: 18px;
  }
}
.tab .result a {
  font-size: 14px;
  margin: 0;
}
@media (max-width: 767px) {
  .tab .result a {
    display: none;
  }
}
.tab .result a:hover {
  color: #000;
}

@media (max-width: 767px) {
  #searchTab .tab .result a {
    display: inline-block;
  }
}

.notVisible {
  display: none !important;
}

/* hidden section styles*/
.js-tab-hidden {
  display: block !important;
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important;
}

.sub-nav {
  background: #f2f2f2;
  padding: 13px 0 6px;
  display: none;
}
@media (min-width: 768px) {
  .sub-nav {
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -18px;
  }
}
@media (min-width: 1400px) {
  .sub-nav {
    margin-top: -30px;
  }
}
.sub-nav li {
  margin: 0 30px;
}
.sub-nav a {
  color: #7f7f7f;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  display: block;
  border-bottom: 1px solid #f2f2f2;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.sub-nav a.active,
.sub-nav a:hover {
  color: #000;
  border-color: #000;
}

/* breadcrumbs styles */
.breadcrumb {
  margin-bottom: 0;
}
.breadcrumb > li {
  font-size: 10px;
  line-height: 1;
  font-weight: 400;
}
@media (min-width: 768px) {
  .breadcrumb > li {
    font-size: 16px;
  }
}
.breadcrumb > li:first-child {
  text-transform: uppercase;
}
.breadcrumb > li:first-child a {
  text-transform: uppercase;
}
.breadcrumb a {
  font-size: 10px;
  line-height: 1;
  color: #666;
}
@media (min-width: 768px) {
  .breadcrumb a {
    font-size: 16px;
  }
}
.breadcrumb a:hover {
  color: #000;
}

.breadcrumb > li + li:before {
  padding: 0;
}

/* info-product section styles */
.info-product-frame {
  padding: 15px 0 0 0;
}
@media (min-width: 640px) {
  .info-product-frame .owl-carousel.without-pagination .owl-nav {
    display: none;
  }
}
.info-product-frame h1 {
  margin: 0 0 5px;
  text-transform: none;
}
@media (min-width: 768px) {
  .info-product-frame h1 {
    margin: 0 0 24px;
  }
}
.info-product-frame h3 {
  margin: 0 0 3px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .info-product-frame h3 {
    font-size: 24px;
    margin: 0 0 10px;
  }
}
.info-product-frame p {
  margin: 0 0 16px;
  max-width: 1310px;
}
@media (min-width: 768px) {
  .info-product-frame p {
    margin: 0;
    font-size: 20px;
    line-height: 1.4;
  }
}
.info-product-frame .product-description-accordion:last-of-type {
  border-bottom: 1px solid #e6e6e6;
}
.info-product-frame .product-description-accordion:last-of-type:after {
  display: none;
}
@media (min-width: 768px) {
  .info-product-frame .product-description-accordion:last-of-type {
    border-bottom: 0;
  }
}

/* product accordion styles */
.product-description-accordion {
  position: relative;
  margin: 0 0px;
  border-bottom: 1px solid #e6e6e6;
}
@media (min-width: 768px) {
  .product-description-accordion {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0 0 13px;
    border-bottom: 0px none;
  }
}
.product-description-accordion + .product-description-accordion .product-description:first-child {
  border-top: 1px solid #e6e6e6;
}
@media (min-width: 768px) {
  .product-description-accordion + .product-description-accordion .product-description:first-child {
    border-top: 0;
  }
}
.product-description-accordion:after {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  bottom: -44px;
  left: 0;
  right: 0;
  height: 1px;
  background: #e6e6e6;
}
@media (max-width: 767px) {
  .product-description-accordion:after {
    display: none;
  }
}
@media (min-width: 768px) {
  .product-description-accordion .media-communications-list {
    padding-top: 10px;
  }
}
.product-description-accordion .product-description {
  border-top: 1px solid #e6e6e6;
}
.product-description-accordion .product-description:first-child {
  border-top: 0;
}
.product-description-accordion .product-description.active {
  background: #f2f2f2;
}
.product-description-accordion .product-description.active .row-opener .opener-link:after {
  transform: rotate(-90deg);
}
@media (min-width: 768px) {
  .product-description-accordion .product-description {
    max-width: 60%;
    padding: 0 30px 0 0;
    border-top: 0;
  }
}
@media (min-width: 992px) {
  .product-description-accordion .product-description {
    max-width: 60%;
    padding: 0 150px 0 0;
  }
}
.product-description-accordion .product-description a {
  border: 0px none;
}
@media (min-width: 768px) {
  .product-description-accordion .row-opener {
    margin: 0 0 5px;
  }
}
.product-description-accordion .row-opener .fake-opener {
  display: none;
  font-size: 16px;
  color: #666;
  text-transform: uppercase;
  font-weight: 400;
}
@media (min-width: 768px) {
  .product-description-accordion .row-opener .fake-opener {
    display: block;
  }
}
.product-description-accordion .row-opener .opener-link {
  display: block;
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  font-weight: 400;
  padding: 14px 0 10px 24px;
  position: relative;
}
.product-description-accordion .row-opener .opener-link:after {
  font-family: "icomoon" !important;
  content: "\e90d";
  clear: both;
  display: block;
  position: absolute;
  top: 13px;
  left: 6px;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .product-description-accordion .row-opener .opener-link {
    display: none;
  }
}
@media (min-width: 768px) {
  .product-description-accordion .js-slide-hidden {
    position: static !important;
    top: 0 !important;
    left: 0 !important;
    visibility: visible !important;
  }
}
.product-description-accordion ul {
  padding: 9px 15px 16px 26px;
}
@media (min-width: 768px) {
  .product-description-accordion ul {
    padding: 0;
  }
}
.product-description-accordion ul li {
  display: block;
  line-height: 1.6;
  position: relative;
  font-size: 15px;
  line-height: 1.333;
  margin: 0 0 1px;
}
@media (min-width: 768px) {
  .product-description-accordion ul li {
    font-size: 20px;
    line-height: 1.4;
  }
}
@media (min-width: 992px) {
  .product-description-accordion ul li {
    line-height: 1.6;
  }
}
.product-description-accordion ul li:after {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  top: 6px;
  left: -14px;
  width: 4px;
  height: 4px;
  background: #7f7f7f;
  border-radius: 50%;
  overflow: hidden;
}
@media (min-width: 768px) {
  .product-description-accordion ul li:after {
    top: 12px;
  }
}
.product-description-accordion ul.media-communications-list li {
  margin: 0 0 30px;
}
.product-description-accordion ul.media-communications-list li:after {
  display: none;
}
.product-description-accordion dl {
  margin: 0;
  padding: 9px 15px 16px 26px;
}
@media (min-width: 768px) {
  .product-description-accordion dl {
    padding: 0;
  }
}
.product-description-accordion dl dt {
  float: left;
  clear: both;
  margin: 0;
  font-weight: normal;
  position: relative;
  font-size: 15px;
  line-height: 1.333;
}
@media (min-width: 768px) {
  .product-description-accordion dl dt {
    font-size: 20px;
    line-height: 1.4;
  }
}
@media (min-width: 992px) {
  .product-description-accordion dl dt {
    line-height: 1.6;
  }
}
.product-description-accordion dl dt:after {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  top: 6px;
  left: -14px;
  width: 4px;
  height: 4px;
  background: #7f7f7f;
  border-radius: 50%;
  overflow: hidden;
}
@media (min-width: 768px) {
  .product-description-accordion dl dt:after {
    top: 12px;
  }
}
.product-description-accordion dl dd {
  float: left;
  margin: 0;
  font-size: 15px;
  line-height: 1.333;
}
@media (min-width: 768px) {
  .product-description-accordion dl dd {
    font-size: 20px;
    line-height: 1.4;
  }
}
@media (min-width: 992px) {
  .product-description-accordion dl dd {
    line-height: 1.6;
  }
}

.owl-carousel-bg-frame {
  background: #f2f2f2;
  margin: 21px -9999px 27px;
  padding: 21px 9999px 10px;
  z-index: 2;
  position: relative;
}
@media (min-width: 768px) {
  .owl-carousel-bg-frame {
    margin: 43px -9999px 0;
    padding: 40px 9999px 20px;
  }
}
@media (min-width: 992px) {
  .owl-carousel-bg-frame {
    padding: 78px 9999px 60px;
  }
}
.owl-carousel-bg-frame .owl-carousel-bg-holder {
  position: relative;
}
.owl-carousel-bg-frame .projektname {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  z-index: 3;
  padding: 7px 5px 1px 5px;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .owl-carousel-bg-frame .projektname {
    font-size: 24px;
    padding: 11px 10px 5px 10px;
    top: -18px;
    left: 7px;
    transform: translate(0, 0);
  }
}
.owl-carousel-bg-frame .projektname:hover {
  background: #fff;
  color: #000;
}
.owl-carousel-bg-frame .owl-carousel {
  margin: 0 auto;
  max-width: 1396px;
  padding: 0 5px;
}
@media (min-width: 768px) {
  .owl-carousel-bg-frame .owl-carousel {
    padding: 0 50px;
  }
}
.owl-carousel-bg-frame .owl-carousel .owl-dots {
  display: none;
}
.owl-carousel-bg-frame .owl-carousel .owl-nav {
  left: 4px;
}
.owl-carousel-bg-frame .owl-carousel .owl-nav .owl-next {
  right: 6px;
}
@media (min-width: 640px) {
  .owl-carousel-bg-frame .owl-carousel .owl-nav .owl-prev,
  .owl-carousel-bg-frame .owl-carousel .owl-nav .owl-next {
    display: none;
    top: 44%;
    left: 0;
    margin: -17px 0 0 0;
  }
  .owl-carousel-bg-frame .owl-carousel .owl-nav .owl-next {
    left: auto;
    right: 15px;
    margin: -17px 0 0 0;
  }
}
@media (min-width: 992px) {
  .owl-carousel-bg-frame .owl-carousel .owl-nav .owl-prev,
  .owl-carousel-bg-frame .owl-carousel .owl-nav .owl-next {
    display: block;
    font-size: 40px;
    margin: -17px 0 0 -7px;
    top: 44%;
  }
  .owl-carousel-bg-frame .owl-carousel .owl-nav .owl-next {
    margin: -17px 0 0 0;
  }
}

/* article secyion styles */
.article-section .sub-title-section {
  text-transform: uppercase;
  font-size: 10px;
  color: #7f7f7f;
  font-weight: 400;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  padding: 0;
  margin: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .article-section .sub-title-section {
    font-size: 16px;
    text-align: left;
    padding: 0 0 0px 12px;
    margin: 0 0 13px;
  }
}

@media (max-width: 767px) {
  .familie-product {
    text-align: center;
  }
}

.familie-product .otherFamily {
  border-top: 1px solid #e6e6e6;
  padding: 15px 5px 10px;
}

.familie-product .other-product-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  text-align: left;
}
.familie-product .other-product-list .linkFamily {
  position: relative;
}
.familie-product .other-product-list .linkFamily .image-holder {
  display: block;
  height: 0;
  padding-top: 100%;
  background: white;
  position: relative;
}
.familie-product .other-product-list .linkFamily .image-holder .imageIcon {
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.familie-product .other-product-list .linkFamily .image-holder .imageIcon:before {
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  font-size: 50px;
}
@media (min-width: 992px) {
  .familie-product .other-product-list .linkFamily .image-holder .imageIcon:before {
    width: 100px;
    height: 100px;
    font-size: 100px;
  }
}
@media (max-width: 767px) {
  .familie-product .other-product-list {
    margin: 0 -5px;
  }
}
@media (min-width: 768px) {
  .familie-product .other-product-list {
    margin: 0 0 30px;
  }
}
.familie-product .other-product-list li {
  width: 50%;
  padding: 0 5px;
}
@media (min-width: 768px) {
  .familie-product .other-product-list li {
    width: 25%;
    margin: 0 0 10px;
  }
}
@media (max-width: 767px) {
  .familie-product .other-product-list .text-box {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .familie-product .other-product-list strong {
    font-size: 12px;
    line-height: 1.166;
  }
}
@media (max-width: 991px) {
  .familie-product .other-product-list strong {
    font-size: 16px;
  }
}

.familie-product .sub-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  color: #7f7f7f;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  padding: 0;
  margin: 0 0 17px;
  text-align: center;
}
@media (min-width: 768px) {
  .familie-product .sub-title {
    text-align: left;
    font-size: 16px;
    padding: 0 0 0px 12px;
  }
}

.head-article {
  display: none;
  position: relative;
}
@media (min-width: 768px) {
  .head-article {
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 82px;
  }
}

/* newsletter section styles */
.newsletter-section {
  padding: 30px 0 0 0;
}
@media (min-width: 768px) {
  .newsletter-section {
    padding: 69px 0 0 0;
  }
}
.newsletter-section h1 {
  margin: 0 0 20px;
}
@media (min-width: 992px) {
  .newsletter-section h1 {
    margin: 0 0 81px;
  }
}
.newsletter-section h3 {
  margin: 0 0 15px;
  font-size: 20px;
}
@media (min-width: 768px) {
  .newsletter-section h3 {
    font-size: 30px;
    margin: 0 0 32px;
  }
}

/* custom input styles */
.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 0;
  width: 100%;
  vertical-align: top;
  position: relative;
}
.input.textarea .input__field {
  min-height: 115px !important;
  max-height: 115px !important;
  max-width: 100% !important;
}
.input.textarea .input__label-content {
  line-height: 1.333;
  height: 115px !important;
}

.input__field {
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  padding: 0 5px;
  width: 100%;
  height: 100%;
  background: transparent;
  text-align: left;
  color: #000;
  font-weight: 400;
  border-bottom: 1px solid #e6e6e6;
  transition: border-color 0.3s ease-in-out;
}
.input__field.input-error {
  border-color: #ff0000;
  color: #ff0000;
}
.input__field.input-error + label span {
  color: #ff0000;
}
.input__field.input-error:focus {
  border-color: #ff0000;
}
.input__field:focus {
  border-color: #000;
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background: none;
  color: red;
  cursor: text;
  font-weight: 300;
}

.input__label-content {
  position: relative;
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 1.444;
  height: 30px !important;
  padding: 6px 4px 2px !important;
  color: #666;
  font-weight: 300;
  transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out;
}
.input__label-content em {
  font-style: normal;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
}

.parent-active .input__label {
  cursor: default;
  pointer-events: none;
}

.parent-active .input__label .input__label-content {
  transform: translate3d(0, -25px, 0);
  font-size: 16px;
  font-weight: 400;
}

.row.registerRow {
  margin: 0;
}

.colTeaser > div {
  padding: 0 20px;
  border-left: #000 solid 1px;
  border-right: #000 solid 1px;
}
.colTeaser > div:first-child {
  padding-left: 5px;
  border-left: 0;
}
.colTeaser > div:last-child {
  padding-right: 5px;
  margin-left: -1px;
  border-right: 0;
}

/* merklist senden styles */
.merklist-senden .link-holder {
  border-bottom: 1px solid #e6e6e6;
  margin: 0 0 40px;
}
.merklist-senden .link-holder span {
  font-weight: 400;
  font-size: 16px;
  color: #666;
}
.merklist-senden .link-holder span.clipboard {
  display: block;
  width: 100%;
  word-wrap: break-word;
}
.merklist-senden .link-holder a {
  font-size: 14px;
  color: #666;
  font-weight: normal;
}
@media (min-width: 768px) {
  .merklist-senden .link-holder a {
    font-size: 18px;
  }
}
.merklist-senden .link-holder a:hover {
  color: #000;
}

.merklist-senden .submit-holder {
  text-align: center;
  padding: 40px 0 60px;
}
@media (min-width: 768px) {
  .merklist-senden .submit-holder {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
@media (min-width: 992px) {
  .merklist-senden .submit-holder {
    padding: 0;
  }
}
.merklist-senden .submit-holder .g-recaptcha {
  margin: 0 0 20px;
}
@media (max-width: 767px) {
  .merklist-senden .submit-holder .g-recaptcha {
    max-width: 304px;
    margin: 0 auto 20px;
  }
}
@media (min-width: 768px) {
  .merklist-senden .submit-holder .g-recaptcha {
    margin: 0;
  }
}
.merklist-senden .submit-holder .btn {
  margin: 0 0 0 20px;
}

@media (max-width: 991px) {
  .merklist-senden .form-group {
    margin-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .merklist-senden .add-class {
    margin-top: 99px;
  }
}

@media (min-width: 992px) {
  .merklist-senden .add-class {
    margin-top: 112px;
  }
}

.merklist-senden .newsletter-form {
  margin: 0;
}

.merklist-senden .amount {
  color: #242424;
  font-size: 18px;
  text-align: right;
  background: #f2f2f2;
  border: 0;
  box-shadow: none;
  padding: 7px 20px 1px 20px;
  outline: none;
  max-width: 70%;
}
@media (max-width: 767px) {
  .merklist-senden .amount {
    display: none;
  }
}

.merklist-senden .shareLink {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 65px;
}
.merklist-senden .shareLink > .clipboardIcon {
  position: relative;
  right: -30px;
  top: -5px;
  font-size: 22px;
}

.merklist-senden .clipboardLabel {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 7px #000;
  border-radius: 4px;
  padding: 6px 10px 4px;
  top: 0;
  right: 30px;
  z-index: 10;
}

/* add button styles */
.add-button {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  font-weight: 300;
  color: #666;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.add-button i {
  display: inline-block;
  vertical-align: middle;
  transform: rotate(45deg);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 15px 0 0;
  background: #666;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
}
.add-button:hover {
  color: #000;
}
.add-button:hover i {
  background: #000;
}

.header .btnLogin,
.header .btnProfile,
.header .btnLogout {
  color: #fff;
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: relative;
  margin: 4px 10px 0 5px;
  transition: all 0.3s;
}
.header .btnLogin:hover,
.header .btnProfile:hover,
.header .btnLogout:hover {
  color: #7f7f7f;
}

.header .btnLogin > a,
.header .btnProfile > a,
.header .btnLogout > a {
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
}

.underline {
  text-decoration: underline;
}

.cc_banner-wrapper .cc_container {
  background: #000;
}
.cc_banner-wrapper .cc_container .cc_message a,
.cc_banner-wrapper .cc_container .cc_message {
  line-height: 1.3em;
}
.cc_banner-wrapper .cc_container .cc_btn,
.cc_banner-wrapper .cc_container .cc_btn:visited {
  background-color: #fff;
  color: #000;
  border-radius: 0px;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  padding: 5px 20px;
}

strong {
  font-weight: 400;
}

.box-holder .image-holder,
.title-section .image-holder,
.imageHolderWrapper {
  position: relative;
}
.box-holder .image-holder::after,
.title-section .image-holder::after,
.imageHolderWrapper::after {
  content: "";
  position: absolute;
  background: linear-gradient(transparent 0%, transparent 30%, #000 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imageBoxWrapper h2 {
  color: #fff;
}

.no-uppercase {
  text-transform: none !important;
}

.image-caption {
  font-size: 60%;
  padding-top: 10px;
  font-weight: 300;
}

/*# sourceMappingURL=maps/main.css.map */
