/**
 * Animate a list of elements sequentially
 *
 * @author FOURDEGREES
 * @param {string} $target             - selector of animated item 
 * @param {number} $initialDelay = 0.3 - delay until animation begins
 * @param {number} $duration = 0.5     - duration for each animation
 * @param {number} $delay = 0.2        - delay between each animation
 * @param {number} $itemCount = 10     - number of animated items
 */

.singleArticleWrapper,
.article-section,
.article-list,
.productFinder .firstLevelWrapper .categoriesWrapper {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clearfix:after,
.singleArticleWrapper .owl-carousel.owl-carousel2 .holder:after,
.article-section .owl-carousel.owl-carousel2 .holder:after,
.article-list .owl-carousel.owl-carousel2 .holder:after {
  content: "";
  display: block;
  clear: both;
}

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

body.open-filter {
  overflow: hidden;
}

body.open-filter .filter-frame {
  transform: translate(0, 0);
}

body.open-filter .filter-frame:after {
  opacity: 1;
  visibility: visible;
}

.filter-frame {
  background: #fff;
  position: relative;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  transform: translate(0, 100%);
  transition: transform 0.3s ease-in-out;
  z-index: 3;
  text-align: center;
  max-height: 85vh;
}

.filter-frame .opener-filter-frame {
  width: 40px;
  height: 40px;
  background: #000;
  text-decoration: none;
  outline: none;
  position: absolute;
  top: -40px;
  left: 50%;
  margin: 0 0 0 -20px;
  z-index: 3;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 0;
  visibility: hidden;
}

.filter-frame .opener-filter-frame:after,
.filter-frame .opener-filter-frame:before {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  top: 5px;
  left: 15px;
  width: 10px;
  height: 1px;
  background: #313131;
}

.filter-frame .opener-filter-frame:before {
  top: 7px;
}

.filter-frame .title {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  color: #000;
  margin: 0 0 15px;
}

.filter-frame .clear {
  font-size: 12px;
  font-weight: 400;
  color: #666;
  text-decoration: none;
  outline: none;
  display: inline-block;
  vertical-align: top;
}

.filter-frame .clear:hover {
  color: #000;
}

.filter-frame .clear i {
  display: inline-block;
  vertical-align: top;
  margin: 1px 0 0;
}

.filter-frame .filter-scroll-frame {
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 18px 54px 5px;
}

.filter-frame .filter-scroll-frame .articleCounterHolder {
  display: none;
}

.owl-carousel {
  max-height: 750px;
}

.tabset-holder {
  margin-bottom: 25px;
}

.singleArticleWrapper,
.article-section,
.article-list {
  margin: 0 0 20px;
}

.singleArticleWrapper > li,
.article-section > li,
.article-list > li {
  display: block;
  position: relative;
  border-bottom: 1px solid #e6e6e6;
  list-style: none;
}

.singleArticleWrapper > li.active,
.article-section > li.active,
.article-list > li.active {
  background: #f2f2f2;
}

.singleArticleWrapper > li.active .article-opener:after,
.article-section > li.active .article-opener:after,
.article-list > li.active .article-opener:after {
  transform: rotate(-90deg);
}

.singleArticleWrapper .article-slide-holder,
.article-section .article-slide-holder,
.article-list .article-slide-holder {
  overflow: hidden;
}

.singleArticleWrapper .gallery-slide-frame .btn-holder .btn,
.article-section .gallery-slide-frame .btn-holder .btn,
.article-list .gallery-slide-frame .btn-holder .btn {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: normal;
  color: #fff;
  background: #242424;
  display: block;
  margin: 0 0 19px;
  padding: 11px 4px 9px 4px;
}

.singleArticleWrapper .gallery-slide-frame .btn-holder .btn:hover,
.article-section .gallery-slide-frame .btn-holder .btn:hover,
.article-list .gallery-slide-frame .btn-holder .btn:hover {
  color: #000;
  background: #fff;
}

.singleArticleWrapper .gallery-slide-frame .btn-holder .btn i,
.article-section .gallery-slide-frame .btn-holder .btn i,
.article-list .gallery-slide-frame .btn-holder .btn i {
  margin: -5px 10px 0 0;
  font-size: 18px;
}

.singleArticleWrapper .gallery-slide-frame .btn-holder .btn .icon-add,
.article-section .gallery-slide-frame .btn-holder .btn .icon-add,
.article-list .gallery-slide-frame .btn-holder .btn .icon-add {
  display: inline-block;
  vertical-align: middle;
  visibility: visible;
  width: auto;
  height: auto;
  opacity: 1;
}

.singleArticleWrapper .gallery-slide-frame .btn-holder .btn .icon-ok,
.article-section .gallery-slide-frame .btn-holder .btn .icon-ok,
.article-list .gallery-slide-frame .btn-holder .btn .icon-ok {
  display: inline-block;
  vertical-align: middle;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}

.singleArticleWrapper .gallery-slide-frame .btn-holder .btn em,
.article-section .gallery-slide-frame .btn-holder .btn em,
.article-list .gallery-slide-frame .btn-holder .btn em {
  display: inline-block;
  vertical-align: middle;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  font-style: normal;
}

.singleArticleWrapper .gallery-slide-frame .btn-holder .btn b,
.article-section .gallery-slide-frame .btn-holder .btn b,
.article-list .gallery-slide-frame .btn-holder .btn b {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
}

.singleArticleWrapper .gallery-slide-frame .btn-holder .btn.added b,
.singleArticleWrapper .gallery-slide-frame .btn-holder .btn.added .icon-add,
.article-section .gallery-slide-frame .btn-holder .btn.added b,
.article-section .gallery-slide-frame .btn-holder .btn.added .icon-add,
.article-list .gallery-slide-frame .btn-holder .btn.added b,
.article-list .gallery-slide-frame .btn-holder .btn.added .icon-add {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}

.singleArticleWrapper .gallery-slide-frame .btn-holder .btn.added em,
.singleArticleWrapper .gallery-slide-frame .btn-holder .btn.added .icon-ok,
.article-section .gallery-slide-frame .btn-holder .btn.added em,
.article-section .gallery-slide-frame .btn-holder .btn.added .icon-ok,
.article-list .gallery-slide-frame .btn-holder .btn.added em,
.article-list .gallery-slide-frame .btn-holder .btn.added .icon-ok {
  visibility: visible;
  width: auto;
  height: auto;
  opacity: 1;
}

.singleArticleWrapper .gallery-slide-frame .gallery-slide,
.article-section .gallery-slide-frame .gallery-slide,
.article-list .gallery-slide-frame .gallery-slide {
  padding: 0 4px;
}

.singleArticleWrapper .gallery-slide-holder,
.article-section .gallery-slide-holder,
.article-list .gallery-slide-holder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.singleArticleWrapper .content-slide,
.article-section .content-slide,
.article-list .content-slide {
  overflow: hidden;
}

.singleArticleWrapper .content-slide h3,
.article-section .content-slide h3,
.article-list .content-slide h3 {
  font-weight: 700;
  font-size: 16px;
  margin: 0 0 4px;
}

.singleArticleWrapper .content-slide p,
.article-section .content-slide p,
.article-list .content-slide p {
  margin: 0 0 30px;
}

.singleArticleWrapper .column-holder strong,
.article-section .column-holder strong,
.article-list .column-holder strong {
  font-weight: normal;
  display: block;
  font-style: normal;
  text-transform: uppercase;
  color: #666;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 11px 12px;
}

.singleArticleWrapper .column-holder .column,
.article-section .column-holder .column,
.article-list .column-holder .column {
  width: 100%;
  padding: 0 0 0 7px;
}

.singleArticleWrapper .column-holder .column:first-child,
.article-section .column-holder .column:first-child,
.article-list .column-holder .column:first-child {
  width: 100%;
  padding: 0;
  margin: 0 0 19px;
}

.singleArticleWrapper .column-holder .sub-column,
.article-section .column-holder .sub-column,
.article-list .column-holder .sub-column {
  width: 100%;
  padding: 0 10px 0 0;
}

.singleArticleWrapper .filterWrapper,
.article-section .filterWrapper,
.article-list .filterWrapper {
  margin: 0;
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.singleArticleWrapper .filterWrapper .parentlabel,
.article-section .filterWrapper .parentlabel,
.article-list .filterWrapper .parentlabel {
  margin: 0;
  font-weight: normal;
  font-size: 12px;
}

.singleArticleWrapper .filterWrapper .filterlabel,
.article-section .filterWrapper .filterlabel,
.article-list .filterWrapper .filterlabel {
  margin: 0 0 3px 9px;
  font-size: 15px;
}

.singleArticleWrapper .media-communications-list li,
.article-section .media-communications-list li,
.article-list .media-communications-list li {
  margin: 0 0 21px;
}

.singleArticleWrapper .owl-carousel,
.article-section .owl-carousel,
.article-list .owl-carousel {
  padding: 0 20% 0 0;
  margin: 0 0 9px;
}

.singleArticleWrapper .owl-carousel .owl-dots,
.article-section .owl-carousel .owl-dots,
.article-list .owl-carousel .owl-dots {
  position: absolute;
  top: 0;
  right: 0;
  width: 18%;
  padding: 0;
  display: block;
}

.singleArticleWrapper .owl-carousel .owl-dots .owl-dot,
.article-section .owl-carousel .owl-dots .owl-dot,
.article-list .owl-carousel .owl-dots .owl-dot {
  display: block;
  width: 100% !important;
  height: 50px;
  margin: 0 0 13px;
  padding-top: 100%;
  background-position: center center;
}

.singleArticleWrapper .owl-carousel.owl-carousel2,
.article-section .owl-carousel.owl-carousel2,
.article-list .owl-carousel.owl-carousel2 {
  padding: 0;
  margin: 0;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-dots,
.article-section .owl-carousel.owl-carousel2 .owl-dots,
.article-list .owl-carousel.owl-carousel2 .owl-dots {
  position: static;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-dots .owl-dot,
.article-section .owl-carousel.owl-carousel2 .owl-dots .owl-dot,
.article-list .owl-carousel.owl-carousel2 .owl-dots .owl-dot {
  width: 6px !important;
  height: 6px !important;
  padding-top: 0;
  margin: 0 2px;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next {
  display: block;
  background-color: rgba(255, 255, 255, 0.4) !important;
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  opacity: 1;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev:focus,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev:hover,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next:focus,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next:hover,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev:focus,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev:hover,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next:focus,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next:hover,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev:focus,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev:hover,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next:focus,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next:hover {
  transform: scale(1);
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev.disabled,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev.disabled:focus,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev.disabled:hover,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next.disabled,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next.disabled:focus,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next.disabled:hover,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev.disabled,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev.disabled:focus,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev.disabled:hover,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next.disabled,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next.disabled:focus,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next.disabled:hover,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev.disabled,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev.disabled:focus,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev.disabled:hover,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next.disabled,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next.disabled:focus,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next.disabled:hover {
  opacity: 0.4;
  cursor: default;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev::after,
.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next::after,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev::after,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next::after,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev::after,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next::after {
  top: 50%;
  margin-top: -8px;
  left: 8px;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev {
  left: 0;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next,
.article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next,
.article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next {
  right: 0;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .holder,
.article-section .owl-carousel.owl-carousel2 .holder,
.article-list .owl-carousel.owl-carousel2 .holder {
  margin: 0 0 16px;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 img,
.article-section .owl-carousel.owl-carousel2 img,
.article-list .owl-carousel.owl-carousel2 img {
  float: left;
  width: 104px;
  margin: 0 10px 0 0;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 h3,
.article-section .owl-carousel.owl-carousel2 h3,
.article-list .owl-carousel.owl-carousel2 h3 {
  display: none;
  color: #242424;
  font-size: 18px;
  margin: 0;
  font-weight: normal;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 h4,
.article-section .owl-carousel.owl-carousel2 h4,
.article-list .owl-carousel.owl-carousel2 h4 {
  display: none;
  color: #666;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 300;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .text-box,
.article-section .owl-carousel.owl-carousel2 .text-box,
.article-list .owl-carousel.owl-carousel2 .text-box {
  overflow: hidden;
  cursor: pointer;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .text-box .clickOpener,
.article-section .owl-carousel.owl-carousel2 .text-box .clickOpener,
.article-list .owl-carousel.owl-carousel2 .text-box .clickOpener {
  color: #fff;
  font-size: 200%;
  width: 60px;
  height: 60px;
  line-height: 1.5;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  top: 50%;
  margin-top: -30px;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .text-box h3,
.article-section .owl-carousel.owl-carousel2 .text-box h3,
.article-list .owl-carousel.owl-carousel2 .text-box h3 {
  color: #242424;
  font-size: 16px;
  margin: 0;
  font-weight: normal;
  display: block;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .text-box h4,
.article-section .owl-carousel.owl-carousel2 .text-box h4,
.article-list .owl-carousel.owl-carousel2 .text-box h4 {
  color: #666;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0 0 5px;
  display: block;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 p,
.article-section .owl-carousel.owl-carousel2 p,
.article-list .owl-carousel.owl-carousel2 p {
  margin: 0 0 13px;
  font-weight: 300;
  line-height: 1.166;
  font-size: 12px;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .add,
.article-section .owl-carousel.owl-carousel2 .add,
.article-list .owl-carousel.owl-carousel2 .add {
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  border: 1px solid #868686;
  font-size: 12px;
  font-weight: 400;
  padding: 13px 0 9px;
  margin: 0 0 10px;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .add:hover,
.article-section .owl-carousel.owl-carousel2 .add:hover,
.article-list .owl-carousel.owl-carousel2 .add:hover {
  background: #000;
  color: #fff;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .add i,
.article-section .owl-carousel.owl-carousel2 .add i,
.article-list .owl-carousel.owl-carousel2 .add i {
  font-size: 18px;
  display: inline-block;
  vertical-align: top;
  margin: -2px 10px 0 0;
}

.singleArticleWrapper .owl-carousel.owl-carousel2 .add em,
.article-section .owl-carousel.owl-carousel2 .add em,
.article-list .owl-carousel.owl-carousel2 .add em {
  font-style: normal;
}

.singleArticleWrapper .white-frame,
.article-section .white-frame,
.article-list .white-frame {
  background: #fff;
  margin: 0 5px 5px;
  padding: 10px 5px 5px;
}

.article-slide .assessoryCarousel.owl-carousel .owl-stage {
  position: absolute;
  height: 255px;
}

.article-slide .assessoryCarousel.owl-carousel .owl-stage-outer {
  height: 255px;
}

.article-slide .assessoryCarousel.owl-carousel .owl-item .item .text-box > p {
  display: none;
}

.article-slide .assessoryCarousel.owl-carousel .owl-item .item .text-box > p:first-of-type {
  display: inline-block;
  line-height: 20px;
}

.filter.disabled.active {
  color: #fff;
  background-color: #000;
}

.product-description-accordion:after {
  display: none;
}

.articleHolder {
  position: relative;
  min-height: 100px;
}

.mobileDevice .filter-frame.info-product-filter .inner-box.filter:hover span {
  color: #7f7f7f;
  background: #e6e6e6;
}

.mobileDevice .filter-frame.info-product-filter .inner-box.filter.active:hover span {
  background: #000;
  color: #fff;
}

.filter-frame.info-product-filter {
  display: block;
  text-align: left;
  background: #fff;
  margin: 0 -16px;
}

.filter-frame.info-product-filter .filter-scroll-frame {
  display: block !important;
  padding: 18px 20px 5px;
}

.filter-frame.info-product-filter .title {
  margin: 0 0 11px;
}

.filter-frame.info-product-filter .submit-row {
  text-align: center;
}

.filter-frame.info-product-filter .submit-row .btn {
  background: #000;
  color: #fff;
  padding: 12px 19px 10px 19px;
}

.filter-frame.info-product-filter .submit-row .btn:hover {
  background: #fff;
  color: #000;
}

.filter-frame.info-product-filter .filter-product-holder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  align-items: flex-start;
  max-width: 1300px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 8px;
}

.filter-frame.info-product-filter .filter-product-holder .photo-holder .productLabel {
  width: 100%;
  display: block;
  bottom: -40px;
  left: 0;
  text-align: center;
  height: auto;
  padding: 10px 10px 5px;
}

.filter-frame.info-product-filter .filter-product-holder .active .photo-holder .productLabel {
  bottom: -39px;
}

.filter-frame.info-product-filter .filterHolder > .inner-box-frame {
  width: auto;
}

.filter-frame.info-product-filter .inner-box-frame {
  margin: 0 10px 9px 0;
  width: 100%;
}

.filter-frame.info-product-filter .inner-box-frame .sub-title {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  color: #666;
  font-weight: 400;
  text-align: left;
  margin: 0 0 4px 12px;
}

.filter-frame.info-product-filter .inner-box-holder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.filter-frame.info-product-filter .inner-box {
  margin: 0 5px 65px;
  cursor: pointer;
}

.filter-frame.info-product-filter .inner-box.filter {
  margin-bottom: 10px;
}

.filter-frame.info-product-filter .inner-box.active span,
.filter-frame.info-product-filter .inner-box:hover span {
  background: #000;
  color: #fff;
}

.filter-frame.info-product-filter .inner-box.active .photo-holder > img {
  box-sizing: border-box;
  border: solid 1px #000;
  border-bottom: 0;
  box-shadow: inset 0px 0px 31px #000;
}

.filter-frame.info-product-filter .inner-box.disabled {
  cursor: default;
}

.filter-frame.info-product-filter .inner-box.disabled span {
  background: #444;
  color: #fff;
}

.filter-frame.info-product-filter .inner-box.inactive span {
  background: #e1e1e1;
  color: #fff;
}

.filter-frame.info-product-filter .photo-holder {
  max-width: 103px;
}

.filter-frame.info-product-filter .photo-holder img {
  vertical-align: top;
  width: 100%;
}

.filter-frame.info-product-filter .photo-holder span {
  display: block;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  line-height: 1.083;
  color: #7f7f7f;
  background: #e6e6e6;
  display: -ms-flexbox;
  display: flex;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border-color 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}

.filter-frame.info-product-filter .photo-holder span.sm-height em {
  min-height: 30px;
}

.filter-frame.info-product-filter .photo-holder span em {
  font-style: normal;
  min-width: 45px;
  min-height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 5px 10px 0;
}

.filter-frame.info-product-filter .photo-holder span i {
  font-style: normal;
}

.articleOpener {
  position: absolute;
  top: 50%;
  left: 4px;
  color: #7f7f7f;
  font-size: 12px;
  margin: -7px 0 0 0;
}

.articleOpener:after {
  font-family: "icomoon" !important;
  content: "\e90d";
  clear: both;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

/* product-bersicht page styles*/

.productFinder .noResults {
  min-height: 400px;
  font-size: 120%;
  text-align: center;
  padding: 150px 10px 150px;
}

.productFinder h2,
.productFinder .btn,
.productFinder .btn-category {
  font-weight: 400;
}

.productFinder .menuWrapper,
.productFinder .category,
.productFinder label,
.productFinder .fake-input,
.productFinder .image-frame {
  position: relative;
}

.productFinder .configPicker,
.productFinder .delete,
.productFinder .custom-check [type="checkbox"],
.productFinder .sub-category,
.productFinder .more,
.productFinder .family {
  position: absolute;
}

.productFinder .categoriesWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px 50px;
}

.productFinder .buttonWrapper {
  padding-left: 6px;
  width: 225px;
}

.productFinder .buttonWrapper.typeButton span {
  padding: 11px 14px 7px;
}

.productFinder .buttonWrapper:first-child {
  padding: 0;
}

.productFinder .buttonWrapper.category {
  margin-bottom: 10px;
  padding: 0 5px;
  transition: opacity 0.3s ease-in-out 0s;
  width: 33.333%;
  font-size: 22px;
  line-height: 1.1818;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.productFinder .buttonWrapper.category span.flexWrapper {
  min-height: 76px;
  display: inline-block;
  vertical-align: top;
}

.productFinder .buttonWrapper.category span.flexWrapper span {
  padding: 12px 20px 12px;
  display: -ms-flexbox;
  display: flex;
  vertical-align: middle;
  min-height: inherit;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.productFinder .buttonWrapper.category span.flexWrapper.active:hover {
  opacity: 0.8;
  color: #fff;
}

.productFinder .buttonWrapper.category.active .delete {
  opacity: 1;
  visibility: visible;
}

.productFinder .badgeWrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;
}

.productFinder .badgeWrapper .badgeButton {
  width: auto;
}

.productFinder .badgeWrapper .badgeButton span {
  padding: 9px 20px 9px;
}

.productFinder .configPicker {
  right: 0;
  top: -70px;
}

.productFinder .inactive {
  display: none !important;
}

.productFinder .categoriesMainWrapper,
.productFinder .filter-frame {
  display: none;
}

.productFinder .categoriesMainWrapper.active,
.productFinder .filter-frame.active {
  display: block;
}

.productFinder .submit-row {
  margin-top: 15px;
  text-align: center;
}

.productFinder .submit-row button {
  color: #fff;
  font-size: 12px;
  background: #242424;
  margin: 0 0 20px;
  padding: 13px 20px 9px;
}

.productFinder .submit-row button:hover {
  color: #242424;
  background: #fff;
}

.productFinder h2 {
  font-size: 30px;
  margin: 0 0 34px;
  font-weight: normal;
  display: block;
  color: #242424;
}

.productFinder .menuWrapper {
  margin: 0 0 27px;
}

.productFinder .firstLevelWrapper {
  margin: 0 0 19px;
}

.productFinder .firstLevelWrapper .mainButtonWrapper {
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}

.productFinder .firstLevelWrapper .categoriesMobileWrapper,
.productFinder .firstLevelWrapper .jcf-select-categoriesMobileWrapper {
  display: none !important;
}

.productFinder .firstLevelWrapper .categoriesWrapper {
  margin: 0 -5px 50px;
}

.productFinder .firstLevelWrapper .categoriesWrapper .delete {
  top: 1px;
  right: 6px;
  font-size: 10px;
  color: #fff;
  width: 20px;
  height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
}

.productFinder .body .results {
  position: relative;
  min-height: 350px;
}

/* filter product styles*/

.filterSidebar .tabset-holder {
  margin: 0 0 15px;
}

.filterSidebar .filter-frame {
  margin: 0;
  background: #fff;
}

.filterSidebar .filter-frame .filter-scroll-frame {
  display: block;
}

.filterSidebar .filter-frame .clear {
  margin: 0;
}

.filterSidebar .sub-box-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: start;
  align-items: flex-start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.filterSidebar .sub-box-column .sub-box {
  width: 50%;
  padding: 0 20px 0 0;
  margin: 0 0 13px;
  text-align: left;
}

.filterSidebar .sub-box {
  cursor: pointer;
  margin: 0 0 35px;
}

.filterSidebar .sub-box.groupFilter {
  display: block !important;
}

.filterSidebar .sub-box.groupFilter .custom-check {
  display: block !important;
  text-decoration: none;
  opacity: 1 !important;
}

.filterSidebar .sub-box.groupFilter .custom-check::after {
  content: "";
  position: absolute;
  right: -20px;
  top: 0;
  font-size: 60%;
}

.filterSidebar .sub-box .all {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  color: #242424;
  font-weight: normal;
  margin: 0 0 0 3px;
}

.filterSidebar .sub-box .all:hover {
  color: #000;
}

.filterSidebar .sub-box .notAvailable {
  position: relative;
}

.filterSidebar .sub-box .notAvailable::after {
  content: "(0)";
  position: absolute;
  right: -20px;
  top: 0;
  font-size: 60%;
}

.filterSidebar .sub-box.singleSelect .notAvailable::after {
  content: "";
  position: absolute;
  right: -20px;
  top: 0;
  font-size: 60%;
}

.filterSidebar .sub-box .checked.notAvailable,
.filterSidebar .sub-box .active {
  opacity: 1;
}

.filterSidebar .sub-box.notSelected .notAvailable {
  opacity: 0.4;
}

.filterSidebar .sub-label {
  display: block;
  font-size: 12px;
  color: #000;
  font-weight: normal;
  margin: 0 0 5px;
  text-align: left;
}

.filterSidebar .sub-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.filterSidebar .input__label-content {
  font-size: 14px;
  margin: 0;
}

.filterSidebar .form-control {
  font-size: 14px;
  margin: 0;
}

.filterSidebar .submit {
  font-size: 15px;
  width: 10%;
  margin: 0;
}

.filterSidebar .form-group {
  width: 90%;
  margin: 0;
}

.filterSidebar .slider-range-holder {
  margin: 13px 20px 35px 10px;
}

.filterSidebar .slider-range-holder .amount1,
.filterSidebar .slider-range-holder .amount2 {
  font-size: 10px;
  top: 18px;
}

.bersicht-content {
  overflow: hidden;
}

.bersicht-content h2 {
  font-weight: normal;
  margin: 0 0 8px;
}

/* custom checkbox styles*/

.custom-check {
  margin: 3px 0 11px 0px;
}

.custom-check [type="checkbox"] {
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
}

.custom-check .fake-input,
.custom-check label {
  display: inline-block;
  vertical-align: middle;
}

.custom-check label {
  padding: 0;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  align-items: flex-start;
  font-weight: normal;
}

.custom-check .fake-input {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 0;
  background: #e6e6e6;
  vertical-align: middle;
  margin-right: 6px;
  margin-top: 2px;
}

.custom-check [type="checkbox"]:checked + .fake-input {
  background: #000;
}

.custom-check [type="checkbox"]:checked ~ .fake-label {
  color: #242424;
}

.custom-check input:disabled + .fake-input,
.custom-check input:disabled ~ .fake-label {
  opacity: 0.5;
}

.custom-check .fake-label {
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 300;
  margin: -1px 0 -4px 0;
}

.result-holder {
  margin: 0 -2px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* result product box styles*/

.defaultContainer .merkliste-section a {
  border-bottom-width: 0;
}

.defaultContainer .merkliste-section a.btn {
  border-bottom-width: 1px;
}

.merkliste-section h1 > i {
  display: inline-block;
  margin-left: 25px;
  margin-top: -10px;
}

.merkliste-section .article .delete {
  cursor: pointer;
}

.merkliste-section .priceField {
  width: 100%;
  padding-right: 20%;
}

.merkliste-section .priceField .priceRow,
.merkliste-section .priceField .priceRow.noDoubleLine,
.merkliste-section .priceField .priceRow.noDoubleLine:first-child,
.merkliste-section .priceField .priceRow.noDoubleLine:last-child {
  border: none;
  padding: 0;
  margin-top: 0px;
  width: 100%;
}

.merkliste-section .priceField .priceRow .priceField,
.merkliste-section .priceField .priceRow.noDoubleLine .priceField,
.merkliste-section .priceField .priceRow.noDoubleLine:first-child .priceField,
.merkliste-section .priceField .priceRow.noDoubleLine:last-child .priceField {
  width: 100%;
}

.defaultContainer span.sub-category.newLabel {
  text-transform: uppercase;
  color: #fff;
  top: 5px;
  left: 0;
  font-size: 11px;
  line-height: 1;
  font-weight: normal;
  padding: 4px 7px 0 8px;
}

.lineWrapper {
  width: 50%;
  padding: 0 2px;
  margin: 0 0 10px;
  display: block;
}

.lineWrapper:hover .image-frame img {
  transform: scale(1.2);
}

.lineWrapper .image-frame {
  overflow: hidden;
}

.lineWrapper .image-frame img {
  vertical-align: top;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.lineWrapper h3 {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  color: #242424;
}

.lineWrapper p {
  margin: 0;
  font-size: 16px;
  line-height: 1.25;
  color: #666;
  font-weight: 300;
  display: none;
}

.lineWrapper .more {
  font-weight: normal;
  bottom: 15px;
  right: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  padding: 9px 15px 5px;
  z-index: 1;
  overflow: hidden;
  max-width: 70%;
}

.lineWrapper .more:hover i {
  transform: translate(8px, 0);
}

.lineWrapper .more:after {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0;
  background: #000;
  transition: width 0.3s ease-in-out;
  z-index: -1;
}

.lineWrapper .more em {
  display: inline-block;
  font-style: normal;
  transition: transform 0.3s ease-in-out;
}

.lineWrapper .more.openArticleLink:after {
  width: 100%;
}

.lineWrapper .more.openArticleLink em {
  transform: translate(0, 0);
}

.lineWrapper .more.openArticleLink i {
  color: #fff;
}

.lineWrapper .more i {
  display: inline;
  font-size: 14px;
  margin: 0 0 0 10px;
  color: #000;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.lineWrapper .familyLinkIcon {
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-size: 16px;
  display: block;
  width: 30px;
  box-sizing: border-box;
  padding: 15px;
  height: 30px;
  background-color: #000;
  border-radius: 20px;
  overflow: hidden;
  transition: width 0.5s ease, border-radius 0.5s ease;
}

.lineWrapper .familyLinkIcon.open {
  width: 210px;
  border-radius: 0;
}

.lineWrapper .familyLinkIcon em {
  display: inline-block;
  vertical-align: top;
  color: #fff;
  margin-left: 17px;
  margin-top: -8px;
  white-space: nowrap;
  font-weight: 400;
  font-style: normal;
}

.lineWrapper .familyLinkIcon i.icon-family {
  color: #fff;
  margin: 0;
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 16px;
}

.lineWrapper .family {
  width: 24px;
  height: 24px;
  display: block;
  bottom: 5px;
  left: 5px;
  border-radius: 50%;
  background: #000;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 10px;
}

.lineWrapper .text-box {
  padding: 3px 0 0 2px;
}

a.btn-download {
  border-bottom: 0;
}

table.articleOverView {
  width: 100%;
  border: 0;
}

table.articleOverView .articleHeadline {
  cursor: pointer;
}

table.articleOverView .articleHeadline.active {
  background: #f2f2f2;
}

table.articleOverView .articleHeadline.active .articleOpener:after {
  transform: rotate(-90deg);
}

table.articleOverView .articleHeadline.active td {
  border-bottom: 0;
}

table.articleOverView .articleContent .article-slide {
  height: 0;
  overflow: hidden;
  position: relative;
}

table.articleOverView .articleContent .processbar {
  display: block;
  height: 3px;
  background-color: black;
  width: 0%;
  transition: width 3s ease-out;
}

table.articleOverView .articleContent .processbar.started {
  width: 100%;
}

table.articleOverView .articleContent td {
  border-bottom: 0;
}

table.articleOverView .articleContent.active {
  background: #f2f2f2;
  border-bottom: solid 1px #d5d5d5;
}

table.articleOverView .articleContent.active .article-slide {
  height: auto;
}

table.articleOverView .articleContent.active .processbar {
  width: 100%;
}

table.articleOverView .noArticlesFound {
  font-size: 20px;
}

table.articleOverView .noArticlesFound td {
  padding: 22px 0 20px;
  text-align: center;
}

table.articleOverView td.arrowHolder {
  width: 60px;
  position: relative;
  height: 50px;
}

table.articleOverView td {
  box-sizing: border-box;
  padding: 12px 25px 10px;
  border-bottom: 1px solid #e6e6e6;
  vertical-align: middle;
}

table.articleOverView .articleContent td {
  padding: 0 25px;
}

table.articleOverView .hide-phone {
  display: none;
}

table.articleOverView .head-column {
  text-align: left;
}

table.articleOverView .head-column strong {
  display: block;
  font-size: 12px;
  font-weight: normal;
  padding: 19px 0 6px 0;
  box-sizing: border-box;
}

.productLineNavigation {
  position: absolute;
  width: 100%;
  left: 0;
  height: 34px;
  margin: 0;
  top: 72px;
  z-index: 10;
}

.merkliste-section .text-box strong a {
  border: 0;
}

#popup table.articleOverView td {
  cursor: default;
}

#popup table.articleOverView .articleContent .article-slide {
  height: auto;
}

#popup .popupContentHolder .successHolder {
  font-size: 20px;
  text-align: center;
}

#popup .popupContentHolder .row {
  margin-left: -5px;
  margin-right: -5px;
}

#popup .errorHolder {
  font-size: 16px;
  color: #e1001a;
  font-weight: bold;
}

#popup .errorHolder .errorMessage {
  margin-bottom: 10px;
}

.articleHeadline.active .oldArticleNumber {
  visibility: visible;
  opacity: 1;
}

.articleNumberField {
  line-height: 0;
  transition: line-height 0.3s ease-in-out;
}

.oldArticleNumber {
  display: inline-block;
  width: 100%;
  font-size: 70%;
  font-weight: bold;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.iconWrapper {
  width: auto;
  padding: 10px 0 8px;
}

.iconContainer {
  padding: 0;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 10px 15px 0;
  width: 80px;
  background-color: #fff;
  border: solid 5px #fff;
  vertical-align: top;
}

.iconContainer.noBgColor {
  background-color: transparent;
  border: none;
}

.iconContainer.smallIcon {
  width: 46%;
  margin-right: 8%;
  margin-bottom: 8%;
  height: 46%;
  border-width: 3px;
}

.iconContainer.smallIcon:nth-child(2n) {
  margin-right: 0;
}

.iconContainer.smallIcon:nth-child(3),
.iconContainer.smallIcon:nth-child(4) {
  margin-bottom: 0;
}

.wishlistFormField.removeable input {
  padding-right: 30px;
}

.wishlistFormField.removeable .remove {
  z-index: 10;
  position: absolute;
  right: 5px;
  top: 3px;
}

.assessoryCarousel .accessory .added .icon-add {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}

.assessoryCarousel .accessory .added .icon-ok {
  visibility: visible;
  width: auto;
  height: auto;
  opacity: 1;
}

.assessoryCarousel .accessory .addToWishlist {
  top: 3px !important;
}

.assessoryCarousel .accessory .icon-ok {
  display: inline-block;
  vertical-align: middle;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}

.filterWrapper .sub-label.searchLabel {
  font-weight: 400;
  margin-top: -27px;
  line-height: 30px;
}

.priceField {
  margin: 10px 0;
}

.priceField .askForLogin {
  font-weight: bold;
  margin-bottom: 15px;
}

.priceField .priceHeadline {
  text-transform: uppercase;
  font-weight: bold;
}

.priceField .priceRow {
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  padding: 11px 0 5px;
  margin-top: 15px;
}

.priceField .priceRow .priceLabel {
  display: inline-block;
  width: 45%;
}

.priceField .priceRow .priceField {
  display: inline-block;
  width: 55%;
  text-align: right;
}

.priceField .priceRow.noDoubleLine {
  border: 0;
  border-bottom: 1px solid #a1a1a1;
  padding: 3px 0 5px;
  margin-top: 0;
}

.priceField .priceRow.noDoubleLine:first-child {
  border-top: 1px solid #000;
}

.priceField .priceRow.noDoubleLine:last-child {
  border-bottom: 1px solid #000;
}

.backToProductFinderUrl {
  position: fixed;
  bottom: -44px;
  height: 44px;
  left: 50%;
  width: 240px;
  margin-left: -120px;
  padding: 12px 15px 10px 10px;
  text-align: center;
  background-color: #000;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.backToProductFinderUrl:hover {
  width: 100%;
  left: 0;
  margin: 0;
}

.backToProductFinderUrl i.left {
  position: absolute;
  transform: rotate(-180deg);
  margin-top: 1px;
}

.backToProductFinderUrl span {
  padding-left: 30px;
}

.backToProductFinderUrl a {
  color: #fff;
}

.owl-carousel-bg-frame .projektname {
  border-bottom: 1px solid #000;
}

.owl-carousel-bg-frame .projektname:hover {
  border-bottom: 1px solid #fff;
}

/* IE10+ specific styles go here */

@media (min-width: 768px) {
  .singleArticleWrapper,
  .article-section,
  .article-list {
    margin: 0 0 96px;
  }

  .singleArticleWrapper .article-slide-holder,
  .article-section .article-slide-holder,
  .article-list .article-slide-holder {
    padding: 30px 0 0 19px;
  }

  .singleArticleWrapper .gallery-slide-frame,
  .article-section .gallery-slide-frame,
  .article-list .gallery-slide-frame {
    float: right;
    width: 33%;
    padding: 0 19px 0 0;
  }

  .singleArticleWrapper .gallery-slide-frame .btn-holder .btn,
  .article-section .gallery-slide-frame .btn-holder .btn,
  .article-list .gallery-slide-frame .btn-holder .btn {
    margin: 0 0 40px;
  }

  .singleArticleWrapper .gallery-slide-frame .gallery-slide,
  .article-section .gallery-slide-frame .gallery-slide,
  .article-list .gallery-slide-frame .gallery-slide {
    padding: 0;
  }

  .singleArticleWrapper .gallery-slide-holder,
  .article-section .gallery-slide-holder,
  .article-list .gallery-slide-holder {
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 345px;
  }

  .singleArticleWrapper .content-slide,
  .article-section .content-slide,
  .article-list .content-slide {
    padding: 0px 100px 0 0;
  }

  .singleArticleWrapper .content-slide h3,
  .article-section .content-slide h3,
  .article-list .content-slide h3 {
    font-size: 24px;
    margin: 0 0 27px;
  }

  .singleArticleWrapper .column-holder,
  .article-section .column-holder,
  .article-list .column-holder {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: start;
    align-items: flex-start;
    align-items: flex-start;
  }

  .singleArticleWrapper .column-holder strong,
  .article-section .column-holder strong,
  .article-list .column-holder strong {
    font-size: 16px;
    margin: 0 0 12px 12px;
  }

  .singleArticleWrapper .column-holder .column,
  .article-section .column-holder .column,
  .article-list .column-holder .column {
    width: 50%;
  }

  .singleArticleWrapper .column-holder .column:first-child,
  .article-section .column-holder .column:first-child,
  .article-list .column-holder .column:first-child {
    width: 50%;
    margin: 0;
  }

  .singleArticleWrapper .filterWrapper .parentlabel,
  .article-section .filterWrapper .parentlabel,
  .article-list .filterWrapper .parentlabel {
    font-size: 16px;
  }

  .singleArticleWrapper .filterWrapper .filterlabel,
  .article-section .filterWrapper .filterlabel,
  .article-list .filterWrapper .filterlabel {
    font-size: 18px;
    margin: 0 0 13px 9px;
  }

  .singleArticleWrapper .media-communications-list li,
  .article-section .media-communications-list li,
  .article-list .media-communications-list li {
    margin: 0 0 30px;
  }

  .singleArticleWrapper .owl-carousel,
  .article-section .owl-carousel,
  .article-list .owl-carousel {
    padding: 0;
  }

  .singleArticleWrapper .owl-carousel .owl-stage-outer,
  .article-section .owl-carousel .owl-stage-outer,
  .article-list .owl-carousel .owl-stage-outer {
    width: 100%;
  }

  .singleArticleWrapper .owl-carousel .owl-dots,
  .article-section .owl-carousel .owl-dots,
  .article-list .owl-carousel .owl-dots {
    position: static;
    width: auto;
    padding: 10px 0 8px;
    display: -ms-flexbox;
    display: flex;
  }

  .singleArticleWrapper .owl-carousel .owl-dots .owl-dot,
  .article-section .owl-carousel .owl-dots .owl-dot,
  .article-list .owl-carousel .owl-dots .owl-dot {
    padding-top: 0;
    margin: 0 10px 13px 0;
    width: 65px !important;
    height: 65px !important;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .owl-dots,
  .article-section .owl-carousel.owl-carousel2 .owl-dots,
  .article-list .owl-carousel.owl-carousel2 .owl-dots {
    display: none;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .item,
  .article-section .owl-carousel.owl-carousel2 .item,
  .article-list .owl-carousel.owl-carousel2 .item {
    overflow: hidden;
    position: relative;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .item:hover .text-box,
  .singleArticleWrapper .owl-carousel.owl-carousel2 .item:hover .add,
  .singleArticleWrapper .owl-carousel.owl-carousel2 .item.active .text-box,
  .singleArticleWrapper .owl-carousel.owl-carousel2 .item.active .add,
  .article-section .owl-carousel.owl-carousel2 .item:hover .text-box,
  .article-section .owl-carousel.owl-carousel2 .item:hover .add,
  .article-section .owl-carousel.owl-carousel2 .item.active .text-box,
  .article-section .owl-carousel.owl-carousel2 .item.active .add,
  .article-list .owl-carousel.owl-carousel2 .item:hover .text-box,
  .article-list .owl-carousel.owl-carousel2 .item:hover .add,
  .article-list .owl-carousel.owl-carousel2 .item.active .text-box,
  .article-list .owl-carousel.owl-carousel2 .item.active .add {
    transform: translate(0, 0);
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .holder,
  .article-section .owl-carousel.owl-carousel2 .holder,
  .article-list .owl-carousel.owl-carousel2 .holder {
    margin: 0 0 10px;
    position: relative;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 img,
  .article-section .owl-carousel.owl-carousel2 img,
  .article-list .owl-carousel.owl-carousel2 img {
    float: none;
    width: 100%;
    margin: 0;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 h3,
  .article-section .owl-carousel.owl-carousel2 h3,
  .article-list .owl-carousel.owl-carousel2 h3 {
    display: block;
    margin: 0 0 3px 4px;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 h4,
  .article-section .owl-carousel.owl-carousel2 h4,
  .article-list .owl-carousel.owl-carousel2 h4 {
    display: block;
    margin: 0 0 0 4px;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .text-box,
  .article-section .owl-carousel.owl-carousel2 .text-box,
  .article-list .owl-carousel.owl-carousel2 .text-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 35px 9px 9px 9px;
    transform: translate(0, -100%);
    transition: transform 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.8);
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .text-box h3,
  .article-section .owl-carousel.owl-carousel2 .text-box h3,
  .article-list .owl-carousel.owl-carousel2 .text-box h3 {
    display: none;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .text-box h4,
  .article-section .owl-carousel.owl-carousel2 .text-box h4,
  .article-list .owl-carousel.owl-carousel2 .text-box h4 {
    display: none;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 p,
  .article-section .owl-carousel.owl-carousel2 p,
  .article-list .owl-carousel.owl-carousel2 p {
    line-height: 1.25;
    font-size: 16px;
    color: #fff;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .btn-download,
  .article-section .owl-carousel.owl-carousel2 .btn-download,
  .article-list .owl-carousel.owl-carousel2 .btn-download {
    color: #fff;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .btn-download i,
  .singleArticleWrapper .owl-carousel.owl-carousel2 .btn-download em,
  .article-section .owl-carousel.owl-carousel2 .btn-download i,
  .article-section .owl-carousel.owl-carousel2 .btn-download em,
  .article-list .owl-carousel.owl-carousel2 .btn-download i,
  .article-list .owl-carousel.owl-carousel2 .btn-download em {
    color: #fff;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .btn-download:hover,
  .article-section .owl-carousel.owl-carousel2 .btn-download:hover,
  .article-list .owl-carousel.owl-carousel2 .btn-download:hover {
    color: #000;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .btn-download:hover i,
  .singleArticleWrapper .owl-carousel.owl-carousel2 .btn-download:hover em,
  .article-section .owl-carousel.owl-carousel2 .btn-download:hover i,
  .article-section .owl-carousel.owl-carousel2 .btn-download:hover em,
  .article-list .owl-carousel.owl-carousel2 .btn-download:hover i,
  .article-list .owl-carousel.owl-carousel2 .btn-download:hover em {
    color: #000;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .add,
  .article-section .owl-carousel.owl-carousel2 .add,
  .article-list .owl-carousel.owl-carousel2 .add {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 10px;
    border: 0;
    z-index: 2;
    color: #fff;
    background: none;
    transform: translate(200%, 0);
    transition: transform 0.3s ease-in-out;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .add:hover,
  .article-section .owl-carousel.owl-carousel2 .add:hover,
  .article-list .owl-carousel.owl-carousel2 .add:hover {
    color: #000;
    background: none;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .add i,
  .article-section .owl-carousel.owl-carousel2 .add i,
  .article-list .owl-carousel.owl-carousel2 .add i {
    margin: 0;
    font-size: 28px;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .add em,
  .article-section .owl-carousel.owl-carousel2 .add em,
  .article-list .owl-carousel.owl-carousel2 .add em {
    display: none;
  }

  .singleArticleWrapper .white-frame,
  .article-section .white-frame,
  .article-list .white-frame {
    margin: 0 19px 19px;
    padding: 20px;
  }

  .filter-frame.info-product-filter .filter-product-holder {
    margin: 0 0 19px;
  }

  .filter-frame.info-product-filter .inner-box-frame {
    margin: 0 40px 37px 0;
  }

  .filter-frame.info-product-filter .inner-box-frame .sub-title {
    font-size: 16px;
    margin: 0 0 12px 20px;
  }

  .filter-frame.info-product-filter .photo-holder {
    max-width: 150px;
  }

  .filter-frame.info-product-filter .photo-holder span {
    font-size: 20px;
    line-height: 1.1;
  }

  .filter-frame.info-product-filter .photo-holder span em {
    min-width: 84px;
    min-height: 60px;
  }

  .filter-frame.info-product-filter .submit-row {
    display: none;
  }

  .articleOpener {
    font-size: 20px;
    left: 50%;
    margin: -14px 0 -11px 0;
  }

  .productFinder .buttonWrapper.category span.flexWrapper {
    font-size: 18px;
    line-height: 1.2;
  }

  .productFinder .submit-row button {
    width: 100%;
  }

  .productFinder .firstLevelWrapper {
    margin: 0 0 38px;
  }

  .filterSidebar .tabset-holder {
    display: none;
  }

  .filterSidebar .sub-box-column {
    display: block;
  }

  .filterSidebar .sub-box-column .sub-box {
    width: 100%;
    padding: 0;
    margin: 0 0 40px;
  }

  .filterSidebar .sub-box {
    margin: 0 0 40px;
  }

  .filterSidebar .sub-box .all {
    font-size: 16px;
  }

  .filterSidebar .sub-label {
    font-size: 18px;
  }

  .filterSidebar .slider-range-holder {
    margin: 15px 10px 80px;
  }

  .filterSidebar .slider-range-holder .amount1,
  .filterSidebar .slider-range-holder .amount2 {
    font-size: 14px;
    top: 20px;
  }

  .bersicht-content h2 {
    font-size: 26px;
  }

  .custom-check {
    margin: 8px 0 12px 3px;
  }

  .custom-check .fake-input {
    width: 11px;
    height: 11px;
    margin-right: 9px;
    margin-top: 3px;
  }

  .result-holder {
    margin: 0 -5px 48px;
  }

  .defaultContainer span.sub-category.newLabel {
    font-size: 22px;
    top: 15px;
    padding: 6px 13px 0 14px;
  }

  .lineWrapper h3 {
    font-size: 20px;
  }

  .lineWrapper p {
    display: block;
  }

  .lineWrapper .more {
    font-size: 10px;
    padding: 9px 5px 5px;
  }

  .lineWrapper .more:after {
    width: 100%;
  }

  .lineWrapper .more i {
    color: #fff;
    font-size: 8px;
    margin: 0 0 0 5px;
  }

  .lineWrapper .familyLinkIcon em {
    font-size: 10px;
  }

  .lineWrapper .family {
    width: 40px;
    height: 40px;
    bottom: 5px;
    left: 5px;
    font-size: 20px;
  }

  .lineWrapper .text-box {
    padding: 12px 0 0 0;
  }

  table.articleOverView td {
    padding: 12px 10px 10px;
  }

  table.articleOverView .articleContent td {
    padding: 0px 10px;
  }

  table.articleOverView .hide-phone {
    display: table-cell;
  }

  table.articleOverView .hide-tablet {
    display: none;
  }
}

@media (min-width: 769px) {
  .articleHeadline.active .articleNumberField {
    line-height: inherit;
  }
}

@media (min-width: 992px) {
  .filter-frame {
    max-height: none;
    text-align: left;
    position: static;
    position: relative;
    z-index: 3;
    transform: translate(0, 0);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 21px;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background: #f2f2f2;
  }

  .filter-frame .filter-scroll-frame {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .filter-frame .title {
    display: none;
  }

  .filter-frame .clear {
    margin: 5px 7px 0 100px;
  }

  .filter-frame .filter-scroll-frame {
    padding: 0;
    max-height: none;
    overflow: visible;
  }

  .singleArticleWrapper .article-slide-holder,
  .article-section .article-slide-holder,
  .article-list .article-slide-holder {
    padding: 30px 0 51px 19px;
  }

  .singleArticleWrapper .gallery-slide-frame .btn-holder .btn,
  .article-section .gallery-slide-frame .btn-holder .btn,
  .article-list .gallery-slide-frame .btn-holder .btn {
    font-size: 18px;
    padding: 11px 14px 9px 14px;
  }

  .singleArticleWrapper .column-holder .column,
  .article-section .column-holder .column,
  .article-list .column-holder .column {
    width: 32%;
  }

  .singleArticleWrapper .column-holder .column:first-child,
  .article-section .column-holder .column:first-child,
  .article-list .column-holder .column:first-child {
    width: 68%;
  }

  .singleArticleWrapper .column-holder .sub-column,
  .article-section .column-holder .sub-column,
  .article-list .column-holder .sub-column {
    width: 50%;
  }

  .singleArticleWrapper .owl-carousel .owl-dots,
  .article-section .owl-carousel .owl-dots,
  .article-list .owl-carousel .owl-dots {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .singleArticleWrapper .owl-carousel .owl-dots .owl-dot,
  .article-section .owl-carousel .owl-dots .owl-dot,
  .article-list .owl-carousel .owl-dots .owl-dot {
    width: 80px !important;
    height: 80px !important;
  }

  .singleArticleWrapper .white-frame,
  .article-section .white-frame,
  .article-list .white-frame {
    padding: 15px 20px 14px;
  }

  .filter-frame.info-product-filter .photo-holder {
    max-width: none;
  }

  .filter-frame.info-product-filter .photo-holder img {
    width: auto;
  }

  .productFinder .buttonWrapper.category span.flexWrapper {
    font-size: 22px;
    line-height: 1.1818;
  }

  .filterSidebar {
    padding: 30px 20px 0 0;
  }

  .filterSidebar .sub-box-column .sub-box {
    margin: 0 0 56px;
  }

  .filterSidebar .sub-box {
    margin: 0 0 56px;
  }

  .bersicht-content h2 {
    font-size: 30px;
  }

  .lineWrapper h3 {
    font-size: 22px;
  }

  .lineWrapper .more {
    font-size: 12px;
    padding: 6px 15px 6px;
  }

  .lineWrapper .more:after {
    width: 0;
  }

  .lineWrapper .more em {
    transform: translate(200%, 0);
  }

  .lineWrapper .more i {
    color: #000;
    font-size: 14px;
    margin: 0 0 0 10px;
  }

  .lineWrapper .familyLinkIcon em {
    font-size: 12px;
  }

  .lineWrapper .family {
    bottom: 15px;
    left: 15px;
  }

  .lineWrapper .text-box {
    padding: 12px 0 0 16px;
  }

  table.articleOverView .hide-tablet {
    display: table-cell;
  }

  table.articleOverView .head-column strong {
    font-size: 16px;
  }

  .productLineNavigation {
    top: 125px;
  }
}

@media (min-width: 1025px) {
  .lineWrapper {
    width: 33.333%;
    padding: 0 5px;
    margin: 0 0 20px;
  }
}

@media (min-width: 1400px) {
  .filter-frame .clear {
    margin: 5px 7px 0 214px;
  }

  .productFinder .buttonWrapper.category span.flexWrapper span {
    padding: 16px 70px 10px;
  }

  .filterSidebar {
    padding: 30px 115px 0 0;
  }

  .lineWrapper .more {
    font-size: 16px;
    padding: 9px 15px 5px;
  }

  .lineWrapper .familyLinkIcon em {
    font-size: 16px;
  }

  .productLineNavigation {
    top: 174px;
  }
}

@media screen and (-ms-high-contrast: active) and (max-width: 1400px),
  (-ms-high-contrast: none) and (max-width: 1400px) {
  table.articleOverView .articleContent .article-slide {
    max-width: 1100px;
  }
}

@media screen and (-ms-high-contrast: active) and (max-width: 1280px),
  (-ms-high-contrast: none) and (max-width: 1280px) {
  table.articleOverView .articleContent .article-slide {
    max-width: 950px;
  }
}

@media screen and (-ms-high-contrast: active) and (max-width: 1100px),
  (-ms-high-contrast: none) and (max-width: 1100px) {
  table.articleOverView .articleContent .article-slide {
    max-width: 850px;
  }
}

@media screen and (-ms-high-contrast: active) and (max-width: 1000px),
  (-ms-high-contrast: none) and (max-width: 1000px) {
  table.articleOverView .articleContent .article-slide {
    max-width: 750px;
  }
}

@media (max-width: 991px) {
  body.nav-active {
    overflow: hidden;
  }

  .filter-frame .rangeWrapper {
    clear: both;
  }

  .filter-frame .sub-box {
    margin: 20px 0 35px !important;
  }

  .filter-frame .sub-box .notAvailable::after {
    content: "" !important;
  }

  .filter-frame .sub-box .fake-label {
    text-align: left;
  }

  .filter-frame:after {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    top: -9999px;
    left: 0;
    right: 0;
    bottom: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  .filter-frame .opener-filter-frame {
    opacity: 1;
    visibility: visible;
  }

  .filter-frame .clear {
    margin: 5px 7px 0 15px;
    font-size: 14px;
  }

  .filter-frame .filter-scroll-frame .articleCounterHolder {
    display: block;
    margin-bottom: 5px;
    padding-bottom: 15px;
  }

  .stickyHolder {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 10;
  }

  .stickyHolder .counter {
    padding-top: 10px;
  }

  .filter-frame.info-product-filter .stickyHolder .title {
    margin: 10px 0 -4px;
  }

  .stickySpacer {
    width: 100%;
    height: 60px;
  }

  .productFinder .buttonWrapper {
    width: 50%;
  }

  .productFinder .badgeWrapper {
    margin: 20px 0px 10px;
    position: relative;
  }

  .productFinder .badgeWrapper .badgeButton {
    width: 23%;
    padding: 0;
  }

  .productFinder .directSearchButton {
    width: 100%;
  }

  .productFinder .configPicker {
    width: 100%;
    right: auto;
    top: -50px;
  }

  .productFinder
    .configPicker
    .jcf-select.jcf-select-primary-select.jcf-select-primary-light-select {
    display: block;
    background: #f2f2f2 none repeat scroll 0 0;
  }

  .productFinder
    .configPicker
    .jcf-select.jcf-select-primary-select.jcf-select-primary-light-select
    .jcf-select-text:after {
    font-size: 18px;
  }

  .productFinder
    .configPicker
    .jcf-select.jcf-select-primary-select.jcf-select-primary-light-select
    .jcf-select-text
    span {
    color: #242424;
  }

  .productFinder .jcf-select.jcf-select-primary-select {
    background: #f2f2f2 none repeat scroll 0 0;
    max-width: 100%;
    width: 100%;
  }

  .productFinder h2 {
    margin: 0 0 16px;
    font-size: 18px;
  }

  .productFinder .menuWrapper {
    margin: 60px 0 9px;
  }

  .productFinder .firstLevelWrapper .mainButtonWrapper {
    margin-bottom: 20px;
  }

  .productFinder .firstLevelWrapper .categoriesMobileWrapper,
  .productFinder .firstLevelWrapper .jcf-select-categoriesMobileWrapper {
    display: block !important;
  }

  .productFinder .firstLevelWrapper .categoriesWrapper {
    display: none;
  }

  .filterSidebar {
    position: static;
    top: 0;
    left: 0;
    padding: 10px 0 0 0;
  }

  .filterSidebar .filter-frame .filter-scroll-frame {
    padding: 30px 40px 5px;
  }

  .filterSidebar .form-group {
    width: 94%;
  }

  .filterSidebar .submit {
    width: 6%;
  }

  .iconWrapper {
    text-align: center;
  }

  .iconContainer {
    width: 65px;
    height: 65px;
  }

  .filter-scroll-frame .tabset-holder .counter {
    position: relative;
    text-align: center;
  }
}

@media screen and (-ms-high-contrast: active) and (max-width: 850px),
  (-ms-high-contrast: none) and (max-width: 850px) {
  table.articleOverView .articleContent .article-slide {
    max-width: 600px;
  }
}

@media (max-width: 767px) {
  .singleArticleWrapper .content-slide p,
  .article-section .content-slide p,
  .article-list .content-slide p {
    font-size: 15px;
    line-height: 1.333;
    margin: 0 0 10px;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .owl-dots,
  .article-section .owl-carousel.owl-carousel2 .owl-dots,
  .article-list .owl-carousel.owl-carousel2 .owl-dots {
    display: none;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .owl-stage-outer,
  .article-section .owl-carousel.owl-carousel2 .owl-stage-outer,
  .article-list .owl-carousel.owl-carousel2 .owl-stage-outer {
    height: 190px !important;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
  .singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next,
  .article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
  .article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next,
  .article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
  .article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next {
    height: 232px;
    top: -38px;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
  .article-section .owl-carousel.owl-carousel2 .owl-nav .owl-prev,
  .article-list .owl-carousel.owl-carousel2 .owl-nav .owl-prev {
    left: -4px;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .owl-nav .owl-next,
  .article-section .owl-carousel.owl-carousel2 .owl-nav .owl-next,
  .article-list .owl-carousel.owl-carousel2 .owl-nav .owl-next {
    right: -4px;
  }

  .singleArticleWrapper .owl-carousel.owl-carousel2 .btn-download,
  .article-section .owl-carousel.owl-carousel2 .btn-download,
  .article-list .owl-carousel.owl-carousel2 .btn-download {
    font-size: 12px;
    padding: 0 0 3px;
  }

  .filterSidebar .filter-frame .filter-scroll-frame {
    padding: 18px 10px 5px;
  }

  .bersicht-content h2 {
    font-size: 16px;
    margin: 0 0 5px;
  }

  .lineWrapper .more {
    display: none;
  }

  .lineWrapper .familyLinkIcon em {
    display: none;
  }

  table.articleOverView {
    display: block;
    width: 100%;
  }

  table.articleOverView tr,
  table.articleOverView tbody {
    width: 100%;
    display: inherit;
  }

  table.articleOverView tr.articleContent {
    height: 0;
  }

  table.articleOverView tr.articleContent.active {
    height: auto;
  }

  table.articleOverView td {
    display: inline;
  }

  table.articleOverView td.column,
  table.articleOverView td.head-column {
    width: 100%;
    display: inline-block;
    padding-left: 60px;
  }

  table.articleOverView td[colspan] {
    width: 100%;
    display: inline-block;
  }

  table.articleOverView td.hide-phone {
    display: none;
  }

  table.articleOverView td.arrowHolder {
    width: 60px;
    position: absolute;
    border: none;
    padding: 0;
    height: 50px;
  }

  .merkliste-section .btn-download i.icon-download {
    margin-top: 14px;
  }

  .merkliste-section .btn-download span.bold {
    display: none;
  }

  .btnLogin a.btn {
    width: 100%;
    padding: 12px 5px 10px;
  }

  hr.spacer {
    display: none;
  }

  table.articleOverView tr.headArticle td,
  table.articleOverView tr.articleHeadline td {
    display: none;
  }

  table.articleOverView tr.headArticle .articleNumberField,
  table.articleOverView tr.headArticle .arrowHolder,
  table.articleOverView tr.articleHeadline .articleNumberField,
  table.articleOverView tr.articleHeadline .arrowHolder {
    display: inline-block;
    padding: 25px 0 20px 60px;
    padding: 25px 0 25px 40px;
    font-size: 16px;
  }

  table.articleOverView tr.headArticle .articleOpener,
  table.articleOverView tr.articleHeadline .articleOpener {
    left: 13px;
  }

  .oldArticleNumber {
    width: auto;
  }

  .owl-carousel .owl-dots {
    display: none;
  }

  .owl-carousel-bg-frame .projektname {
    width: 100%;
    max-width: 220px;
    margin-left: -110px;
    margin-top: -12px;
    text-align: center;
    transform: none;
  }
}

@media print {
  .merkliste-section .priceField {
    white-space: nowrap;
    padding: 0;
  }

  .merkliste-section .forceDownload,
  .merkliste-section .deleteCellWrapper,
  .merkliste-section .clearWishlist {
    display: none !important;
  }

  .assessoryCarousel .accessory header,
  .assessoryCarousel .accessory #main ul.sub-nav-list,
  .assessoryCarousel .accessory .defaultContainer .button-row,
  .assessoryCarousel .accessory footer,
  .assessoryCarousel .accessory .deleteCellWrapper,
  .assessoryCarousel .accessory .clearWishlist,
  .assessoryCarousel .accessory .hideOnPrint {
    display: none !important;
  }

  .assessoryCarousel .accessory .showOnPrint {
    display: inline !important;
  }

  .assessoryCarousel .accessory #main {
    padding: 0;
  }

  .assessoryCarousel .accessory #main .container-fluid {
    width: 19cm;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  table.articleOverView .articleContent .article-slide {
    margin: 0 auto;
    width: 100%;
    max-width: 1380px;
  }
}
