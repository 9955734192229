@import url("./bootstrap6f49.css?18");
@import url("./main6f49.css?18");
/* 
 */
@import url("./content6f49.css?18");
@import url("./teaser6f49.css?18");
@import url("./fd6f49.css?18");
@import url("./formStyle6f49.css?18");
@import url("./contestform6f49.css?18");
@import url("./blog6f49.css?18");
@import url("./fdContentElements6f49.css?18");
@import url("./products.css");

/* @import url("./scss/formStyle.css");
@import url("./scss/jst.css"); */

.owl-theme .owl-dots {
  width: 100% !important;
}

.owl-theme .owl-dots .owl-dot {
  width: 12px !important;
  height: 20px !important;
  display: inline-block !important;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px !important;
  display: inline-block !important;
  height: 10px !important;
  box-sizing: border-box !important;
  margin: 0 5px !important;
  border-radius: 100% !important;
  cursor: pointer !important;
  position: relative !important;
  border: 2px solid #a8abab !important;
  background-color: #fff !important;
  vertical-align: top !important;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #a8abab !important;
  /* height: 10px !important;
  width: 10px !important; */
}

.owl-carousel .owl-dot:after {
  display: none !important;
}

.owl-theme .owl-nav .owl-prev {
  color: #fff !important;
  position: absolute;
  left: 26px;
  font-size: 35px !important;
}

.owl-theme .owl-nav .owl-next {
  color: #fff !important;
  position: absolute;
  right: 30px !important;
  font-size: 35px !important;
}
