.hidden {
  display: none !important;
  width: 100%; }

.pointer {
  cursor: pointer; }

@media (min-width: 768px) {
  .box-frame.full-width-mobile {
    justify-content: flex-start; } }

.noMargin {
  margin: 0 !important; }

#lighttboxDflt {
  display: none; }

/* select options styles */
.jcf-list {
  background: #e6e6e6;
  cursor: pointer; }

.jcf-list .jcf-option {
  color: #000;
  cursor: pointer; }

.jcf-list .jcf-disabled {
  background: #fff !important;
  color: #aaa !important; }

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
  background: #000;
  color: #fff; }

.jcf-list .jcf-optgroup-caption {
  color: #000; }

/* common custom form elements styles */
.jcf-disabled {
  background: #ddd !important; }

.jcf-focus, .jcf-focus * {
  border-color: #f00 !important; }

.ext-tmpl .ext-header {
  padding: 70px 0px; }
  @media (max-width: 767px) {
    .ext-tmpl .ext-header {
      padding: 40px 0px; } }
  .ext-tmpl .ext-header h1 {
    margin: -12px 0px; }
  .ext-tmpl .ext-header .col {
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .ext-tmpl .ext-header .col {
        margin-bottom: 25px; } }
  .ext-tmpl .ext-header .form-control {
    color: inherit;
    box-shadow: none;
    border-bottom: 1px solid #e6e6e6; }

.nav-holder .toWishlist {
  transition: width 0.5s ease;
  margin: 0px 0 0 20px;
  white-space: nowrap;
  opacity: 0;
  width: 0;
  overflow: hidden;
  position: relative; }
  .nav-holder .toWishlist.visible {
    opacity: 1;
    width: 55px; }
  .nav-holder .toWishlist a {
    color: #fff; }

.iconLink {
  position: relative;
  padding-left: 1.5em;
  border: none !important; }
  .iconLink::before {
    font-family: 'icomoon' !important;
    position: absolute;
    left: 0;
    bottom: -2px; }
  .iconLink__phone::before {
    content: '\e91f'; }
  .iconLink__mail::before {
    content: '\e912'; }
  .iconLink__download::before {
    content: '\e90f'; }

.twoColRTE {
  overflow: hidden; }
  .twoColRTE .colLeft {
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .twoColRTE .colLeft {
        margin-bottom: 0; } }

/*# sourceMappingURL=maps/fd.css.map */
