#main.eventMain {
  padding-top: 50px; }
  @media (min-width: 768px) {
    #main.eventMain {
      padding-top: 70px; } }
  @media (min-width: 1400px) {
    #main.eventMain {
      padding-top: 100px; } }
  #main.eventMain .headerContent h2 {
    padding-bottom: 20px;
    font-size: 26px; }
    @media (min-width: 640px) {
      #main.eventMain .headerContent h2 {
        font-size: 30px;
        padding-bottom: 30px; } }
    @media (min-width: 992px) {
      #main.eventMain .headerContent h2 {
        font-size: 36px;
        padding-bottom: 40px; } }
  #main.eventMain .eventdescription p {
    margin: 0 0 30px 0; }

.eventContainer {
  position: relative;
  max-width: 1920px;
  margin: 0 auto; }
  .eventContainer .ce-column {
    text-align: center; }
  .eventContainer .text-box {
    position: absolute;
    max-width: 1440px;
    margin: 0 auto; }
    .eventContainer .text-box.position-40 {
      top: 50px;
      right: 20px; }
      @media (min-width: 768px) {
        .eventContainer .text-box.position-40 {
          right: 40px; } }
      @media (min-width: 992px) {
        .eventContainer .text-box.position-40 {
          right: 70px; } }
      @media (min-width: 1400px) {
        .eventContainer .text-box.position-40 {
          right: 8.33%; } }
    .eventContainer .text-box.position-50 {
      top: 50px;
      left: 20px; }
      @media (min-width: 768px) {
        .eventContainer .text-box.position-50 {
          left: 40px; } }
      @media (min-width: 992px) {
        .eventContainer .text-box.position-50 {
          left: 70px; } }
      @media (min-width: 1400px) {
        .eventContainer .text-box.position-50 {
          left: 8.33%; } }
    .eventContainer .text-box.position-60 {
      bottom: 50px;
      right: 20px; }
      @media (min-width: 768px) {
        .eventContainer .text-box.position-60 {
          right: 40px; } }
      @media (min-width: 992px) {
        .eventContainer .text-box.position-60 {
          right: 70px; } }
      @media (min-width: 1400px) {
        .eventContainer .text-box.position-60 {
          right: 8.33%; } }
    .eventContainer .text-box.position-70 {
      bottom: 50px;
      left: 20px; }
      @media (min-width: 768px) {
        .eventContainer .text-box.position-70 {
          left: 40px; } }
      @media (min-width: 992px) {
        .eventContainer .text-box.position-70 {
          left: 70px; } }
      @media (min-width: 1400px) {
        .eventContainer .text-box.position-70 {
          left: 8.33%; } }
    .eventContainer .text-box.position-80 {
      top: 50%;
      transform: translateY(-50%);
      left: 20px; }
      @media (min-width: 768px) {
        .eventContainer .text-box.position-80 {
          left: 40px; } }
      @media (min-width: 992px) {
        .eventContainer .text-box.position-80 {
          left: 70px; } }
      @media (min-width: 1400px) {
        .eventContainer .text-box.position-80 {
          left: 8.33%; } }
    .eventContainer .text-box.position-90 {
      top: 50%;
      transform: translateY(-50%);
      right: 20px; }
      @media (min-width: 768px) {
        .eventContainer .text-box.position-90 {
          right: 40px; } }
      @media (min-width: 992px) {
        .eventContainer .text-box.position-90 {
          right: 70px; } }
      @media (min-width: 1400px) {
        .eventContainer .text-box.position-90 {
          right: 8.33%; } }
    .eventContainer .text-box .black-white span {
      background: #000;
      color: #fff; }
    .eventContainer .text-box .white-black span {
      background: #fff;
      color: #000; }
    .eventContainer .text-box h1.black-white span,
    .eventContainer .text-box h2.white-black span {
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 5px;
      line-height: 36px;
      font-size: 36px; }
      @media (min-width: 640px) {
        .eventContainer .text-box h1.black-white span,
        .eventContainer .text-box h2.white-black span {
          padding-top: 20px;
          line-height: 70px;
          font-size: 70px; } }
      @media (min-width: 992px) {
        .eventContainer .text-box h1.black-white span,
        .eventContainer .text-box h2.white-black span {
          line-height: 120px;
          font-size: 100px; } }
      @media (min-width: 1400px) {
        .eventContainer .text-box h1.black-white span,
        .eventContainer .text-box h2.white-black span {
          line-height: 140px;
          font-size: 140px; } }
    .eventContainer .text-box h2.black-white span,
    .eventContainer .text-box h2.white-black span {
      padding-top: 7px;
      padding-left: 3px;
      padding-right: 3px; }
    .eventContainer .text-box h1, .eventContainer .text-box h2 {
      margin-bottom: 3px; }
      .eventContainer .text-box h1 span, .eventContainer .text-box h2 span {
        color: #fff; }

.contestForm {
  float: left;
  margin: 30px 0 50px 0; }
  .contestForm .form-group {
    padding-right: 0; }
    @media (min-width: 641px) {
      .contestForm .form-group {
        padding-right: 100px; } }
  .contestForm .form-horizontal .form-group {
    margin-left: -10px; }
  .contestForm .rowWrapper,
  .contestForm .fieldWrapper {
    height: 60px;
    position: relative; }
    .contestForm .rowWrapper label,
    .contestForm .fieldWrapper label {
      position: absolute;
      top: 25px;
      left: 20px;
      color: #666;
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      font-weight: 400;
      transition: all .3s ease; }
    .contestForm .rowWrapper.parent-active label,
    .contestForm .fieldWrapper.parent-active label {
      opacity: 1 !important;
      top: 0px;
      left: 5px;
      color: #333; }
    .contestForm .rowWrapper.has-value label,
    .contestForm .fieldWrapper.has-value label {
      opacity: 0; }
    .contestForm .rowWrapper .form-control,
    .contestForm .fieldWrapper .form-control {
      position: relative;
      margin-top: 18px;
      height: 33px !important; }
      .contestForm .rowWrapper .form-control.f3-form-error,
      .contestForm .fieldWrapper .form-control.f3-form-error {
        background-color: rgba(255, 50, 50, 0.5); }
  .contestForm .fieldWrapper {
    height: auto; }
    .contestForm .fieldWrapper .selectlabel {
      position: relative;
      top: 0;
      margin-top: 15px; }
  .contestForm .form-group p {
    margin: 0 0 5px 0;
    display: table; }
  .contestForm .form-group .cont-contact {
    margin: 30px 0 50px 0; }
  .contestForm .form-group span.label {
    display: table-cell;
    width: 120px;
    font-weight: bold; }
  .contestForm .form-group span.value {
    display: table-cell; }
  .contestForm .form-group .country {
    margin-right: 0px; }
  .contestForm .txtSzT2 {
    text-align: right;
    font-size: 14px;
    margin-top: 20px; }
  .contestForm .jcf-select.jcf-select-primary-select.jcf-select-f3-form-error {
    background-color: rgba(255, 50, 50, 0.5);
    border: 1px #FF0000 solid; }
  .contestForm .jcf-select.jcf-select-primary-select {
    max-width: 100%; }
    @media (min-width: 769px) {
      .contestForm .jcf-select.jcf-select-primary-select {
        max-width: 344px; } }
  .contestForm .jcf-select.jcf-select-primary-select .jcf-select-text {
    font-size: 16px;
    line-height: 23px;
    font-weight: 400; }
  .contestForm .jcf-select.jcf-select-primary-select.jcf-select-f3-form-error .jcf-select-text {
    color: #b54d4d; }
  .contestForm .jcf-select.jcf-select-primary-select .jcf-select-text {
    font-size: 16px;
    line-height: 23px;
    padding: 8px 50px 2px 15px; }
  .contestForm h3 {
    font-weight: 700;
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 20px; }
  .contestForm .additionalQuest {
    padding: 30px 0px 0;
    font-weight: bold; }

.eventmap {
  position: relative;
  padding-bottom: 56.25%;
  /* ratio 16x9 */
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
  margin-top: 50px; }
  .eventmap iframe {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.headerContent .eventContainer .article-box .image-embed-item {
  width: auto;
  height: 400px;
  max-width: none;
  left: 50%;
  position: relative;
  transform: translateX(-50%); }
  @media (min-width: 768px) {
    .headerContent .eventContainer .article-box .image-embed-item {
      width: 100%;
      height: auto; } }

/*# sourceMappingURL=maps/contestform.css.map */
