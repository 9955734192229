/**
 * Animate a list of elements sequentially
 *
 * @author FOURDEGREES
 * @param {string} $target             - selector of animated item 
 * @param {number} $initialDelay = 0.3 - delay until animation begins
 * @param {number} $duration = 0.5     - duration for each animation
 * @param {number} $delay = 0.2        - delay between each animation
 * @param {number} $itemCount = 10     - number of animated items
 */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

@media (min-width: 768px) {
  .imageTeaser.col2 .imageTeaser__textBox {
    width: 81%;
  }
}

@media (min-width: 768px) {
  .imageTeaser.col3 .imageTeaser__textBox {
    width: 27%;
  }
}

.imageTeaser__image {
  transform: scale(1);
  transition: all 0.6s;
  position: relative;
}

.imageTeaser__textBox {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: #fff;
  padding: 25px 16px 25px;
  width: 81%;
}
@media (min-width: 768px) {
  .imageTeaser__textBox {
    width: 40.5%;
    left: 40px;
    bottom: 60px;
  }
}
.imageTeaser__textBox p {
  line-height: 1;
}

.imageTeaser__textBottom {
  padding: 20px 0;
}
@media (min-width: 768px) {
  .imageTeaser__textBottom {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.imageTeaser__metaTitle {
  font-size: 20px;
  margin-bottom: 2em;
}

.imageTeaser__title {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 0.3em;
}
@media (min-width: 992px) {
  .imageTeaser__title {
    font-size: 48px;
  }
}

.imageTeaser__subTitle {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .imageTeaser__subTitle {
    font-size: 32px;
  }
}

.imageTeaser__link {
  display: block;
  overflow: hidden;
}
.imageTeaser__link:hover {
  color: inherit;
}
.imageTeaser__link:hover .imageTeaser__image {
  transform: scale(1.05);
}

.imageTeaser2 {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .imageTeaser2 {
    padding-right: 9.5% !important;
  }
}
.imageTeaser2__title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1em;
  max-width: 340px;
  letter-spacing: 0.02em;
  line-height: 1.2;
  color: #fff;
}
@media (min-width: 768px) {
  .imageTeaser2__title {
    padding-right: 9.5% !important;
    min-height: 2.5em;
  }
}
@media (min-width: 992px) {
  .imageTeaser2__title {
    font-size: 48px;
  }
}
.imageTeaser2__subTitle {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  font-size: 30px;
  font-weight: 400;
  color: #fff;
  line-height: 1.2;
}
@media (min-width: 992px) {
  .imageTeaser2__subTitle {
    font-size: 48px;
    top: 40px;
    left: 40px;
    right: 40px;
  }
}
.imageTeaser2__image {
  transform: scale(1);
  transition: all 0.5s;
}
.imageTeaser2__link,
.imageTeaser2__noLink {
  display: block;
  position: relative;
  overflow: hidden;
}
.imageTeaser2__link .imageTeaser2__subTitle::after {
  font-family: "icomoon";
  content: "\e920";
  position: relative;
  display: block;
  font-size: 70%;
  margin-top: 0.5em;
}
.imageTeaser2__link:hover .imageTeaser2__image {
  transform: scale(1.05);
}

.richtext::before {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background-color: #000;
  position: relative;
  top: -20px;
  left: 4px;
}
@media (min-width: 992px) {
  .richtext::before {
    display: none;
  }
}

.richtext.col3 {
  width: 100% !important;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 20px;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .richtext.col3 {
    width: 33.333333% !important;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.richtext__metaTitle {
  margin-bottom: 0.2em;
}

.richtext.text__column {
  -moz-column-count: 1;
  column-count: 1;
  -moz-column-gap: 50px;
  column-gap: 50px;
}
@media (min-width: 992px) {
  .richtext.text__column {
    -moz-column-count: 2;
    column-count: 2;
  }
}

.fdSlider {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.fdSlider.largeContainer .container-fluid,
.fdSlider.maxContainer .container-fluid {
  max-width: 100vw;
  padding: 0;
}
@media (min-width: 768px) {
  .fdSlider.largeContainer .slide,
  .fdSlider.maxContainer .slide {
    height: calc(100vh - 50px);
  }
}
@media (min-width: 992px) {
  .fdSlider.largeContainer .slide,
  .fdSlider.maxContainer .slide {
    height: calc(100vh - 70px);
  }
}
@media (min-width: 1400px) {
  .fdSlider.largeContainer .slide,
  .fdSlider.maxContainer .slide {
    height: calc(100vh - 100px);
  }
}
.fdSlider.largeContainer .fdSlider__content {
  max-width: 1920px;
}
@media (min-width: 1920px) {
  .fdSlider.largeContainer .slide {
    height: 980px;
    width: 1920px;
  }
}
.fdSlider__content {
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: none;
}
.fdSlider .owl-dots {
  position: absolute !important;
  bottom: 0;
}
@media (min-width: 768px) {
  .fdSlider .owl-dots {
    bottom: 15px;
  }
}
.fdSlider .owl-nav {
  display: none;
}
@media (min-width: 768px) {
  .fdSlider .owl-nav {
    display: block;
  }
}
.fdSlider .slide {
  position: relative;
  height: 51.041666vw;
  min-height: 400px;
  width: 100vw;
}
.fdSlider .slide.bgBlack {
  background-color: #000;
}
@media (min-width: 992px) {
  .fdSlider .slide.bgWhite p {
    color: #000 !important;
  }
}
.fdSlider .slide.darken::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
@media (min-width: 992px) {
  .fdSlider .slide.darken::after {
    display: none;
  }
}
.fdSlider .slide__bgImage {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.fdSlider .slide__textBox {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  width: 100vw;
  padding: 0 20px;
  z-index: 1;
}
@media (min-width: 768px) {
  .fdSlider .slide__textBox {
    transform: translateY(calc(-50% - 40px));
  }
}
@media (min-width: 992px) {
  .fdSlider .slide__textBox.align--right {
    right: 60px;
    max-width: 650px;
  }
  .fdSlider .slide__textBox.align--left {
    left: 60px;
    max-width: 650px;
  }
}
@media (min-width: 1400px) {
  .fdSlider .slide__textBox.align--right {
    right: 200px;
    max-width: 900px;
  }
  .fdSlider .slide__textBox.align--left {
    left: 200px;
    max-width: 900px;
  }
}
.fdSlider .slide__textBox.align--center {
  max-width: 1400px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fdSlider .slide__title {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2em;
  letter-spacing: 0.02em;
}
@media (min-width: 768px) {
  .fdSlider .slide__title {
    font-size: 32px;
    margin-bottom: 1em;
  }
}
@media (min-width: 992px) {
  .fdSlider .slide__title {
    font-size: 48px;
  }
}
@media (min-width: 1400px) {
  .fdSlider .slide__title {
    font-size: 75px;
    margin-bottom: 1em;
  }
}
.fdSlider .slide__subtitle {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.02em;
}
@media (min-width: 768px) {
  .fdSlider .slide__subtitle {
    font-size: 32px;
    margin-bottom: 0.8em;
  }
}
@media (min-width: 992px) {
  .fdSlider .slide__subtitle {
    font-size: 48px;
  }
}
@media (min-width: 1400px) {
  .fdSlider .slide__subtitle {
    font-size: 75px;
    margin-bottom: 1em;
  }
}
.fdSlider .slide__subtitle span {
  font-weight: 400;
}
.fdSlider .slide .text---wide {
  letter-spacing: 0.5em;
}
.fdSlider .slide__text p {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}
@media (min-width: 768px) {
  .fdSlider .slide__text p {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .fdSlider .slide__text p {
    font-size: 28px;
  }
}
@media (min-width: 1400px) {
  .fdSlider .slide__text p {
    font-size: 34px;
  }
}
.fdSlider .slide__text p .btn {
  margin-top: 20px;
}
@media (min-width: 992px) {
  .fdSlider .slide__text p .btn {
    margin-top: 60px;
  }
}
.fdSlider .slide__video {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.fdSlider .slide__video .video-embed-item {
  width: auto;
  height: 100%;
  max-height: 980px;
  max-width: 100%;
  outline: none;
}
.fdSlider .slide__video-play {
  cursor: pointer;
}

.contentBox {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  margin-left: -8px;
  margin-right: -8px;
}
@media (min-width: 768px) {
  .contentBox {
    margin-bottom: 120px;
  }
}
.contentBox .container-fluid {
  width: 100%;
}
.contentBox__header {
  padding: 8px;
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .contentBox__header {
    margin-bottom: 40px;
  }
}
.contentBox__content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.contentBox__footer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .contentBox__footer {
    margin-top: 60px;
  }
}
.contentBox__item {
  position: relative;
  padding: 8px;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 768px) {
  .contentBox__item.col2 {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .contentBox__item.col3 {
    width: 33.333333%;
  }
}
.contentBox.largeContainer,
.contentBox.maxContainer {
  background-color: #000;
  padding: 50px 0 10px;
}
@media (min-width: 768px) {
  .contentBox.largeContainer,
  .contentBox.maxContainer {
    padding: 180px 0 100px;
  }
}
.contentBox.largeContainer .container-fluid,
.contentBox.maxContainer .container-fluid {
  width: 100%;
  padding-left: 20px !important;
  padding-right: 10px !important;
}

.fullscreenTeaser {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .fullscreenTeaser {
    margin-bottom: 120px;
  }
}
.fullscreenTeaser .container-fluid {
  width: 100%;
}
.fullscreenTeaser__image {
  position: relative;
  width: 100%;
}
.fullscreenTeaser__content {
  max-width: 750px;
  padding: 50px 20px;
}
@media (min-width: 992px) {
  .fullscreenTeaser__content {
    padding: 80px 20px;
  }
}
.fullscreenTeaser__title {
  color: #fff;
  margin-bottom: 0;
}
.fullscreenTeaser__header {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .fullscreenTeaser__header {
    margin-bottom: 40px;
  }
}
.fullscreenTeaser__subheader {
  color: #fff;
}
.fullscreenTeaser__text {
  color: #fff;
}
.fullscreenTeaser__text p:last-child {
  margin-bottom: 0;
}
.fullscreenTeaser__meta {
  color: #fff;
}
@media (min-width: 768px) {
  .fullscreenTeaser.maxContainer.fullHeight {
    min-height: calc(100vh - 50px);
  }
}
@media (min-width: 992px) {
  .fullscreenTeaser.maxContainer.fullHeight {
    min-height: calc(100vh - 70px);
  }
}
@media (min-width: 1400px) {
  .fullscreenTeaser.maxContainer.fullHeight {
    min-height: calc(100vh - 100px);
  }
}

.blackTeaser {
  background-color: #000;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  text-align: center;
}
@media (min-width: 768px) {
  .blackTeaser {
    margin-bottom: 120px;
  }
}
.blackTeaser .container-fluid {
  max-width: 775px;
  width: 100%;
}
.blackTeaser__content {
  padding: 80px 20px;
}
.blackTeaser__title {
  color: #fff;
}
.blackTeaser__subheader {
  color: #fff;
}
.blackTeaser__text {
  color: #fff;
}
.blackTeaser__text p:last-child {
  margin-bottom: 0;
}
.blackTeaser__meta {
  color: #fff;
}

/*# sourceMappingURL=maps/fdContentElements.css.map */
